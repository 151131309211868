import React, { Component } from "react";

import { Table, Button, Input } from "antd";
import { httpGet, httpUrl, httpPut } from "../../api/httpClient";

import {
  checkData,
  coinDivideFormatByDecimal,
  formatDate,
  numberFormat
} from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";

const Search = Input.Search;
class ExchangeRequestList extends Component {
  state = {
    list: [],
    pagination: {
      current: 0,
      total: 0
    },
    statusType: "ALL",
    searchType: "FROM",
    searchText: ""
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.userLockPagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getExchangeRequestList({
      pageNum: pager.current
    });
  };

  onStatusTypeHandleChange = (value) => {
    this.setState({ statusType: value });
  };

  onSearchTypeHandleChange = (value) => {
    this.setState({ searchType: value });
  };

  // 검색
  onSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.getExchangeRequestList();
      }
    );
  };

  // 상태 갱신
  onStatusUpdate = (value, idx) => {
    let body = {
      idx,
      statusType: value
    };

    if (global.confirm(`${string.exchangeString[value]}하시겠습니까?`))
      httpPut(httpUrl.exchangeRequestUpdate, [], body)
        .then((res) => {
          // console.log(`res = ${JSON.stringify(res, null, 4)}`);
          this.getExchangeRequestList({
            pageNum: this.state.pagination.current
          });
        })
        .catch((e) => {});
  };

  getExchangeRequestList = (params = {}) => {
    const { statusType, searchType, searchText } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getExchangeRequestList,
      [10, pageNum, statusType, searchType, searchText],
      {}
    )
      .then((res) => {
        // console.log(`res = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getExchangeRequestList();
  }

  render() {
    const { statusType, searchType, list, pagination } = this.state;
    const columns = [
      {
        width: 120,
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data, row) => <div>{numberFormat(data)}</div>
      },
      {
        width: 120,
        title: "등록일자",
        dataIndex: "createDate",
        className: "text-center",
        render: (data, row) => <div>{formatDate(data)}</div>
      },
      {
        title: "보낸 주소",
        dataIndex: "from",
        className: "text-center"
      },
      {
        title: "받은 주소",
        dataIndex: "to",
        className: "text-center"
      },
      {
        title: "금액",
        dataIndex: "amount",
        className: "text-center",
        render: (data, row) => <div>{coinDivideFormatByDecimal(data, 9)}</div>
      },
      {
        title: "이메일",
        dataIndex: "email",
        className: "text-center"
      },
      {
        title: "입금 주소",
        dataIndex: "depositAddress",
        className: "text-center"
      },
      {
        title: "TXID",
        dataIndex: "txid",
        className: "text-center",
        render: (data, row) => <div>{checkData(data)}</div>
      },
      {
        title: "상태",
        dataIndex: "status",
        className: "text-center",
        render: (data, row) => (
          <div>
            {data === "REQUEST" ? (
              <div>
                <Button
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    this.onStatusUpdate("ACCEPT", row.idx);
                  }}>
                  {string.exchangeString["ACCEPT"]}
                </Button>
                <Button
                  onClick={() => {
                    this.onStatusUpdate("REJECT", row.idx);
                  }}>
                  {string.exchangeString["REJECT"]}
                </Button>
              </div>
            ) : (
              string.exchangeString[data]
            )}
          </div>
        )
      }
    ];

    return (
      <div>
        <div className="title">교환 요청 목록</div>
        <div style={{ marginBottom: "10px", float: "right" }}>
          <label htmlFor="">
            상태&nbsp;&nbsp;
            <SelectBox
              value={string.exchangeString[statusType]}
              code={string.exchangeCode}
              codeString={string.exchangeString}
              onChange={(value) => {
                if (value !== statusType) this.onStatusTypeHandleChange(value);
              }}
              style={{ width: "160px", marginRight: "10px" }}
            />
          </label>
          <label htmlFor="">
            검색조건&nbsp;&nbsp;&nbsp;
            <SelectBox
              value={string.exchangeSearchString[searchType]}
              code={string.exchangeSearch}
              codeString={string.exchangeSearchString}
              onChange={(value) => {
                if (value !== searchType) this.onSearchTypeHandleChange(value);
              }}
              style={{ width: "160px", marginRight: "10px" }}
            />
          </label>
          <Search
            placeholder={`${
              searchType === "TXID"
                ? "TXID를"
                : searchType === "EMAIL"
                ? "이메일을"
                : "주소를"
            } 입력하세요`}
            enterButton="검색"
            allowClear
            onSearch={this.onSearch}
            style={{ width: "300px" }}
          />
        </div>
        <div style={{ clear: "both" }} />
        <Table
          columns={columns}
          rowKey={(record) => record.idx}
          style={{ marginBottom: "10px" }}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default ExchangeRequestList;
