import React, { Component } from "react";

import { Table, Input, Button } from "antd";
import { httpGet, httpPut, httpUrl } from "../../api/httpClient";
import {
  numberFormat,
  formatDate,
  amountFormat,
  checkCoinName,
  checkData
} from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";

const Search = Input.Search;
class Process extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 0
    },
    statusTypes: "ALL",
    reasonTypes: "ALL",
    searchText: ""
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getProcessList({ pageNum: pager.current });
  };

  // 승인 종류 선택
  reasonTypeHandleChange = (value) => {
    this.setState(
      {
        reasonTypes: value
      },
      () => this.getProcessList()
    );
  };

  // 사유 선택
  searchTypeHandleChange = (value) => {
    this.setState(
      {
        statusTypes: value
      },
      () => this.getProcessList()
    );
  };

  // 검색
  onSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.getProcessList();
      }
    );
  };

  // 승인 상태 변경
  onUpdate = (value, row) => {
    const { pagination } = this.state;

    let updateData = {
      idx: row.idx,
      status: value
    };
    if (
      global.confirm(
        checkCoinName(row.coinType) +
          "의 상태를 " +
          string.acceptString[value] +
          "로 변경하시겠습니까?"
      )
    ) {
      httpPut(httpUrl.processUpdate, [], updateData)
        .then((result) => {
          this.getProcessList({ pageNum: pagination.current });
        })
        .catch((error) => {});
    }
  };

  // 거래승인 목록 조회
  getProcessList = (params = {}) => {
    const { statusTypes, reasonTypes, searchText } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.processList,
      [10, pageNum, statusTypes, reasonTypes, searchText],
      {}
    )
      .then((res) => {
        // console.log(`res = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.approvalStandbyList,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getProcessList();
  }

  render() {
    const approvalColumns = [
      {
        width: 120,
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        width: 120,
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "전송 방법",
        dataIndex: "type",
        className: "text-center",
        render: (data) => <div>{string.sendString[data]}</div>
      },
      {
        width: 320,
        title: "보낸 지갑",
        dataIndex: "from",
        className: "text-center",
        render: (data) => (
          <div
            style={{
              whiteSpace: "nowrap",
              width: "300px",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}>
            {checkData(data)}
          </div>
        )
      },
      {
        title: "받은 지갑",
        dataIndex: "to",
        width: 320,
        className: "text-center",
        render: (data) => (
          <div
            style={{
              whiteSpace: "nowrap",
              width: "300px",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}>
            {checkData(data)}
          </div>
        )
      },
      {
        width: 170,
        title: "사유",
        dataIndex: "reason",
        className: "text-center",
        render: (data) => (
          <div
            style={{
              wordWrap: "break-word"
            }}>
            {string.reasonString[data]}
          </div>
        )
      },
      {
        width: 180,
        title: "승인 설정",
        dataIndex: "status",
        className: "text-center",
        render: (data, row) => (
          <div>
            {data === "REQUEST" ? (
              <div>
                <div>
                  <Button
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      this.onUpdate("ACCEPT", row);
                    }}>
                    {string.acceptString["ACCEPT"]}
                  </Button>
                  <Button
                    onClick={() => {
                      this.onUpdate("REJECT", row);
                    }}>
                    {string.acceptString["REJECT"]}
                  </Button>
                </div>
              </div>
            ) : (
              <div>{string.acceptString[data]}</div>
            )}
          </div>
        )
      }
    ];

    const expandedRowRender = (record) => {
      let dataArr = [record];
      const dropColumns = [
        {
          title: "수수료",
          dataIndex: "fee",
          className: "text-center",
          render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
        },
        {
          title: "금액",
          dataIndex: "amount",
          className: "text-center",
          render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
        },
        {
          title: "메모",
          dataIndex: "memo",
          className: "text-center",
          render: (data) => <div>{checkData(data)}</div>
        },
        {
          title: "자동 승인 여부",
          dataIndex: "autoFlag",
          className: "text-center",
          render: (data) => <div>{string.autoFlagString[data]}</div>
        }
      ];
      // console.log(`${JSON.stringify(dataArr, null, 4)}`);
      return (
        <Table
          rowKey={(record) => record.idx}
          columns={dropColumns}
          dataSource={dataArr}
          pagination={false}
        />
      );
    };

    return (
      <div>
        <div className="title">거래승인</div>
        <div className="wrapper mt-35 width-1240" />
        <div style={{ marginBottom: "10px", float: "right" }}>
          <label htmlFor="" style={{}}>
            대상{"  "}
            <SelectBox
              value={string.statusString[this.state.statusTypes]}
              code={string.statusCode}
              codeString={string.statusString}
              onChange={(value) => {
                if (value !== this.state.statusTypes)
                  this.searchTypeHandleChange(value);
              }}
              style={{ width: "160px", marginRight: "10px" }}
            />
            <SelectBox
              value={string.reasonString[this.state.reasonTypes]}
              code={string.reasonCode}
              codeString={string.reasonString}
              onChange={(value) => {
                if (value !== this.state.reasonTypes)
                  this.reasonTypeHandleChange(value);
              }}
              style={{ width: "250px", marginRight: "10px" }}
            />
          </label>
          <label htmlFor="">
            검색기준{"  "}
            <Search
              placeholder="보낸지갑, 받은지갑을 입력하세요"
              enterButton="검색"
              allowClear
              onSearch={this.onSearch}
              style={{ width: "320px" }}
            />
          </label>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          tableLayout="fixed"
          id="process-table"
          rowKey={(record) => record.idx}
          columns={approvalColumns}
          dataSource={this.state.list}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          expandedRowRender={expandedRowRender}
        />
      </div>
    );
  }
}

export default Process;
