import React from "react";
import { connect } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";

import { Layout, Modal } from "antd";
import { httpPost, httpUrl } from "../api/httpClient";
import { logout, login } from "../actions/loginAction";
import Const from "../const";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  initializeUserInfo = () => {
    const userInfo = reactLocalStorage.getObject("adminUser");
    if (!userInfo || !userInfo.id) return;
    this.props.onLogin(userInfo);
  };

  logout = () => {
    reactLocalStorage.remove("adminUser");
    httpPost(httpUrl.logout, [], {})
      .then(() => {
        this.props.onLogout();
        global.location.href = "/";
      })
      .catch((e) => {
        global.location.href = "/";
      });
    reactLocalStorage.remove("coinList");
  };

  componentDidMount() {
    this.initializeUserInfo();
  }

  render() {
    const userInfo = reactLocalStorage.getObject("adminUser");

    return (
      <Layout.Header style={{ background: "#000", padding: 0 }}>
        <div
          className={
            Const.isLogoFlag
              ? "flex-row flex-center flex-sb"
              : "flex-row flex-center flex-end"
          }>
          {Const.isLogoFlag && (
            <img
              src={Const.logoImageWhite}
              alt="symbolImage"
              style={{
                marginLeft: "20px",
                width: "10%",
                height: "5%"
              }}
            />
          )}
          <div
            style={{
              // backgroundColor: "#000",
              fontSize: "14px",
              fontWeight: "bold",
              color: "#fff",
              paddingRight: "20px"
            }}>
            <div style={{ display: "inline-block" }}>
              {userInfo.userId}&nbsp;&nbsp;&nbsp;|
            </div>
            <div
              style={{ display: "inline-block", cursor: "pointer" }}
              onClick={() => {
                this.setState({ visible: true });
              }}>
              &nbsp;&nbsp;&nbsp;로그아웃
            </div>
          </div>
        </div>
        <Modal
          visible={this.state.visible}
          title="로그아웃"
          okText="확인"
          cancelText="취소"
          onOk={this.logout}
          onCancel={() => {
            this.setState({ visible: false });
          }}
          destroyOnClose={true}>
          <div>로그아웃 하시겠습니까?</div>
        </Modal>
      </Layout.Header>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    isLogin: state.login.isLogin,
    loginInfo: state.login.loginInfo
  };
};

let mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (userinfo) => dispatch(login(userinfo)),
    onLogout: () => dispatch(logout())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
