import React, { Component } from "react";
import DaumPostcode from "react-daum-postcode";

import { Modal } from "antd";

class Address extends Component {
  render() {
    const { visible, onCancel, onOk } = this.props;
    const handleComplete = (data) => {
      let fullAddress = data.address;
      let extraAddress = "";

      if (data.addressType === "R") {
        if (data.bname !== "") {
          extraAddress += data.bname;
        }
        if (data.buildingName !== "") {
          extraAddress +=
            extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
        }
        fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
      }
      onOk(fullAddress);
      // console.log(fullAddress); // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
    };

    return (
      <Modal
        visible={visible}
        title="주소 찾기"
        footer={false}
        onCancel={onCancel}
        style={{ minWidth: "600px" }}>
        <DaumPostcode onComplete={handleComplete} />
      </Modal>
    );
  }
}

export default Address;
