import React, { Component } from "react";

import { Table, Button, InputNumber } from "antd";
import { httpGet, httpPut, httpUrl } from "../../api/httpClient";

import {
  amountFormat,
  coinMultiFormat,
  coinDivideFormat,
  checkCoinName
} from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import { showAlert } from "../../components/common/AlertModal";

class Auto extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 0
    },
    statusTypes: "ALL",

    dropPagination: {
      total: 0,
      current: 0
    },
    selectedCoin: "",
    selectedData: []
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getCoinAutoList({
      pageNum: pagination.current
    });
  };

  // 수정
  onUpdate = () => {
    const { selectedData, selectedCoin, pagination } = this.state;

    let data = {
      airDropList: selectedData.map((value, index) => {
        return {
          ...value,
          amount: coinMultiFormat(value.amount, selectedCoin)
        };
      }),
      coinType: selectedCoin
    };

    if (global.confirm(`변경하시겠습니까?`)) {
      httpPut(httpUrl.autoUpdate, [], data)
        .then((res) => {
          showAlert("modify");
          this.setState(
            {
              selectedCoin: ""
            },
            () => {
              this.getCoinAutoList({ pageNum: pagination.current });
            }
          );
        })
        .catch((error) => {});
    }
  };

  // 값 변경
  onChangeModal = (value, row, type) => {
    this.setState({
      selectedData: this.state.selectedData.map((item, index) => {
        if (item.dropType === row.dropType) {
          if (type === "active") {
            return {
              ...item,
              active: value
            };
          } else {
            if (
              coinMultiFormat(parseInt(value), row.coinType) >=
              9223372036854775807
            ) {
              alert(`Too much amount: ${value}`);
              return {
                ...item
              };
            } else {
              return {
                ...item,
                amount: value
              };
            }
          }
        } else {
          return {
            ...item
          };
        }
      })
    });
  };

  // 자동 배포 목록 조회
  getCoinAutoList = (params = {}) => {
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.coinAutoList, [10, pageNum], {})
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.coinAutoList,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getCoinAutoList();
  }

  render() {
    const coinColumns = [
      {
        title: "코인 종류",
        dataIndex: "coinType",
        key: "coinType",
        // width: 120,
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      }
    ];

    const expandedRowRender = (record) => {
      const dropColumns = [
        {
          title: "타입",
          dataIndex: "dropType",
          className: "text-center",
          render: (data) => <div>{string.dropString[data]}</div>
        },
        {
          title: "금액",
          dataIndex: "amount",
          className: "text-center",
          render: (data, row, index) => (
            <div>
              {this.state.selectedCoin === record.coinType ? (
                <InputNumber
                  min={0}
                  // max={9223372036}
                  style={{ width: "130px" }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  value={this.state.selectedData[index].amount}
                  onChange={(value) => {
                    this.onChangeModal(value, row, "amount");
                  }}
                />
              ) : (
                amountFormat(data, record.coinType)
              )}
            </div>
          )
        },
        {
          title: "상태",
          dataIndex: "active",
          className: "text-center",
          render: (data, row, index) => (
            <div>
              {this.state.selectedCoin === record.coinType ? (
                <SelectBox
                  value={
                    string.activeHandlesCodes[
                      this.state.selectedData[index].active
                    ]
                  }
                  code={string.toggleCode}
                  data={row}
                  page="active"
                  codeString={string.activeHandlesCodes}
                  onChange={this.onChangeModal}
                />
              ) : (
                string.activeHandlesCodes[data]
              )}
            </div>
          )
        }
      ];

      return (
        <div>
          <div style={{ float: "right" }}>
            {this.state.selectedCoin !== record.coinType ? (
              <Button
                size="small"
                onClick={() => {
                  this.setState({
                    selectedCoin: record.coinType,
                    selectedData: record.airDropList.map((value, index) => {
                      return {
                        ...value,
                        amount: coinDivideFormat(value.amount, record.coinType)
                      };
                    })
                  });
                }}>
                수정
              </Button>
            ) : (
              <div>
                <Button
                  size="small"
                  style={{ marginRight: "8px" }}
                  onClick={() => {
                    this.onUpdate();
                  }}>
                  확인
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    this.setState({
                      selectedCoin: ""
                    });
                  }}>
                  취소
                </Button>
              </div>
            )}
          </div>
          <div style={{ clear: "both" }} />
          <Table
            rowKey={(record) => `record: ${record.dropType}`}
            columns={dropColumns}
            dataSource={record.airDropList}
          />
        </div>
      );
    };

    return (
      <div>
        {/* {JSON.stringify(this.props.result, null, 4)} */}
        <div className="title">자동배포</div>
        <div className="wrapper mt-35 width-1240" />
        <div style={{ clear: "both" }} />
        <Table
          id="process-table"
          rowKey={(record) => record.coinType}
          columns={coinColumns}
          dataSource={this.state.list}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          expandedRowRender={expandedRowRender}
        />
      </div>
    );
  }
}

export default Auto;
