import React, { Component } from "react";

import { Form, Input, InputNumber, Modal } from "antd";
import { checkCoinName, coinDivideFormat, coinMultiFormat } from "../../util";
import CoinSelectBox from "../common/CoinSelectBox";
import { httpPost, httpUrl } from "../../api/httpClient";
import { ShowErrorAlert } from "../common/ErrorAlertModal";
import { showAlert } from "../common/AlertModal";

const FormItem = Form.Item;
const FoundationModal = Form.create()(
  class extends Component {
    state = {};

    // 회수 시
    onCollectionRequest = (e) => {
      const { form, selectedData } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
        if (!err)
          if (global.confirm("회수하시겠습니까?")) {
            let body = {
              ...values,
              foundationIdx: selectedData.foundationIdx,
              amount: coinMultiFormat(values.amount, values.coinType)
            };
            // console.log(`body = ${JSON.stringify(body, null, 4)}`);
            httpPost(httpUrl.paymentFoundationCollection, [], body)
              .then((res) => {
                // console.log(`res = ${JSON.stringify(res, null, 4)}`);
                if (res.data.result) {
                  showAlert("withdraw");
                  this.props.onCancel();
                  this.props.refresh();
                } else ShowErrorAlert(res.data.resultCode);
              })
              .catch((e) => {});
          }
      });
    };

    // 충전 시
    onChargeRequest = (e) => {
      const { form, foundationIdx } = this.props;

      e.preventDefault(e);
      form.validateFields((err, values) => {
        if (!err)
          if (global.confirm("충전하시겠습니까?")) {
            let body = {
              ...values,
              foundationIdx,
              amount: coinMultiFormat(values.amount, values.coinType)
            };
            // console.log(`values = ${JSON.stringify(body, null, 4)}`);
            httpPost(httpUrl.paymentFoundationCharge, [], body)
              .then((res) => {
                // console.log(`res = ${JSON.stringify(res, null, 4)}`);
                if (res.data.result) {
                  showAlert("charge");
                  this.props.onCancel();
                  this.props.refresh();
                }
              })
              .catch((e) => {});
          }
      });
    };

    onSubmit = (e) => {
      const { visibleType } = this.props;

      if (visibleType === "charge") this.onChargeRequest(e);
      else if (visibleType === "collection") this.onCollectionRequest(e);
    };

    componentDidMount() {
      // console.log(`this.props= ${JSON.stringify(this.props, null, 4)}`);
      const { visibleType, form, selectedData } = this.props;
      if (visibleType === "collection")
        form.setFieldsValue({ coinType: selectedData.coinType });
    }

    render() {
      const { visible, visibleType, onCancel, form, selectedData } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12 }
        }
      };

      return (
        <div>
          <Modal
            style={{ minWidth: "600px" }}
            visible={visible}
            title={`코인 ${visibleType === "charge" ? "충전" : "회수"}`}
            okText={`${visibleType === "charge" ? "충전" : "회수"}하기`}
            onOk={this.onSubmit}
            cancelText="취소"
            onCancel={onCancel}>
            <Form {...formItemLayout} onSubmit={this.onSubmit}>
              <FormItem label={<span>코인 종류&nbsp;</span>}>
                {getFieldDecorator("coinType", {
                  rules: [
                    {
                      required: true,
                      message: "코인 종류를 선택해주세요"
                    }
                  ]
                })(
                  <div>
                    {visibleType === "charge" ? (
                      <CoinSelectBox
                        style={{ width: "160px" }}
                        placeholder="선택해주세요"
                        value={this.state.coinType}
                        onChange={(value) => {
                          this.setState({ coinType: value });
                          form.setFieldsValue({ coinType: value });
                        }}
                      />
                    ) : (
                      checkCoinName(selectedData.coinType)
                    )}
                  </div>
                )}
              </FormItem>
              <FormItem label={<span>코인 수량&nbsp;</span>}>
                {getFieldDecorator("amount", {
                  rules: [
                    {
                      required: true,
                      message: "수량을 입력해주세요!"
                    },
                    {
                      validator: (rule, value, cb) => {
                        if (
                          visibleType === "collection" &&
                          value >
                            coinDivideFormat(
                              selectedData.balance,
                              selectedData.coinType
                            )
                        )
                          cb("보유 수량보다 큰 수량을 입력하셨습니다.");
                        cb();
                      }
                    }
                  ]
                })(<InputNumber style={{ width: "250px" }} />)}
                {visibleType === "collection" && (
                  <div>
                    코인 보유 수량 :{" "}
                    {coinDivideFormat(
                      selectedData.balance,
                      selectedData.coinType
                    )}
                  </div>
                )}
              </FormItem>
              <FormItem label={<span>메모&nbsp;</span>}>
                {getFieldDecorator("memo", {
                  rules: [
                    {
                      required: false,
                      message: "메모를 입력해주세요!"
                    }
                  ]
                })(<Input.TextArea />)}
              </FormItem>
            </Form>
          </Modal>
        </div>
      );
    }
  }
);

export default FoundationModal;
