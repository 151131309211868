import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { Select } from "antd";

const Option = Select.Option;
class ExternalFoundationCoinSelectBox extends Component {
  renderOption() {
    let coinList = reactLocalStorage.getObject("coinList");
    let result = [];

    this.props.foundationCoinList.forEach((item, index) => {
      if (coinList.find((coin) => coin.coinType === item.coinType))
        result.push(
          <Option value={item.coinType} key={`${item}${index}`}>
            {coinList.find((coin) => coin.coinType === item.coinType).name}
          </Option>
        );
    });

    return result;
  }

  render() {
    const { size, mode, style, placeholder, value, onChange } = this.props;
    return (
      <Select
        {...this.props}
        size={size}
        mode={mode}
        style={{ width: "120px", ...style }}
        placeholder={placeholder}
        value={value}
        onChange={(value) => {
          onChange(value);
        }}>
        {this.renderOption()}
      </Select>
    );
  }
}

export default ExternalFoundationCoinSelectBox;
