import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Button, Form, Input, Modal } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";

const UserSearch = Form.create()(
  class extends Component {
    state = { address: "" };

    // 회원 목록
    getCoinUserList = (e) => {
      const { form } = this.props;
      // console.log(`coinType = ${JSON.stringify(this.props.coinType)}`);

      e.preventDefault();
      form.validateFields((err, values) => {
        // console.log(`value = ${JSON.stringify(values, null, 4)}`);
        if (!err)
          httpGet(httpUrl.getCoinUserList, [values.coinType, values.id], {})
            .then((res) => {
              // console.log(`res = ${JSON.stringify(res, null, 4)}`);
              if (res.data.result === "SUCCESS")
                this.setState({ address: res.data.data });
              else {
                alert("존재하지 않는 아이디입니다.");
                this.setState({ address: "" });
              }
            })
            .catch((e) => {});
      });
    };

    onOk = () => {
      this.props.onOk(this.state.address);
      this.props.onCancel();
    };

    componentDidMount() {
      this.props.form.setFieldsValue({ coinType: this.props.coinType });
    }

    render() {
      const FormItem = Form.Item;
      let coinList = reactLocalStorage.getObject("coinList");
      const { form, visible, onCancel, coinType } = this.props;
      const { getFieldDecorator } = form;
      const { address } = this.state;

      const formItemLayout = {
        labelCol: {
          xs: { span: 8 },
          sm: { span: 8 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };

      return (
        <Modal
          visible={visible}
          style={{ minWidth: "600px" }}
          title="회원검색"
          cancelText="취소"
          onCancel={onCancel}
          footer={null}>
          <Form {...formItemLayout}>
            <FormItem label={<span>코인종류&nbsp;</span>}>
              {getFieldDecorator("coinType", {
                rules: [
                  {
                    required: true,
                    message: "코인종류를 선택해주세요!"
                  }
                ]
              })(
                <div>
                  {coinList.find((coin) => coin.coinType === coinType).name}
                </div>
              )}
            </FormItem>
            <FormItem label={<span>ID&nbsp;</span>}>
              {getFieldDecorator("id", {
                rules: [
                  {
                    required: true,
                    message: "아이디를 입력하주세요!"
                  }
                ]
              })(
                <div
                  className="flex-row flex-center"
                  style={{ alignContent: "center" }}>
                  <Input style={{ width: "180px", marginRight: "10px" }} />
                  <Button onClick={this.getCoinUserList}>검색</Button>
                </div>
              )}
            </FormItem>
            {address !== "" && (
              <FormItem label={<span>&nbsp;</span>} colon={false}>
                <div>{address}</div>
                <Button onClick={this.onOk}>확인</Button>
              </FormItem>
            )}
          </Form>
        </Modal>
      );
    }
  }
);
export default UserSearch;
