import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Table, Input, Button, Checkbox, DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";

import { httpGet, httpUrl, httpPut } from "../../api/httpClient";
import {
  numberFormat,
  formatDate,
  startDateFormat,
  endDateFormat
} from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import { showAlert } from "../../components/common/AlertModal";
import WalletModal from "../../components/user/WalletModal";
import WalletLogModal from "../../components/user/WalletLogModal";
import LockListModal from "../../components/user/LockListModal";

const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;
class User extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 0
    },
    deleted: false,
    searchType: "ID",
    searchText: "",
    startDate: "",
    endDate: "",

    selectedSerial: "",
    walletVisible: false,
    walletLogVisible: false,
    coinLockVisible: false
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getUserList({ pageNum: pager.current });
  };

  // 삭제 포함여부 설정
  deletedHandleChange = (e) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      deleted: e.target.checked
    });
  };

  // 검색 대상 설정
  searchTypeHandleChange = (value) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      searchType: value
    });
  };

  // 검색 기간 설정
  handleChangeDate = (date) => {
    this.setState({
      startDate: date.length !== 0 ? startDateFormat(date[0]) : "",
      endDate: date.length !== 0 ? endDateFormat(date[1]) : ""
    });
  };

  // 검색
  onSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.getUserList();
      }
    );
  };

  // 회원 삭제 및 복구
  onDelete = (row) => {
    const { deleted, pagination } = this.state;

    let pageNum =
      pagination.current > 1 && !deleted && row.deleted === 0
        ? this.state.list.length === 1
          ? pagination.current - 1
          : pagination.current
        : pagination.current;
    if (
      global.confirm(
        `${row.id}을(를) ${row.deleted ? "복구" : "삭제"}하시겠습니까?`
      )
    ) {
      httpPut(httpUrl.userDelete, [row.idx], {})
        .then((result) => {
          if (row.deleted) showAlert("restore");
          else showAlert("delete");
          this.getUserList({ pageNum });
        })
        .catch((error) => {});
    }
  };

  // 회원 정보 리스트
  getUserList = (params = {}) => {
    const { deleted, searchText, searchType, startDate, endDate } = this.state;
    let pageNum = params.pageNum || 1;
    if (params.refresh) pageNum = this.state.pagination.current;

    httpGet(
      httpUrl.userList,
      [10, pageNum, deleted, searchText, searchType, startDate, endDate],
      {}
    )
      .then((res) => {
        // console.log(`data= ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.userList === null ? [] : res.data.userList,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    const userInfo = reactLocalStorage.getObject("adminUser");
    if (userInfo.authList) {
      this.getUserList();
    }
    // console.log(global.lanList);
  }

  render() {
    const {
      walletVisible,
      selectedSerial,
      walletLogVisible,
      coinLockVisible,
      userId,
      searchType,
      list,
      pagination
    } = this.state;

    const columns = [
      {
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data, row) => <div>{formatDate(data)}</div>
      },
      {
        title: "아이디",
        dataIndex: "id",
        className: "text-center"
      },
      {
        title: "시리얼번호",
        dataIndex: "serial",
        className: "text-center"
      },
      {
        title: "",
        dataIndex: "",
        className: "text-center",
        render: (data, row) => {
          return (
            <Button
              onClick={() => {
                this.setState({
                  selectedSerial: row,
                  walletVisible: true
                });
              }}>
              잔액조회
            </Button>
          );
        }
      },
      {
        title: "거래내역",
        dataIndex: "",
        className: "text-center",
        render: (data, row) => (
          <div>
            <Button
              onClick={() => {
                this.setState({
                  selectedSerial: row,
                  walletLogVisible: true
                });
              }}>
              내역보기
            </Button>
          </div>
        )
      },
      {
        title: "LOCK내역",
        dataIndex: "",
        className: "text-center",
        render: (data, row) => (
          <div>
            <Button
              onClick={() => {
                this.setState({
                  userId: row.id,
                  coinLockVisible: true
                });
              }}>
              코인LOCK
            </Button>
          </div>
        )
      },
      {
        title: "상태",
        dataIndex: "deleted",
        className: "text-center",
        render:
          // data => <div>{data === 0 ? '삭제' : '-'}</div>
          (data, row) => (
            <SelectBox
              value={string.deleteString[data]}
              code={string.toggleCode}
              codeString={string.deleteString}
              onChange={(value) => {
                if (parseInt(value) !== row.deleted) {
                  this.onDelete(row);
                }
              }}
            />
          )
      }
    ];

    return (
      <div>
        {/* 지갑정보 */}
        {walletVisible && (
          <WalletModal
            page={true}
            visible={walletVisible}
            selectedSerial={selectedSerial}
            onCancel={() => {
              this.setState({
                walletVisible: false
              });
            }}
          />
        )}
        {/* 거래내역 */}
        {walletLogVisible && (
          <WalletLogModal
            page={true}
            visible={walletLogVisible}
            selectedSerial={selectedSerial}
            onOk={() => {
              this.setState({
                walletLogVisible: false
              });
            }}
          />
        )}
        {/* 회원 코인 락 정보 */}
        {coinLockVisible && (
          <LockListModal
            visible={coinLockVisible}
            searchText={userId}
            onCancel={() => {
              this.setState({
                coinLockVisible: false
              });
            }}
          />
        )}

        <div className="title">회원 관리</div>
        <div className="flex-row flex-center m-b-10 flex-sb">
          <div className="flex-row flex-center">
            <label>검색기간&nbsp;</label>
            <RangePicker
              locale={locale}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
            />
          </div>
          <div className="flex-row flex-center">
            <label htmlFor="" style={{ marginRight: "10px" }}>
              검색조건&nbsp;&nbsp;&nbsp;
              <Checkbox onChange={this.deletedHandleChange}>삭제 포함</Checkbox>
            </label>
            <label htmlFor="searchType">검색 대상&nbsp;</label>
            <SelectBox
              value={searchType}
              style={{ width: "160px", marginRight: "10px" }}
              code={string.memberSearchTypeCode}
              codeString={string.searchTypeString}
              onChange={(value) => {
                if (value !== searchType) {
                  this.searchTypeHandleChange(value);
                }
              }}
            />
            <Search
              placeholder={`검색어를 입력해주세요.`}
              enterButton="검색"
              allowClear
              onSearch={this.onSearch}
              style={{ width: 300 }}
            />
          </div>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="member-table"
          className="flex-1"
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default User;
