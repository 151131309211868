import React, { Component } from "react";
import { Form, Modal, Input, InputNumber } from "antd";
import { httpPost, httpUrl, httpPut } from "../../api/httpClient";
import { showAlert } from "../common/AlertModal";

const FormItem = Form.Item;
const FranchiseUpdateModal = Form.create({ name: "form_in_modal" })(
  class extends Component {
    componentDidUpdate(prevProps) {
      let modalData = { ...this.props.modalData };
      if (
        prevProps.visible !== this.props.visible &&
        !prevProps.visible &&
        this.props.modalType === "edit"
      ) {
        this.props.form.setFieldsValue({
          id: modalData.id,
          name: modalData.name,
          phone: modalData.phone,
          address: modalData.address,
          ratio: modalData.ratio * 100,
          description: modalData.description
        });
      }
    }

    onOk = () => {
      let modalString = this.props.modalType === "create" ? "생성" : "수정";
      let body = this.props.form.getFieldsValue();
      if (body.ratio) {
        body = {
          ...body,
          ratio: body.ratio / 100
        };
      }
      if (global.confirm(modalString + "하시겠습니까?")) {
        if (this.props.modalType === "create") {
          httpPost(httpUrl.franchiseCreate, [], body).then(res => {
            if (res.data.data === "ID_NOT_EXIST") {
              alert("아이디가 존재하지 않습니다.");
            } else if (res.data.data === "DUPLICATE") {
              alert("해당 아이디로 생성된 가맹점이 있습니다.");
            } else {
              showAlert("create");

              this.onCancel();
              this.props.refresh();
            }
          });
        } else {
          httpPut(httpUrl.franchiseUpdate, [], body).then(res => {
            showAlert("update");
            this.props.refresh();
            this.onCancel();
          });
        }
      }
    };

    onCancel = () => {
      this.props.form.resetFields();
      this.props.onCancel();
    };

    render() {
      const { visible, form, modalType } = this.props;
      const { getFieldDecorator } = form;
      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 18 }
        }
      };
      return (
        <Modal
          visible={visible}
          title={`가맹점 ${
            this.props.modalType === "create" ? "생성" : "상세 정보"
          }`}
          okText={this.props.modalType === "create" ? "생성" : "수정"}
          onCancel={this.onCancel}
          onOk={this.onOk}>
          <Form {...formItemLayout}>
            <FormItem label="아이디">
              {getFieldDecorator("id", {
                rules: [
                  {
                    required: true,
                    message: "아이디를 입력해주세요!"
                  }
                ]
              })(
                modalType === "create" ? (
                  <Input placeholder="아이디를 입력해주세요." />
                ) : (
                  <div>{this.props.modalData.id}</div>
                )
              )}
            </FormItem>
            <FormItem label="이름">
              {getFieldDecorator("name", {
                rules: [
                  {
                    required: true,
                    message: "이름을 입력해주세요!"
                  }
                ]
              })(<Input placeholder="이름을 입력해주세요." />)}
            </FormItem>
            <FormItem label="전화번호">
              {getFieldDecorator("phone", {
                rules: [
                  {
                    required: true,
                    message: "전화번호를 입력해주세요!"
                  }
                ]
              })(<Input placeholder="전화번호를 입력해주세요." />)}
            </FormItem>
            <FormItem label="주소">
              {getFieldDecorator("address", {
                rules: [
                  {
                    required: true,
                    message: "주소를 입력해주세요.!"
                  }
                ]
              })(<Input placeholder="주소를 입력해주세요." />)}
            </FormItem>
            <FormItem label="비율(%)">
              {getFieldDecorator("ratio", {
                rules: [
                  {
                    required: true,
                    message: "정산수수료 비율를 입력해주세요!"
                  }
                ]
              })(
                <InputNumber
                  min={0}
                  max={100}
                  style={{ width: "350px" }}
                  placeholder="정산수수료 비율를 입력해주세요."
                />
              )}
            </FormItem>
            <FormItem label="상세내용">
              {getFieldDecorator("description", {
                rules: [
                  {
                    required: true,
                    message: "상세내용을 입력해주세요!"
                  }
                ]
              })(<Input.TextArea placeholder="상세내용을 입력해주세요." />)}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

export default FranchiseUpdateModal;
