import React, { Component } from "react";

import { Modal, Descriptions } from "antd";
import { formatDate } from "../../util";
import string from "../../string";

const Ditems = Descriptions.Item;

class FranchiseDetail extends Component {
  state = {
    detailData: {}
  };
  componentDidUpdate(prevProps) {
    if (
      this.props.modalData.idx !== prevProps.modalData.idx ||
      this.props.modalData.deleted !== prevProps.modalData.deleted
    ) {
      const obj = Object.assign({}, this.props.modalData);
      this.setState({
        detailData: obj
      });
    }
  }

  render() {
    const { visible, onCancel } = this.props;
    const { detailData } = this.state;

    return (
      <Modal
        visible={visible}
        title="가맹점 정보"
        onCancel={onCancel}
        footer={null}
        style={{ minWidth: "1000px" }}>
        {/* <div>{JSON.stringify(modalData)}</div> */}
        <Descriptions
          bordered
          column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}>
          <Ditems label="생성일">{formatDate(detailData.createDate)}</Ditems>
          <Ditems label="탈퇴">{string.userString[detailData.deleted]}</Ditems>
          <Ditems label="상태">
            <div>{string.franchiseListStatusString[detailData.status]}</div>
          </Ditems>
          <Ditems label="이름">
            <div>{detailData.name}</div>
          </Ditems>
          <Ditems label="아이디(이메일)">{detailData.id}</Ditems>
          <Ditems label="주소">
            <div>{detailData.address}</div>
          </Ditems>
          <Ditems label="휴대폰">
            <div>{detailData.phone}</div>
          </Ditems>
          <Ditems label="시리얼번호" span={2}>
            {detailData.serialNum}
          </Ditems>
          <Ditems label="상세" span={2}>
            <div>{detailData.description}</div>
          </Ditems>
        </Descriptions>
      </Modal>
    );
  }
}

export default FranchiseDetail;
