import React, { Component } from "react";

import { Modal, Form, InputNumber } from "antd";
import { httpGet, httpUrl, httpPost, httpPut } from "../../api/httpClient";

import CoinSelectBox from "../common/CoinSelectBox";
import { showAlert } from "../common/AlertModal";
import { checkCoinName } from "../../util";

const FormItem = Form.Item;
const TraceRatioModal = Form.create()(
  class extends Component {
    state = {
      foundationList: [],
      fCoinList: [],
      fromCoinType: 0,
      toCoinType: 0,
      ratio: 0
    };

    onOk = (e) => {
      const { form, type, detail } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
        if (!err) {
          let http = httpPost;
          let url = httpUrl.tradeRatioCreate;
          let body = { ...values };
          if (type) {
            http = httpPut;
            url = httpUrl.tradeRatioUpdate;
            body = { ...detail, ...body };
          }
          // console.log(`body = ${JSON.stringify(body, null, 4)}`);

          if (global.confirm(type ? "수정하시겠습니까?" : "생성하시겠습니까?"))
            http(url, [], body)
              .then((res) => {
                // console.log(`res = ${JSON.stringify(res, null, 4)}`);
                showAlert(type ? "update" : "create");
                this.props.onCancel();
                this.props.refresh();
              })
              .catch((e) => {});
        }
      });
    };

    getFoundationCoinList = () => {
      httpGet(httpUrl.getFoundationList, [], {})
        .then((res) => {
          this.setState({
            foundationList: res.data,
            fCoinList: res.data.map((item) => {
              return item.coinType;
            })
          });
        })
        .catch((e) => {});
    };

    componentDidMount() {
      const { type, form, detail } = this.props;

      if (type) form.setFieldsValue(detail);
      // else form.setFieldsValue({ ratio: 0 });

      this.getFoundationCoinList();
    }

    render() {
      const { fCoinList, fromCoinType, toCoinType } = this.state;
      const { form, visible, type, onCancel, detail } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12 }
        }
      };

      return (
        <Modal
          visible={visible}
          title={type ? "교환 비율 상세" : "교환 비율 생성"}
          onCancel={onCancel}
          onOk={this.onOk}
          okText={type ? "수정" : "생성"}
          cancelText="취소">
          <Form {...formItemLayout} onSubmit={this.onOk}>
            <FormItem label={<span>교환코인&nbsp;</span>}>
              {type
                ? checkCoinName(detail.fromCoinType)
                : getFieldDecorator("fromCoinType", {
                    rules: [
                      {
                        required: true,
                        message: "교환코인을 선택해주세요!"
                      }
                    ]
                  })(
                    <CoinSelectBox
                      style={{ width: "200px" }}
                      type="coinType"
                      placeholder="선택해주세요."
                      coinCode={fCoinList}
                      onChange={(value) => {
                        if (value !== fromCoinType)
                          this.setState({ fromCoinType: value });
                      }}
                    />
                  )}
            </FormItem>
            <FormItem label={<span>교환 요청 코인&nbsp;</span>}>
              {type
                ? checkCoinName(detail.toCoinType)
                : getFieldDecorator("toCoinType", {
                    rules: [
                      {
                        required: true,
                        message: "교환 요청 코인을 선택해주세요!"
                      },
                      {
                        validator: (rule, value, callback) => {
                          if (value === form.getFieldValue("fromCoinType"))
                            callback("다른 코인을 선택해주세요.");
                          callback();
                        }
                      }
                    ]
                  })(
                    <CoinSelectBox
                      style={{ width: "200px" }}
                      type="coinType"
                      placeholder="선택해주세요."
                      coinCode={fCoinList}
                      onChange={(value) => {
                        if (value !== toCoinType)
                          this.setState({ toCoinType: value });
                      }}
                    />
                  )}
            </FormItem>
            <FormItem label={<span>비율 (%)&nbsp;</span>}>
              {getFieldDecorator("ratio", {
                rules: [
                  {
                    required: true,
                    message: "비율을 입력해주세요!"
                  },
                  {
                    validator: (rule, value, callback) => {
                      if (value === 0) callback("0보다 큰 값을 입력해주세요.");
                      callback();
                    }
                  }
                ]
              })(<InputNumber min={0} max={100} style={{ width: "150px" }} />)}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);
export default TraceRatioModal;
