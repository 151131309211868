import React, { Component } from "react";

import { Table, Input, Checkbox, Button } from "antd";
import { httpGet, httpUrl, httpPut } from "../../api/httpClient";

import string from "../../string";
import { numberFormat, formatDate, checkData } from "../../util";
import SelectBox from "../../components/common/SelectBox";
import OrganizationRegistry from "../../components/payment/OrganizationRegistry";
import OrganizationDetail from "../../components/payment/OrganizationDetail";
import PaymentSetting from "../../components/payment/PaymentSetting";
import LowerOrganization from "../../components/payment/LowerOrganization";
import OrganizationCategory from "../../components/payment/OrganizationCategory";

const Search = Input.Search;

class Organization extends Component {
  state = {
    organizationList: [],
    pagination: {
      current: 1,
      total: 0
    },
    franchiseType: "ALL",
    deleted: false,
    searchText: "",

    createVisible: false,
    detailVisible: false,
    detailData: {}
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getOrganiztionList({ pageNum: pager.current });
  };

  // 가맹점 조건 선택
  franchiseTypeHandleChange = (value) => {
    this.setState({
      franchiseType: value
    });
  };

  // 삭제 포함 여부 설정
  deleteHandleChange = (e) => {
    this.setState({
      deleted: e.target.checked
    });
  };

  // 검색
  onSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.getOrganiztionList();
      }
    );
  };

  // 삭제 및 복구
  onDelete = (row) => {
    const { pagination, organizationList, deleted } = this.state;

    let pageNum =
      pagination.current > 1 && !deleted && row.deleted === 0
        ? organizationList.length === 1
          ? pagination.current - 1
          : pagination.current
        : pagination.current;
    if (
      global.confirm(
        `${row.name}을(를) ${row.deleted ? "복구" : "삭제"}하시겠습니까?`
      )
    ) {
      httpPut(httpUrl.organizationDelete, [row.idx], {})
        .then((result) => {
          this.getOrganiztionList({
            pageNum: pageNum
          });
        })
        .catch((error) => {});
    }
  };

  getOrganiztionList = (params = {}) => {
    const { deleted, franchiseType, searchText } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getOrganiztionList,
      [10, pageNum, deleted, franchiseType, searchText],
      {}
    )
      .then((res) => {
        // console.log(JSON.stringify(res.data, null, 4));
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          organizationList: res.data.list,
          pagination
        });
      })
      .catch((error) => {});
  };

  componentDidMount() {
    this.getOrganiztionList();
  }

  render() {
    const {
      createVisible,
      detailVisible,
      paymentVisible,
      categoryVisible,
      lowerVisible,
      franchiseType,
      organizationList
    } = this.state;

    const columns = [
      {
        width: 120,
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        width: 120,
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "조직명",
        dataIndex: "name",
        className: "text-center",
        render: (data, row, index) => {
          return (
            <div
              key={index}
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() => {
                this.setState({
                  detailVisible: true,
                  detailData: row
                });
              }}>
              {data}
            </div>
          );
        }
      },
      {
        title: "사업자번호",
        dataIndex: "businessNum",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      },
      {
        title: "상위 조직 1단계",
        dataIndex: "parentName",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      },
      {
        title: "상위 조직 2단계",
        dataIndex: "upperOrg",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      },
      {
        title: "가맹점 여부",
        dataIndex: "franchise",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      },
      {
        title: "자동 정산 여부",
        dataIndex: "autoAdjustment",
        // width: 80,
        className: "text-center",
        render: (data) => <div>{data ? "O" : "X"}</div>
      },
      {
        title: "결제 및 페이백",
        dataIndex: "payment",
        className: "text-center",
        render: (data, row) =>
          row.franchise ? (
            <Button
              style={{ width: "80px" }}
              onClick={() => {
                this.setState({
                  paymentVisible: true,
                  organizationIdx: row.idx
                });
              }}>
              설정
            </Button>
          ) : (
            "-"
          )
      },
      {
        title: "카테고리 설정",
        dataIndex: "category",
        className: "text-center",
        render: (data, row) =>
          row.franchise ? (
            <Button
              style={{ width: "80px" }}
              onClick={() => {
                this.setState({
                  categoryVisible: true,
                  organizationIdx: row.idx
                });
              }}>
              설정
            </Button>
          ) : (
            "-"
          )
      },
      {
        title: "하위 조직",
        dataIndex: "under",
        className: "text-center",
        render: (data, row) => (
          <Button
            onClick={() => {
              this.setState({
                lowerVisible: true,
                organizationIdx: row.idx
              });
            }}>
            상세보기
          </Button>
        )
      },
      {
        title: "상태",
        dataIndex: "deleted",
        className: "text-center",
        render:
          // data => <div>{data === 0 ? '삭제' : '-'}</div>
          (data, row) => (
            <SelectBox
              value={string.deleteString[data]}
              code={string.toggleCode}
              codeString={string.deleteString}
              onChange={(value) => {
                if (parseInt(value) !== row.deleted) {
                  this.onDelete(row);
                }
              }}
            />
          )
      }
    ];

    return (
      <div>
        {/* 생성 */}
        {createVisible && (
          <OrganizationRegistry
            visible={createVisible}
            onOk={() => {
              this.setState({
                createVisible: false
              });
              this.getOrganiztionList();
            }}
            onCancel={() => {
              this.setState({
                createVisible: false
              });
            }}
          />
        )}
        {/* 상세 */}
        {detailVisible && (
          <OrganizationDetail
            modalData={this.state.detailData}
            visible={detailVisible}
            onOk={() => {
              this.setState({
                detailVisible: false
              });
              this.getOrganiztionList({
                pageNum: this.state.pagination.current
              });
            }}
            onCancel={() => {
              this.setState({
                detailVisible: false
              });
            }}
          />
        )}
        {/* 결제 및 페이백 */}
        {paymentVisible && (
          <PaymentSetting
            organizationIdx={this.state.organizationIdx}
            visible={paymentVisible}
            onCancel={() => {
              this.setState({
                paymentVisible: false
              });
            }}
          />
        )}
        {/* 카테고리 */}
        {categoryVisible && (
          <OrganizationCategory
            organizationIdx={this.state.organizationIdx}
            visible={categoryVisible}
            type={false}
            onCancel={() => {
              this.setState({
                categoryVisible: false
              });
            }}
          />
        )}
        {/* 하위조직 */}
        {lowerVisible && (
          <LowerOrganization
            organizationIdx={this.state.organizationIdx}
            visible={lowerVisible}
            onCancel={() => {
              this.setState({
                lowerVisible: false
              });
            }}
          />
        )}
        <div className="title">조직관리</div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <Button
            onClick={() => {
              this.setState({ createVisible: true });
            }}>
            생성
          </Button>
          <div className="flex-row flex-center">
            <label htmlFor="">
              검색조건&nbsp;&nbsp;
              <SelectBox
                value={string.organizationString[franchiseType]}
                code={string.organizationCode}
                codeString={string.organizationString}
                onChange={(value) => {
                  if (value !== franchiseType)
                    this.franchiseTypeHandleChange(value);
                }}
                style={{ width: "160px", marginRight: "10px" }}
              />
            </label>
            <Checkbox onChange={this.deleteHandleChange}>삭제 포함</Checkbox>
            <Search
              placeholder="조직명, 사업자번호를 입력해주세요."
              enterButton="검색"
              allowClear
              onSearch={this.onSearch}
              style={{ width: 400 }}
            />
          </div>
        </div>
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={organizationList}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default Organization;
