import React, { Component } from "react";
import { Modal, Form, Input, Upload, Button, Icon } from "antd";
import { serverUrl, httpUrl, httpPost, httpPut } from "../../api/httpClient";
import { showAlert } from "../common/AlertModal";

const FormItem = Form.Item;

class LinkDetailModal extends Component {
  state = {
    linkData: {}
  };

  onCancel = () => {
    this.setState(
      {
        linkData: {}
      },
      () => {
        this.props.form.resetFields();
        this.props.onCancel();
      }
    );
  };

  onChangeModal = (value, type, language) => {
    const tempData = Object.assign({}, this.state.linkData);
    if (type === "name") {
      let arr = tempData[type];
      arr = arr.map((item) => {
        if (item.language === language) {
          return {
            content: value,
            language: language
          };
        } else {
          return item;
        }
      });
      tempData[type] = arr;
    } else {
      tempData[type] = value;
    }
    this.setState({
      linkData: tempData
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.props.modalType === "create") {
          if (global.confirm(`생성하시겠습니까?`)) {
            httpPost(httpUrl.linkCreate, [], {
              ...this.state.linkData,
              rank: this.props.lastRank
            })
              .then((res) => {
                showAlert("create");
                this.props.form.resetFields();
                this.props.onOk();
                this.props.onCancel();
              })
              .catch((e) => {});
          }
        } else {
          if (global.confirm(`수정하시겠습니까?`)) {
            let updateData = {
              idx: this.state.linkData.idx,
              image: this.state.linkData.image,
              name: this.state.linkData.name,
              nameIdx: this.state.linkData.nameIdx,
              url: this.state.linkData.url
            };
            // console.log(JSON.stringify(updateData, null, 4));
            httpPut(httpUrl.linkUpdate, [], updateData)
              .then((res) => {
                showAlert("update");
                this.props.form.resetFields();
                this.props.onOk();
                this.props.onCancel();
              })
              .catch((e) => {});
          }
        }
      }
    });
  };

  componentDidMount() {
    // console.log(`props = ${JSON.stringify(this.props, null, 4)}`);
    const { modalType, form, modalData } = this.props;

    if (modalType === "create") {
      form.resetFields();
      this.setState({
        linkData: {
          name: global.lanList.map((lan) => {
            return {
              content: "",
              language: lan
            };
          })
        }
      });
    }
    if (modalType === "edit") {
      let nameList = global.lanList.map((lan) => {
        if (modalData.name.find((item) => item.language === lan)) {
          return modalData.name.find((item) => item.language === lan);
        } else {
          return {
            content: "",
            language: lan
          };
        }
      });

      form.setFieldsValue({
        name: nameList.map((item) => {
          return item.content;
        }),
        image: modalData.image,
        url: modalData.url
      });

      this.setState({
        linkData: {
          ...modalData,
          name: nameList
        }
      });
    }
  }

  render() {
    const { linkData } = this.state;
    const { visible, form, modalType } = this.props;
    const { getFieldDecorator } = form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      }
    };

    const imageProps = {
      action: serverUrl + httpUrl.imageEncoding,
      multiple: false,
      withCredentials: true,
      beforeUpload: (file) => {
        if (file.type !== "image/png") {
          alert("이미지파일은 png형식만 업로드 가능합니다.");
          return false;
        } else {
          if (global.confirm("업로드 하시겠습니까?")) {
            return true;
          } else {
            return false;
          }
        }
      },
      onSuccess: (file) => {
        try {
          this.onChangeModal(file.data.encodingImage, "image");
        } finally {
          alert("이미지 업로드 완료");
        }
      },
      onError(err) {
        alert("이미지 업로드 실패");
      }
    };
    return (
      <Modal
        visible={visible}
        style={{ minWidth: "800px" }}
        title={modalType === "create" ? "생성하기" : "수정하기"}
        okText={modalType === "create" ? "생성" : "수정"}
        cancelText="취소"
        onOk={this.handleSubmit}
        onCancel={this.onCancel}>
        <Form onSubmit={this.handleSubmit} {...formItemLayout}>
          <FormItem label={<span>이미지 등록 &nbsp;</span>}>
            {getFieldDecorator("image", {
              rules: [
                {
                  required: true,
                  message: "이미지를 등록해주세요!"
                }
              ]
            })(
              <Upload {...imageProps} showUploadList={false}>
                {linkData.image ? (
                  <div>
                    <img
                      src={linkData.image}
                      alt="symbolImage"
                      style={{
                        width: "30px",
                        marginRight: "8px",
                        cursor: "pointer"
                      }}
                    />
                    <span
                      style={{
                        fontSize: "14px",
                        color: "red",
                        marginTop: "5px"
                      }}>
                      이미지를 변경하고 싶으시면 이미지를 클릭하세요.
                    </span>
                  </div>
                ) : (
                  <Button style={{ marginRight: "8px" }}>
                    <Icon type="upload" />
                    업로드
                  </Button>
                )}
              </Upload>
            )}
          </FormItem>
          {global.lanList.map((lan, index) => (
            <FormItem
              key={`names${lan}${index}`}
              label={<span>{`이름(${lan})`}&nbsp;</span>}>
              {getFieldDecorator(`name[${index}]`, {
                rules: [
                  {
                    required: lan === "ko" ? true : false,
                    message: "이름을 입력해주세요!"
                  }
                ]
              })(
                <Input
                  allowClear
                  onChange={(e) => {
                    this.onChangeModal(e.target.value, "name", lan);
                  }}
                />
              )}
            </FormItem>
          ))}

          <FormItem label={<span>URL&nbsp;</span>}>
            {getFieldDecorator("url", {
              rules: [
                {
                  required: true,
                  message: "url을 입력해주세요!"
                }
              ]
            })(
              <Input
                allowClear
                onChange={(e) => {
                  this.onChangeModal(e.target.value, "url");
                }}
              />
            )}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

LinkDetailModal = Form.create()(LinkDetailModal);

export default LinkDetailModal;
