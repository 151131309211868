import React, { Component } from "react";
import { Select } from "antd";

const Option = Select.Option;
class ExternalFoundationSelectBox extends Component {
  renderOption() {
    let result = [];
    this.props.foundationList.forEach((item, index) => {
      result.push(
        <Option value={item.idx} key={`${item}${index}`}>
          {item.siteInfo}
        </Option>
      );
    });

    return result;
  }

  render() {
    const { size, mode, style, placeholder, value, onChange } = this.props;
    return (
      <Select
        {...this.props}
        size={size}
        mode={mode}
        style={{ width: "120px", ...style }}
        placeholder={placeholder}
        value={value}
        onChange={(value) => {
          onChange(value);
        }}>
        {this.renderOption()}
      </Select>
    );
  }
}

export default ExternalFoundationSelectBox;
