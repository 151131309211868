import React, { Component } from "react";

import { Button, InputNumber } from "antd";
// import { httpGet, httpUrl } from "../../api/httpClient";
import UserList from "../../components/payment/UserList";
import { checkCoinName } from "../../util";

var QRCode = require("qrcode.react");
class CreateQR extends Component {
  state = {
    userVisible: false,
    selectedUser: {},
    selectedUserWalletList: [],
    price: ""
  };

  onSelect = (data, type) => {
    this.setState({ selectedUser: data });
    // httpGet(httpUrl.walletListByIdget, [100, 1, data.userIdx]).then((res) => {
    //   let result = res.data.walletList;
    //   if (result == null) result = [];
    //   this.setState({ selectedUserWalletList: result });
    // });
  };

  render() {
    const { userVisible, selectedUser } = this.state;
    return (
      <div>
        <div className="title">결제QR관리</div>
        <div className="flex-row m-b-10">
          <div style={{ marginTop: "4px", marginRight: "10px" }}>가맹점 :</div>
          <Button
            onClick={() => {
              this.setState({
                userVisible: true
              });
            }}>
            {selectedUser.idx !== undefined ? "변경" : "선택"}
          </Button>
          {userVisible && (
            <UserList
              visible={userVisible}
              userIdx={0}
              onCancel={() => {
                this.setState({ userVisible: false });
              }}
              onOk={this.onSelect}
              type="franchise"
            />
          )}
          <div style={{ marginTop: "4px", marginLeft: "10px" }}>
            {this.state.selectedUser.name}
            {/* {JSON.stringify(this.state.selectedUser)} */}
          </div>
        </div>

        <div className="flex-row m-b-10">
          <div style={{ marginTop: "4px", marginRight: "10px" }}>
            결제금액(옵션) :
          </div>
          <InputNumber
            min={0}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            onChange={(value) => this.setState({ price: value })}
            style={{ width: "150px" }}
            placeholder="결제금액"
          />
        </div>
        <div>
          {this.state.selectedUser.idx &&
            this.state.selectedUser.coinTypes.map((value, index) => {
              return (
                <div style={{ padding: "30px", display: "inline-block" }}>
                  <div style={{ fontSize: "28px", fontWeight: "bold" }}>
                    {checkCoinName(value)}
                  </div>
                  {/* {JSON.stringify(value)} */}
                  <QRCode
                    size={512}
                    value={
                      "payment?type=0" +
                      "&coinType=" +
                      value +
                      "&id=" +
                      selectedUser.userId +
                      "&price=" +
                      this.state.price +
                      "&franchiseName=" +
                      encodeURI(selectedUser.name)
                    }
                  />
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

export default CreateQR;
