import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Modal, Table } from "antd";
import { httpPut, httpUrl } from "../../api/httpClient";

import string from "./../../string";
import SelectBox from "../common/SelectBox";
import { showAlert } from "../common/AlertModal";
import { checkCoinName } from "../../util";

class AuthDetail extends Component {
  state = {
    prevData: [],
    selectData: []
  };

  // 코인 권한 변경
  onChangeCoinTypeAuth = (value, row) => {
    this.setState({
      selectData: this.state.selectData.map((item) => {
        if (item.coinType === row.coinType) {
          return {
            ...item,
            auth: value
          };
        } else {
          return {
            ...item
          };
        }
      })
    });
  };

  // 값 정보 변경
  onChangeUserAuth = (value, row) => {
    let menuArr = [...this.state.selectData];
    let auth = parseInt(value);
    // 부모 상태를 가능 / 불가능으로 바꿀때
    if (row.children && (row.children !== null || row.children.length !== 0)) {
      //선택한 자식이 최상위 부모냐, 아니냐
      //부모가 최 상위 조건 일 때
      if (row.parentIdx === 0) {
        for (const child of row.children) {
          child.auth = auth;
          if (
            child.children &&
            (child.children !== null || child.children.length !== 0)
          ) {
            for (const subChild of child.children) {
              subChild.auth = auth;
            }
          }
        }
      } else {
        // 부모가 최상위 조건이 아니면서 가능으로 바꿀때
        if (auth === 1) {
          for (const menu of menuArr) {
            if (menu.idx === row.parentIdx && menu.auth === 0) {
              menu.auth = 1;
              for (const child of menu.children) {
                if (child.idx === row.idx) child.auth = auth;
                if (
                  child.children &&
                  (child.children !== null || child.children.length !== 0)
                ) {
                  for (const subChild of child.children) {
                    subChild.auth = auth;
                  }
                }
              }
            }
            // 부모가 최상위 조건이 아니면서 자식이 포함된 경우 가능으로 바꿀때
            if (menu.idx === row.parentIdx && menu.auth === 1) {
              for (const child of menu.children) {
                if (child.idx === row.idx) child.auth = auth;
                if (
                  child.children &&
                  (child.children !== null || child.children.length !== 0)
                ) {
                  for (const subChild of child.children) {
                    subChild.auth = auth;
                  }
                }
              }
            }
          }
        } else {
          // 부모가 최상위 조건이 아니면서 불가능으로 바꿀때
          for (const menu of menuArr) {
            if (menu.idx === row.parentIdx && menu.auth === 1) {
              menu.auth = 0;
              for (const child of menu.children) {
                if (child.idx === row.idx) child.auth = auth;
                if (
                  child.children &&
                  (child.children !== null || child.children.length !== 0)
                ) {
                  menu.auth = 1;
                  if (
                    menu.children.every((child) => child.auth === 0) &&
                    child.children.some((subChild) => subChild.auth === 1)
                  )
                    menu.auth = 0;

                  for (const subChild of child.children) {
                    subChild.auth = auth;
                  }
                }
              }
            }
          }
        }
      }
    } else {
      // 자식을 가능으로 바꿨을 때 부모가 불가능 일 경우
      if (auth === 1) {
        let isRoot = false;
        for (const menu of menuArr) {
          if (menu.idx === row.parentIdx && menu.auth === 0) {
            isRoot = true;
            menu.auth = 1;
          } else {
            if (!isRoot) {
              let rootIdx = 0;
              for (const menu of menuArr) {
                if (menu.idx === row.parentIdx) {
                  rootIdx = menu.idx;
                } else {
                  if (menu.children !== null) {
                    for (const subMenu of menu.children) {
                      if (subMenu.idx === row.parentIdx) {
                        rootIdx = subMenu.parentIdx;
                      }
                    }
                  }
                }
              }
              for (const menu of menuArr) {
                if (menu.idx === rootIdx) {
                  let rootBool = 0;
                  for (const child of menu.children) {
                    if (child.idx === row.parentIdx) {
                      let childBool = 0;
                      for (const subChild of child.children) {
                        if (subChild.idx === row.idx && subChild.auth === 0) {
                          rootBool = 0;
                        }
                      }
                      if (rootBool === 0 && childBool === 0) {
                        menu.auth = 1;
                      }
                    }
                  }
                }
              }
            }
            if (menu.children !== null) {
              for (const child of menu.children) {
                if (child.idx === row.parentIdx && child.auth === 0) {
                  child.auth = 1;
                }
              }
            }
          }
        }
      } else {
        // 자식하나를 불가능으로 바꿨을 때 부모의 자식이 모두 불가능일 경우
        let isRoot = false; // 1단계 메뉴 여부
        for (const menu of menuArr) {
          if (menu.idx === row.parentIdx) {
            isRoot = true;
            let bool = 0;
            for (const child of menu.children) {
              if (child.idx !== row.idx && child.auth === 1) {
                bool = 1;
              }
            }
            if (bool === 0) {
              menu.auth = 0;
            }
          }
        }
        if (!isRoot) {
          let idx = 0; // 부모의 idx 확인
          let rootIdx = 0; // 3단계일 경우 1단계 부모 idx 확인
          for (const menu of menuArr) {
            if (menu.idx === row.parentIdx) {
              idx = menu.idx;
            } else {
              if (menu.children !== null) {
                for (const subMenu of menu.children) {
                  if (subMenu.idx === row.parentIdx) {
                    idx = subMenu.idx;
                    rootIdx = subMenu.parentIdx;
                  }
                }
              }
            }
          }
          //2depth 를 찾기위한 반복문
          for (const menu of menuArr) {
            if (menu.idx === rootIdx) {
              let rootBool = 0;
              for (const child of menu.children) {
                if (child.idx === idx) {
                  let childBool = 0;
                  for (const subChild of child.children) {
                    if (subChild.idx !== row.idx && subChild.auth === 1) {
                      childBool = 1;
                    }
                  }
                  if (childBool === 0) {
                    child.auth = 0;
                    if (menu.children.some((child) => child.auth === 1))
                      rootBool = 1;
                    menu.auth = rootBool;
                  }
                }
              }
            }
          }
        }
      }
    }
    for (const menuObj of menuArr) {
      if (menuObj.idx === row.idx) {
        menuObj.auth = auth;
      } else {
        if (menuObj.children !== null) {
          for (const subMenuObj of menuObj.children) {
            if (subMenuObj.idx === row.idx) {
              subMenuObj.auth = auth;
            } else {
              if (subMenuObj.children !== null) {
                for (const thirdMenuObj of subMenuObj.children) {
                  if (thirdMenuObj.idx === row.idx) {
                    thirdMenuObj.auth = auth;
                  }
                }
              }
            }
          }
        }
      }
    }
    this.setState({
      selectData: menuArr
    });
    // console.log(`menuchange = ${JSON.stringify(menuArr, null, 4)}`);
  };

  onUpdateAuth = () => {
    const userInfo = reactLocalStorage.getObject("adminUser");

    let arr = [];
    for (const menu of this.state.selectData) {
      if (menu.children === null) {
        arr.push({ menuIdx: menu.idx, auth: menu.auth });
      } else {
        for (const subMenu of menu.children) {
          if (subMenu.children === null) {
            arr.push({ menuIdx: subMenu.idx, auth: subMenu.auth });
          } else {
            for (const thirdMenu of subMenu.children) {
              arr.push({ menuIdx: thirdMenu.idx, auth: thirdMenu.auth });
            }
            arr.push({ menuIdx: subMenu.idx, auth: subMenu.auth });
          }
        }
        arr.push({ menuIdx: menu.idx, auth: menu.auth });
      }
    }
    let updateData = {
      idx: this.props.authIdx,
      authList: arr
    };
    // console.log(JSON.stringify(updateData, null, 4));
    if (userInfo.superAdmin === 1) {
      if (global.confirm(`수정하시겠습니까?`)) {
        httpPut(httpUrl.authUpdate, [], updateData)
          .then((resutl) => {
            this.props.onCancel();
            showAlert("update");
          })
          .catch((error) => {});
      }
    } else {
      alert("권한이 없습니다.");
    }
  };

  onUpdateCoinAuth = () => {
    const { authIdx } = this.props;
    const userInfo = reactLocalStorage.getObject("adminUser");
    let updateData = {
      idx: authIdx,
      authCoinList: this.state.selectData
    };

    if (userInfo.superAdmin === 1) {
      if (global.confirm(`수정하시겠습니까?`)) {
        httpPut(httpUrl.authCoinUpdate, [], updateData)
          .then((resutl) => {
            this.props.onCancel();
            showAlert("update");

            if (userInfo.idx === authIdx) {
              let coinAuth = [];
              updateData.authCoinList.forEach((item) => {
                if (item.auth === 1) coinAuth.push(item.coinType);
              });
              let data = {
                ...userInfo,
                authCoinList: coinAuth
              };
              reactLocalStorage.setObject("adminUser", data);
            }
          })
          .catch((error) => {});
      }
    } else {
      alert("권한이 없습니다.");
    }
  };

  onUpdate = () => {
    if (this.props.type === "auth") {
      this.onUpdateAuth();
    } else {
      this.onUpdateCoinAuth();
    }
  };

  onCancel = () => {
    this.setState({
      selectData: this.state.prevData
    });
    this.props.onCancel();
  };

  componentDidMount() {
    // console.log(`props = ${JSON.stringify(this.props, null, 4)}`);
    let arr = [];
    if (this.props.type === "auth") {
      this.props.modalData.forEach((menu) => {
        let menuObj = { ...menu };
        delete menuObj.subMenu;
        if (menu.subMenu === null || menu.subMenu.length === 0) {
          arr.push({ ...menuObj, children: null });
        } else {
          let subArr = []; //1 depth menu
          menu.subMenu.forEach((sub) => {
            let subObj = { ...sub };
            delete subObj.subMenu;
            if (sub.subMenu === null || sub.subMenu.length === 0) {
              subArr.push({ ...subObj, children: null });
            } else {
              subArr.push({ ...subObj, children: sub.subMenu });
            }
          });
          arr.push({ ...menuObj, children: subArr });
        }
      });
    } else {
      arr = this.props.modalData;
    }

    this.setState({
      selectData: arr
    });
  }

  render() {
    const { visible, type } = this.props;

    const walletColums = [
      type === "auth"
        ? {
            title: <div style={{ textAlign: "center" }}>영역</div>,
            // className: "text-center",
            dataIndex: "nameKr"
          }
        : {
            title: "코인",
            className: "text-center",
            dataIndex: "coinType",
            render: (data, row, index) => <div>{checkCoinName(data)}</div>
          },
      {
        title: "권한",
        dataIndex: "auth",
        className: "text-center",
        render: (data, row, index) => (
          <SelectBox
            value={string.authString[row.auth]}
            data={row}
            codeString={string.authString}
            onChange={(value) => {
              if (type === "auth") {
                this.onChangeUserAuth(value, row);
              } else {
                this.onChangeCoinTypeAuth(value, row);
              }
            }}
          />
        )
      }
    ];

    return (
      <Modal
        visible={visible}
        title={type === "auth" ? "권한설정" : "코인 권한 설정"}
        okText="변경사항저장"
        cancelText="취소"
        onOk={this.onUpdate}
        onCancel={this.onCancel}
        style={{ minWidth: "800px" }}>
        <div className="auth-wrapper">
          <Table
            className="auth-table"
            rowKey={(record) => record.idx}
            columns={walletColums}
            dataSource={this.state.selectData}
            pagination={false}
          />
        </div>
      </Modal>
    );
  }
}

export default AuthDetail;
