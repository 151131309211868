import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Table, Input, Button } from "antd";
import { httpGet, httpPut, httpUrl } from "../../api/httpClient";

import { checkData, numberFormat } from "../../util";
import { showAlert } from "../../components/common/AlertModal";

class Service extends Component {
  state = {
    userInfo: reactLocalStorage.getObject("adminUser"),
    serviceData: [],
    selectedRow: "",
    selectData: {},
    prevData: {}
  };

  // 값 변경
  onChangeModal = (value, type, row) => {
    var data = Object.assign({}, this.state.selectData);
    if (type === "idx") {
      data = row;
    } else data[type] = value;

    // console.log(`data = ${JSON.stringify(data, null, 4)}`);
    this.setState({
      selectData: data
    });
  };

  // 수정
  onUpdate = () => {
    const { selectData } = this.state;

    if (global.confirm(`수정하시겠습니까?`)) {
      httpPut(httpUrl.serviceUpdate, [], selectData)
        .then((result) => {
          showAlert("update");
          this.setState({
            selectedRow: "",
            selectData: {},
            prevData: {}
          });
          this.serviceList();
        })
        .catch((error) => {});
    }
  };

  serviceList() {
    httpGet(httpUrl.serviceList, [], {})
      .then((result) => {
        // console.log(result.data);
        this.setState({ serviceData: result.data });
      })
      .catch((error) => {});
  }

  componentDidMount() {
    this.serviceList();
  }

  render() {
    const { serviceData, selectData, prevData, selectedRow } = this.state;

    const serviceColumns = [
      {
        width: 120,
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        key: "type",
        // width: 80,
        title: "타입",
        dataIndex: "type",
        className: "text-center"
      },
      {
        key: "service",
        title: "서비스",
        dataIndex: "service",
        className: "text-center"
      },
      {
        title: "상태",
        dataIndex: "status",
        className: "text-center",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index ? (
              data
            ) : (
              <Input
                value={selectData.status}
                onChange={(e) => {
                  this.onChangeModal(e.target.value, "status");
                }}
              />
            )}
          </div>
        )
      },
      {
        title: "메모",
        dataIndex: "memo",
        className: "text-center",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index ? (
              checkData(data)
            ) : (
              <Input
                allowClear
                value={selectData.memo}
                onChange={(e) => {
                  this.onChangeModal(e.target.value, "memo");
                }}
              />
            )}
          </div>
        )
      },
      {
        title: "",
        dataIndex: "update",
        className: "text-center",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index && (
              <Button
                onClick={(value) => {
                  this.onChangeModal(row.idx, "idx", index);
                  this.setState({
                    selectedRow: index,
                    selectData: row,
                    prevData: row
                  });
                }}>
                수정하기
              </Button>
            )}
            {selectedRow === index && (
              <div>
                <Button
                  style={{ marginRight: "8px" }}
                  type="primary"
                  shape="circle"
                  icon="check"
                  onClick={this.onUpdate}
                />
                <Button
                  type="danger"
                  shape="circle"
                  icon="close"
                  onClick={() => {
                    this.setState({
                      selectedRow: "",
                      selectData: prevData
                    });
                  }}
                />
              </div>
            )}
          </div>
        )
      }
    ];

    return (
      <div>
        <div className="title">서비스 관리</div>
        <div className="wrapper mt-35 width-1240" />
        {/* {userInfo.superAdmin === 1 && (
          <div style={{ float: "left" }}>
            <Button
              onClick={() => {
                this.setState({ registryModalVisible: true });
                this.coinAuthList(0);
              }}
            >
              생성
            </Button>
          </div>
        )} */}
        <Table
          id="admin-table"
          rowKey={(record) => record.idx}
          columns={serviceColumns}
          dataSource={serviceData}
          pagination={false}
        />
      </div>
    );
  }
}

export default Service;
