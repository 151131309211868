import React, { Component } from "react";

import { Button, Table } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";

import { amountFormat, checkCoinName } from "../../util";
import CoinSelectBox from "../../components/common/CoinSelectBox";
import FoundationModal from "../../components/payment/FoundationModal";

class FoundationWallet extends Component {
  state = {
    foundationList: [],
    foundationCoinList: [],
    foundationCoinType: 0,

    list: [],
    foundationIdx: 0,
    isLoaded: true,

    visible: false,
    type: "charge"
  };

  // 코인으로 재단 idx 가져오기
  foundationIdxHandleChange = (value) => {
    const { foundationList } = this.state;

    // console.log(`data = ${foundationList.find((coin) => coin.coinType === value)}`)
    if (foundationList.find((coin) => coin.coinType === value))
      this.setState({
        isLoaded: true,
        foundationCoinType: value,
        foundationIdx: foundationList.find((coin) => coin.coinType === value)
          .idx
      });
  };

  // 재단 지갑의 코인 조회
  getFoundationWalletList = (params = {}) => {
    httpGet(httpUrl.getFoundationWalletList, [this.state.foundationIdx], {})
      .then((res) => {
        // console.log(JSON.stringify(res.data, null, 4));
        this.setState({
          list: res.data
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    // 재단 조회
    httpGet(httpUrl.getFoundationSelectList, [], {})
      .then((res) => {
        // console.log(`data = ${JSON.stringify(res, null, 4)}`);
        this.setState({
          foundationList: res.data,
          foundationCoinList: res.data.map((item) => {
            return item.coinType;
          })
        });
      })
      .catch((e) => {});
  }

  render() {
    const {
      visible,
      type,
      foundationIdx,
      selectedData,
      foundationCoinList,
      foundationCoinType,
      isLoaded,
      list
    } = this.state;
    let columns = [
      {
        key: "coinType",
        title: "코인 종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "수량",
        dataIndex: "balance",
        className: "text-center",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      },
      {
        dataIndex: "status",
        className: "text-center",
        render: (data, row) => (
          <Button
            onClick={() => {
              this.setState({
                visible: true,
                type: "collection",
                selectedData: row
              });
            }}>
            회수
          </Button>
        )
      }
    ];

    return (
      <div>
        {/* 충전 / 회수 */}
        {visible && (
          <FoundationModal
            visible={visible}
            visibleType={type}
            foundationIdx={foundationIdx}
            selectedData={selectedData}
            onCancel={() => {
              this.setState({
                visible: false
              });
            }}
            refresh={this.getFoundationWalletList}
          />
        )}
        <div className="title">재단 지갑 관리</div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <div className="flex-row flex-center">
            <label htmlFor="">
              재단{" "}
              <CoinSelectBox
                type="coinType"
                placeholder="선택해주세요."
                coinCode={foundationCoinList}
                value={
                  foundationCoinType !== 0 ? foundationCoinType : "선택해주세요"
                }
                style={{ width: "160px" }}
                onChange={(value) => {
                  if (value !== foundationCoinType)
                    this.foundationIdxHandleChange(value);
                }}
              />
            </label>
            <Button
              className="m-l-10"
              onClick={() => {
                if (foundationCoinType === 0) alert("재단을 선택해주세요");
                else {
                  this.setState({
                    isLoaded: false
                  });
                  this.getFoundationWalletList();
                }
              }}>
              검색
            </Button>
          </div>
          <Button
            onClick={() => {
              foundationCoinType === 0
                ? alert("재단을 선택해주세요")
                : isLoaded
                ? alert("검색 후 사용가능합니다.")
                : this.setState({
                    visible: true,
                    type: "charge"
                  });
            }}>
            충전
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.coinType}
          columns={columns}
          dataSource={list}
          pagination={false}
        />
      </div>
    );
  }
}

export default FoundationWallet;
