import React, { Component } from "react";

import { DatePicker, Input, Table } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import { httpGet, httpUrl } from "../../api/httpClient";

import {
  endDateFormat,
  formatDate,
  numberFormat,
  startDateFormat
} from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";

const { RangePicker } = DatePicker;
const Search = Input.Search;
class PointChagreLog extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 0
    },
    searchType: "ALL",
    searchText: "",
    startDate: "",
    endDate: ""
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getPointChargeLogList({
      pageNum: pager.current
    });
  };

  // 검색 시간 설정
  handleChangeDate = (value) => {
    this.setState(
      {
        startDate: value.length !== 0 ? startDateFormat(value[0]) : "",
        endDate: value.length !== 0 ? endDateFormat(value[1]) : ""
      },
      () => {
        if (this.state.searchType === "ALL") this.getPointChargeLogList();
      }
    );
    // console.log(JSON.stringify(value, null, 4));
  };

  // 검색 조건 선택
  searchTypeHandleChange = (value) => {
    this.setState({
      searchType: value
    });
  };

  // 검색
  onSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.getPointChargeLogList();
      }
    );
  };

  // 포인트 충전내역 목록 조회
  getPointChargeLogList = (params = {}) => {
    const { searchType, searchText, startDate, endDate } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getPointChargeLogList,
      [10, pageNum, searchType, searchText, startDate, endDate],
      {}
    )
      .then((res) => {
        // console.log(`data = ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getPointChargeLogList();
  }

  render() {
    const { searchType, list, pagination } = this.state;
    const columns = [
      {
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "유저 아이디",
        dataIndex: "userId",
        className: "text-center"
      },
      {
        title: "몰 아이디",
        dataIndex: "mallId",
        className: "text-center"
      },
      {
        title: "금액",
        dataIndex: "price",
        className: "text-center"
      },
      {
        title: "상태",
        dataIndex: "status",
        className: "text-center",
        render: (data) => <div>{string.requestLogString[data]}</div>
      }
    ];

    return (
      <div>
        <div className="title">포인트충전내역</div>
        <div className="flex-row flex-center flex-end m-b-10">
          <div className="flex-row flex-center m-r-10">
            <label className="m-r-10">검색기간&nbsp;</label>
            <RangePicker
              // placeholder="시작일"
              locale={locale}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
            />
          </div>
          <div className="flex-row flex-center">
            <label className="m-r-10" htmlFor="">
              검색기준
            </label>
            <SelectBox
              value={searchType}
              style={{ width: "160px", marginRight: "10px" }}
              code={string.pointChargeSearchTypeCode}
              codeString={string.pointChargeSearchType}
              onChange={(value) => {
                if (value !== searchType) {
                  this.searchTypeHandleChange(value);
                }
              }}
            />
            <Search
              disabled={searchType === "ALL" ? true : false}
              placeholder="아이디를 입력하세요"
              enterButton="검색"
              allowClear
              onSearch={this.onSearch}
              style={{ width: "300px" }}
            />
          </div>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default PointChagreLog;
