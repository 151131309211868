import React, { Component } from "react";

import { Table, Modal, Button, Input } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";

const Search = Input.Search;

class OrganizationSelectModal extends Component {
  state = {
    list: [],
    pagination: {
      current: 1,
      total: 0
    },
    searchType: "ALL",
    searchText: ""
  };

  onOk = (data) => {
    this.props.onOk(data.idx, data.name);
    this.props.onCancel();
  };

  onSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.getList();
      }
    );
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getList({
      pageNum: pager.current
    });
  };

  getList = (params = {}) => {
    let pageNum = params.pageNum || 1;

    let url = httpUrl.getAdjustmentOrganization;
    if (this.props.type) {
      url = httpUrl.getOrganiztionList;
    }

    httpGet(url, [10, pageNum, false, "FRANCHISE", this.state.searchText], {})
      .then((res) => {
        // console.log(`data = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getList();
  }

  render() {
    const { visible, onCancel } = this.props;
    const columns = [
      {
        title: "이름",
        key: "name",
        dataIndex: "name",
        className: "text-center",
        render: (data) => <div>{data}</div>
      },
      {
        key: "select",
        className: "text-center",
        render: (data, row) => (
          <Button
            onClick={() => {
              this.onOk(row);
            }}>
            선택
          </Button>
        )
      }
    ];
    return (
      <Modal
        visible={visible}
        title="조직 선택"
        footer={null}
        onCancel={onCancel}>
        <div className="m-b-10">
          <Search
            style={{ width: "100%" }}
            placeholder={"검색어를 입력하세요."}
            onSearch={this.onSearch}
            allowClear
            enterButton={"검색"}
          />
        </div>
        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.list}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          columns={columns}
        />
      </Modal>
    );
  }
}

export default OrganizationSelectModal;
