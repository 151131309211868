import { Button, Checkbox, Table } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { httpGet, httpPut, httpUrl } from "../../api/httpClient";
import { showAlert } from "../../components/common/AlertModal";
import SelectBox from "../../components/common/SelectBox";
import PopUpModal from "../../components/popup/PopUpModal";
import string from "../../string";
import {
  endDateFormat,
  formatDate,
  formatDateSecond,
  numberFormat,
  startDateFormat
} from "../../util";

class PopUp extends Component {
  state = {
    list: [],
    pagination: {
      current: 0,
      total: 0
    },
    orderByType: "DESC",
    deleted: false,
    expireDate: false,

    visible: false,
    type: "create"
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getPopUpList({ pageNum: pager.current });
  };

  // 정렬 순서 선택
  handleChangeOrderByType = (value) => {
    this.setState({
      orderByCode: value
    });
  };

  // 삭제 내역 포함 여부 설정
  handleChangeDelete = (e) => {
    this.setState({
      deleted: e.target.checked
    });
  };

  //  만료 내역 포함 여부 설정
  handleChangeExpireDate = (e) => {
    this.setState({
      expireDate: e.target.checked
    });
  };

  // 팝업 삭제 및 복구
  onDelete = (row) => {
    const { deleted, pagination } = this.state;

    let pageNum =
      pagination.current > 1 && !deleted && row.deleted === 0
        ? this.state.list.length === 1
          ? pagination.current - 1
          : pagination.current
        : pagination.current;

    if (
      global.confirm(
        `${row.url}을(를) ${row.deleted ? "복구" : "삭제"}하시겠습니까?`
      )
    ) {
      httpPut(httpUrl.popUpDelete, [row.idx], {})
        .then((result) => {
          if (row.deleted) showAlert("restore");
          else showAlert("delete");
          this.getPopUpList({ pageNum });
        })
        .catch((error) => {});
    }
  };

  getPopUpList = (params = {}) => {
    const { orderByType, deleted, expireDate } = this.state;

    let pageNum = params.pageNum || 1;
    if (params.refresh) pageNum = this.state.pagination.current;

    httpGet(
      httpUrl.getPopUpList,
      [10, pageNum, orderByType, deleted, expireDate],
      {}
    )
      .then((res) => {
        // console.log(`data = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getPopUpList();
  }

  render() {
    const { visible, type, selectedData, orderByType, list, pagination } =
      this.state;
    const columns = [
      {
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        width: 700,
        title: "링크",
        dataIndex: "url",
        className: "text-center",
        render: (data, row) => {
          return (
            <div
              style={{
                width: "700px",
                wordWrap: "break-word",
                cursor: "pointer",
                color: "blue"
              }}
              onClick={() => {
                this.setState({
                  type: "edit",
                  visible: true,
                  selectedData: row
                });
              }}>
              {data}
            </div>
          );
        }
      },
      {
        title: "기간",
        dataIndex: "startDate",
        className: "text-center",
        render: (data, row) => (
          <div>{`${startDateFormat(data)} ~ ${endDateFormat(
            row.expireDate
          )}`}</div>
        )
      },
      {
        width: 300,
        title: "기간상태",
        dataIndex: "status",
        className: "text-center",
        render:
          // data => <div>{data === 0 ? '삭제' : '-'}</div>
          (data, row) => (
            <div>
              {startDateFormat(row.startDate) >
              formatDateSecond(
                moment()
                  .endOf("day" - 1)
                  .toString()
              ) ? (
                <span>예정</span>
              ) : endDateFormat(row.expireDate) >
                formatDateSecond(
                  moment()
                    .endOf("day" - 1)
                    .toString()
                ) ? (
                <span>진행중</span>
              ) : (
                <span>만료</span>
              )}
            </div>
          )
      },
      {
        width: 300,
        title: "상태",
        dataIndex: "deleted",
        className: "text-center",
        render:
          // data => <div>{data === 0 ? '삭제' : '-'}</div>
          (data, row) => (
            <SelectBox
              value={string.deleteString[data]}
              code={string.toggleCode}
              codeString={string.deleteString}
              onChange={(value) => {
                if (parseInt(value) !== row.deleted) {
                  this.onDelete(row);
                }
              }}
            />
          )
      }
    ];

    return (
      <div>
        {visible && (
          <PopUpModal
            visible={visible}
            type={type}
            data={selectedData}
            onCancel={() => {
              this.setState({
                visible: false
              });
            }}
            refresh={this.getPopUpList}
          />
        )}
        <div className="title">팝업 관리</div>
        <div className="wrapper mt-35 width-1240" />
        <div className="flex-row flex-sb">
          <Button
            onClick={() => {
              this.setState({ visible: true, type: "create" });
            }}
            style={{ marginBottom: "10px" }}>
            생성
          </Button>
          <div className="flex-row flex-center">
            <label htmlFor="">
              정렬 순서&nbsp;&nbsp;
              <SelectBox
                value={string.orderByString[orderByType]}
                code={string.orderByCode}
                codeString={string.orderByString}
                onChange={(value) => {
                  if (value !== orderByType)
                    this.handleChangeOrderByType(value);
                }}
                style={{ width: "160px", marginRight: "10px" }}
              />
            </label>
            <label htmlFor="">
              검색조건&nbsp;&nbsp;&nbsp;
              <Checkbox
                style={{ marginRight: "10px" }}
                onChange={this.handleChangeDelete}>
                삭제 포함
              </Checkbox>
              <Checkbox
                style={{ marginRight: "10px" }}
                onChange={this.handleChangeExpireDate}>
                만료 포함
              </Checkbox>
            </label>
            <Button
              onClick={() => {
                this.getPopUpList();
              }}>
              검색
            </Button>
          </div>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default PopUp;
