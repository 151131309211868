import React, { Component } from "react";

import { Form, Modal, Input } from "antd";

const FormItem = Form.Item;
const InputOtp = Form.create()(
  class extends Component {
    onOk = () => {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          let otp = this.props.form.getFieldValue("otp");
          this.props.onOk({
            otp: otp
          });
          this.props.form.resetFields();
        }
      });
    };

    render() {
      const { visible, form, onCancel } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };

      return (
        <Modal
          visible={visible}
          title="OTP 인증"
          okText="확인"
          cancelText="취소"
          onOk={this.onOk}
          onCancel={onCancel}>
          <Form {...formItemLayout}>
            <FormItem label={<span>OTP</span>}>
              {getFieldDecorator("otp", {
                rules: [
                  {
                    required: true,
                    message: "otp를 입력하세요"
                  }
                ]
              })(<Input />)}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

export default InputOtp;
