import React, { Component } from "react";
import { connect } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";

import { Modal, Descriptions, Input } from "antd";
import { login } from "../../actions/loginAction";
import { httpPut, httpUrl } from "../../api/httpClient";

import { formatDate } from "../../util";
import string from "../../string";
import { showAlert } from "../common/AlertModal";
import SelectBox from "../common/SelectBox";

const Ditems = Descriptions.Item;
class AdminDetail extends Component {
  state = {
    updateData: {}
  };

  onChangeModal = (value, type) => {
    var data = Object.assign({}, this.state.updateData);
    data[type] = value;
    this.setState({
      updateData: data
    });
  };

  onUpdate = () => {
    const { updateData } = this.state;
    const userInfo = reactLocalStorage.getObject("adminUser");

    let body = {
      idx: updateData.idx,
      name: updateData.name,
      userId: updateData.userId,
      password: updateData.password,
      ipAddress: updateData.ipAddress,
      superAdmin: updateData.superAdmin,
      mobile: updateData.mobile,
      email: updateData.email
    };
    // console.log(`updateData = ${JSON.stringify(updateData, null, 4)}`);
    if (body.ipAddress === "") alert("IP 주소를 정확히 입력해주세요.");
    else {
      if (global.confirm(`수정하시겠습니까?`)) {
        httpPut(httpUrl.adminUpdate, [], body)
          .then((result) => {
            // console.log(JSON.stringify(result, null, 4));
            showAlert("update");
            this.props.onCancel();
            this.props.onOk();
            if (updateData.idx === userInfo.idx) {
              delete updateData.password;
              let adminData = {
                ...userInfo,
                ...updateData
              };
              this.props.onLogin({
                ...adminData
              });
            }
          })
          .catch((error) => {});
      }
    }
  };

  componentDidMount() {
    this.setState({
      updateData: this.props.detailData
    });
  }

  render() {
    const { visible, onCancel } = this.props;
    const { updateData } = this.state;

    return (
      <Modal
        visible={visible}
        title="관리자 정보"
        okText="변경"
        cancelText="취소"
        onOk={this.onUpdate}
        onCancel={onCancel}
        style={{ minWidth: "1000px" }}>
        <Descriptions
          bordered
          column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}>
          <Ditems label="생성일">{formatDate(updateData.createDate)}</Ditems>
          <Ditems label="관리자 등급">
            <SelectBox
              type="superAdmin"
              style={{ width: "160px" }}
              value={string.adminString[updateData.superAdmin]}
              code={string.toggleCode}
              codeString={string.adminString}
              onChange={this.onChangeModal}
            />
            {/* {string.adminString[updateData.superAdmin]} */}
          </Ditems>
          <Ditems label="이름">
            <Input
              value={updateData.name}
              onChange={(e) => {
                this.onChangeModal(e.target.value, "name");
              }}
            />
          </Ditems>
          <Ditems label="닉네임">{updateData.userId}</Ditems>
          <Ditems label="비밀번호">
            <Input.Password
              value={updateData.password}
              placeholder="변경 시 작성"
              onChange={(e) => {
                this.onChangeModal(e.target.value, "password");
              }}
            />
          </Ditems>
          <Ditems label="IP">
            <Input
              placeholder="123.456.0.0"
              value={updateData.ipAddress}
              onChange={(e) => {
                this.onChangeModal(e.target.value, "ipAddress");
              }}
            />
            <div
              style={{
                fontSize: "14px",
                color: "blue"
              }}>
              <span>*</span>을 입력하시면 모든 곳에서 접속이 가능합니다.
            </div>
          </Ditems>
          <Ditems label="핸드폰번호">
            <Input
              style={{ width: "200px" }}
              value={updateData.mobile}
              maxLength={11}
              onChange={(e) => {
                let value = e.target.value.replace(/[^0-9]+$/g, "");
                this.onChangeModal(value, "mobile");
              }}
            />
          </Ditems>
          <Ditems label="이메일">
            <Input
              value={updateData.email}
              onChange={(e) => {
                this.onChangeModal(e.target.value, "email");
              }}
            />
          </Ditems>
        </Descriptions>
      </Modal>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    isLogin: state.login.isLogin,
    loginInfo: state.login.loginInfo
  };
};

let mapDisPatchToProps = (dispatch) => {
  return {
    onLogin: (userinfo) => dispatch(login(userinfo))
  };
};

export default connect(mapStateToProps, mapDisPatchToProps)(AdminDetail);
