import React, { Component } from "react";

import { Form, Modal, Input } from "antd";
import { httpPost, httpUrl } from "../../api/httpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { logout } from "../../actions/loginAction";
import { connect } from "react-redux";

const FormItem = Form.Item;
const InputPassword = Form.create()(
  class extends Component {
    onOk = () => {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          httpPost(httpUrl.otpUpdate, [], {
            password: this.props.form.getFieldValue("password")
          })
            .then(res => {
              if (res.data.result === true) {
                Modal.info({
                  title: "OTP",
                  content: (
                    <div>
                      <div
                        style={{
                          fontSize: "18px"
                        }}>
                        OTP key : {res.data.key}
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          fontSize: "12px",
                          color: "blue"
                        }}>
                        키값을 복사하여 구글 OTP에 추가해주세요. <br />
                        확인버튼을 누르면 로그아웃이 됩니다.
                      </div>
                    </div>
                  ),
                  onOk() {
                    httpPost(httpUrl.logout, [], {})
                      .then(() => {
                        this.props.onLogout();
                        global.location.href = "/";
                      })
                      .catch(e => {
                        global.location.href = "/";
                      });
                    reactLocalStorage.remove("coinList");
                  },
                  okText: "로그아웃"
                });
              } else {
                Modal.error({
                  title: "오류",
                  content: "비밀번호를 잘못 입력하셨습니다. 다시 입력해주세요"
                });
              }
            })
            .catch(error => {});
          this.props.form.resetFields();
        }
      });
    };

    render() {
      const { visible, form, onCancel } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };

      return (
        <Modal
          visible={visible}
          title="비밀번호 인증"
          okText="확인"
          cancelText="취소"
          onOk={this.onOk}
          onCancel={onCancel}>
          <Form {...formItemLayout}>
            <FormItem label={<span>비밀번호</span>}>
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "비밀번호를 입력하세요"
                  }
                ]
              })(<Input.Password />)}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

let mapStateToProps = state => {};

let mapDispatchToProps = dispatch => {
  return {
    onLogout: () => dispatch(logout())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InputPassword);
