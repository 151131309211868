import React, { ImgHTMLAttributes } from "react";

const BasicImage = (props: ImgHTMLAttributes) => {
  const defaultImage = require("../../img/no-image.png");
  /**
   * ex)
   * - encoding
   * image={this.state.images}
   * src={this.state.images}
   *
   * - fileId
   * image={this.state.images}
   * src={serverUrl+httpUrl.getImage+this.state.images}
   */
  return props.image === undefined ||
    props.image === null ||
    props.image.length === 0 ? (
    <img {...props} src={defaultImage} style={{ ...props.style }} alt="" />
  ) : (
    <img {...props} alt="" src={props.src} style={{ ...props.style }} />
  );
};

export default BasicImage;
