import React, { Component } from "react";
import renderHTML from "react-render-html";

import { Modal } from "antd";

export default class PreviewModal extends Component {
  componentDidMount() {
    // console.log(`props = ${JSON.stringify(this.props, null, 4)}`);
  }

  render() {
    const { visible, onCancel, data } = this.props;
    return (
      <Modal
        visible={visible}
        title="미리보기"
        onCancel={onCancel}
        footer={false}>
        {renderHTML(data)}
      </Modal>
    );
  }
}
