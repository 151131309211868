import React, { Component } from "react";

import { Modal, Table, Button } from "antd";
import { httpUrl, httpGet } from "../../api/httpClient";
import { formatDate, amountFormat, checkCoinName } from "../../util";

export default class WalletModal extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 0
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getWalletList({ pageNum: pager.current });
  };

  onUpdateBalance = (data) => {
    httpGet(httpUrl.updateUserBalance, [data.address, data.coinType], {})
      .then((res) => {
        alert("갱신이 완료 되었습니다.");
        this.getWalletList({ pageNum: this.state.pagination.current });
      })
      .catch((e) => {});
  };

  getWalletList = (params = {}) => {
    const { selectedSerial } = this.props;
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.walletList, [10, pageNum, selectedSerial.serial], {})
      .then((res) => {
        // console.log(`data = ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.walletList,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getWalletList();
  }

  render() {
    const { page, visible, onCancel } = this.props;
    const { list, pagination } = this.state;

    const walletColums = [
      {
        title: "생성일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        key: "coinType",
        title: "코인 종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "지갑",
        dataIndex: "address",
        className: "text-center"
      },
      {
        title: "잔액",
        dataIndex: "balance",
        className: "text-center",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      },
      !page
        ? {
            title: "",
            className: "text-center",
            render: (data, row) => (
              <div>
                <Button
                  onClick={() => {
                    this.onUpdateBalance(row);
                  }}>
                  잔액 갱신
                </Button>
              </div>
            )
          }
        : {
            colSpan: 0,
            width: 0,
            render: () => {
              return {
                props: {
                  colSpan: 0
                }
              };
            }
          }
    ];

    return (
      <Modal
        visible={visible}
        title="지갑주소"
        onCancel={onCancel}
        style={{ minWidth: "1400px" }}
        footer={null}>
        <div className="wallet-log-wrapper">
          <Table
            id="wallet-log-table"
            rowKey={(record) => record.coinType}
            columns={walletColums}
            dataSource={list}
            pagination={pagination}
            onChange={this.handleTableChange}
            style={{ marginBottom: "10px" }}
          />
        </div>
      </Modal>
    );
  }
}
