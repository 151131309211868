import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Layout } from "antd";
import {
  Member,
  User,
  FeeWallet,
  Collection,
  Fee,
  Caution,
  Foundation,
  FoundationLinkus,
  MergeList,
  MergeLogList,
  Setting,
  Process,
  LimitAmount,
  WithDrawRequestLog,
  Distribute,
  Manual,
  Auto,
  FoundationList,
  FoundationLock,
  FoundationCoin,
  FoundationCoinLinkus,
  FoundationLog,
  DesignationList,
  DesignationStatus,
  FoundationDaily,
  FoundationDetail,
  Notice,
  Faq,
  Link,
  UserStatistics,
  LoginStatistics,
  TransferStatistics,
  FeeDailyStatistics,
  FeeMonthStatistics,
  AdminLoginLog,
  Admin,
  Service,
  BlockChain,
  CustomConfig,
  Franchise,
  PurchaseLog,
  PaybackLog,
  PaybackSetting,
  TradeLog,
  Adjustment,
  TransferLog,
  AdjustmentLog,
  AdjustmentShare,
  Organization,
  AdjustmentSetting,
  PaymentLog,
  Category,
  MyInfo,
  CreateQR,
  BannerList,
  TradeList,
  TradeRatio,
  DepositWallet,
  DepositThirdparty,
  WithdrawCurrency,
  PointChagreLog,
  CoinTransferLog,
  P2PCaution,
  FoundationBalance,
  FoundationChargeLog,
  ExternalTransferLog,
  FoundationWallet,
  FoundationWalletChargeLog,
  CoinBalance,
  EventList,
  UrlLinkList,
  PopUp,
  ExchangeRequestList,
  DecentralLockList,
  DecentralFoundationList
} from "../pages";
import ExchangeList from "../pages/exchangeManagement/ExchangeList";

export default class Content extends Component {
  render() {
    return (
      <Layout.Content style={{ margin: "24px 16px 0" }}>
        <div style={{ padding: 24, background: "#fff" }}>
          <Switch>
            {/* 회원 */}
            <Route exact path="/user/member" component={Member} />
            <Route exact path="/user/user" component={User} />
            {/* 지갑 */}
            <Route path="/wallet/feeWallet" component={FeeWallet} />
            <Route path="/wallet/collection" component={Collection} />
            <Route path="/wallet/fee" component={Fee} />
            <Route path="/wallet/caution" component={Caution} />
            <Route path="/wallet/foundation" component={Foundation} />
            <Route
              path="/wallet/foundationLinkus"
              component={FoundationLinkus}
            />
            <Route exact path="/wallet/walletMerge" component={MergeList} />
            <Route
              exact
              path="/wallet/walletMerge/:logname"
              component={MergeLogList}
            />
            <Route path="/wallet/p2pCaution" component={P2PCaution} />
            <Route path="/wallet/coinBalance" component={CoinBalance} />
            {/* 거래 */}
            <Route path="/transaction/setting" component={Setting} />
            <Route path="/transaction/process" component={Process} />
            <Route path="/transaction/limitAmount" component={LimitAmount} />
            <Route
              path="/transaction/withDrawRequestLog"
              component={WithDrawRequestLog}
            />
            <Route path="/transaction/transferLog" component={TransferLog} />
            {/* 코인 */}
            <Route path="/coin/distribute" component={Distribute} />
            <Route path="/coin/manual" component={Manual} />
            <Route path="/coin/auto" component={Auto} />
            {/* 재단 */}
            <Route
              path="/foundation/foundationList"
              component={FoundationList}
            />
            <Route
              path="/foundation/foundationLock"
              component={FoundationLock}
            />
            {/* 수수료 있는거 */}
            <Route
              path="/foundation/foundationCoin"
              component={FoundationCoin}
            />
            {/* 수수료 없는거 */}
            <Route
              path="/foundation/foundationCoinLinkus"
              component={FoundationCoinLinkus}
            />
            <Route path="/foundation/foundationLog" component={FoundationLog} />
            <Route
              path="/foundation/designationLockList"
              component={DesignationList}
            />
            <Route
              path="/foundation/designationLockStatus"
              component={DesignationStatus}
            />
            <Route
              path="/foundation/foundationDaily"
              component={FoundationDaily}
            />
            <Route
              path="/foundation/foundationDetail"
              component={FoundationDetail}
            />
            {/* 게시글 */}
            <Route path="/community/notice" component={Notice} />
            <Route path="/community/faq" component={Faq} />
            <Route path="/community/link" component={Link} />
            {/* 통계 */}
            <Route
              path="/statistics/userStatistics"
              component={UserStatistics}
            />
            <Route
              path="/statistics/loginStatistics"
              component={LoginStatistics}
            />
            <Route
              path="/statistics/transferStatistics"
              component={TransferStatistics}
            />
            <Route path="/statistics/feeDate" component={FeeDailyStatistics} />
            <Route path="/statistics/feeMonth" component={FeeMonthStatistics} />
            <Route path="/statistics/adminLoginLog" component={AdminLoginLog} />
            {/* 시스템 */}
            <Route path="/system/admin" component={Admin} />
            <Route path="/system/service" component={Service} />
            <Route path="/system/blockChain" component={BlockChain} />
            <Route path="/system/customConfig" component={CustomConfig} />
            {/* 가맹점관리 */}
            <Route path="/franchise/list" component={Franchise} />
            <Route path="/franchise/paymentLog" component={PurchaseLog} />
            <Route path="/franchise/paybackLog" component={PaybackLog} />
            <Route
              path="/franchise/paybackSetting"
              component={PaybackSetting}
            />
            <Route path="/trade" component={TradeLog} />
            <Route path="/franchise/adjustment" component={Adjustment} />
            {/* 정산관리 */}
            <Route path="/adjustment/adjustmentLog" component={AdjustmentLog} />
            <Route
              path="/adjustment/adjustmentShare"
              component={AdjustmentShare}
            />
            {/* 결제관리 */}
            <Route path="/payment/organization" component={Organization} />
            <Route path="/payment/createQR" component={CreateQR} />
            <Route
              path="/payment/adjustmentSetting"
              component={AdjustmentSetting}
            />
            <Route path="/payment/paymentLog" component={PaymentLog} />
            <Route path="/payment/category" component={Category} />{" "}
            <Route
              path="/payment/foundation/wallet"
              component={FoundationWallet}
            />
            <Route
              path="/payment/foundation/chargeLog"
              component={FoundationWalletChargeLog}
            />
            {/* 배너관리 */}
            <Route path="/banner" component={BannerList} />
            {/* 이벤트관리 */}
            <Route path="/event" component={EventList} />
            {/* 사용처 */}
            <Route path="/urlLink" component={UrlLinkList} />
            {/* 거래서 */}
            <Route path="/exchange" component={ExchangeList} />
            {/* 교환관리 */}
            <Route path="/tradeRatio" component={TradeRatio} />
            <Route path="/tradeList" component={TradeList} />
            {/* 마케팅전산관리 */}
            <Route path="/marketing/depositWallet" component={DepositWallet} />
            <Route
              path="/marketing/depositThirdparty"
              component={DepositThirdparty}
            />
            <Route
              path="/marketing/withdrawCurrency"
              component={WithdrawCurrency}
            />
            <Route
              path="/marketing/pointChargeLog"
              component={PointChagreLog}
            />
            <Route path="/marketing/transferLog" component={CoinTransferLog} />
            {/* 외부연동지갑관리 */}
            <Route
              path="/external/foundation/balance"
              component={FoundationBalance}
            />
            <Route
              path="/external/foundation/chargeLog"
              component={FoundationChargeLog}
            />
            <Route
              path="/external/foundation/transferLog"
              component={ExternalTransferLog}
            />
            {/* 교환 요청 */}
            <Route path="/exchangeRequest" component={ExchangeRequestList} />
            {/* 코인관리 */}
            <Route path="/decentral/lock" component={DecentralLockList} />
            <Route
              path="/decentral/foundationList"
              component={DecentralFoundationList}
            />
            {/* 팝업관리 */}
            <Route path="/popUp" component={PopUp} />
            {/* 정보수정 */}
            <Route path="/mypage/myinfo" component={MyInfo} />
          </Switch>
        </div>
      </Layout.Content>
    );
  }
}
