import React, { Component } from "react";

import { Table, DatePicker, Button } from "antd";
import { numberFormat, dayFormat, statFormat } from "../../util";
import locale from "antd/es/date-picker/locale/ko_KR";
import { httpGet, httpUrl } from "../../api/httpClient";

const { RangePicker } = DatePicker;
class LoginStatistics extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 0
    },
    startDate: "",
    endDate: ""
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getLoginStatisticsList({ pageNum: pager.current });
  };

  // 검색 기간 설정
  handleChangeDate = (value) => {
    this.setState({
      startDate: value.length !== 0 ? statFormat(value[0]) : "",
      endDate: value.length !== 0 ? statFormat(value[1]) : ""
    });
  };

  // 로그인 통계 목록 조회
  getLoginStatisticsList = (params = {}) => {
    const { startDate, endDate } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.loginStatisticsList, [10, pageNum, startDate, endDate], {})
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.loginList,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getLoginStatisticsList();
  }

  render() {
    const statisticsColumns = [
      {
        title: "날짜",
        dataIndex: "statDate",
        // width: 80,
        className: "text-center",
        render: (data) => <div>{dayFormat(data)}</div>
      },
      {
        title: "로그인 수",
        dataIndex: "loginCount",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "로그인 한 총 회원 수",
        dataIndex: "loginUser",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      }
    ];

    return (
      <div>
        {/* {JSON.stringify(this.props.result, null, 4)} */}
        <div className="title">로그인 통계</div>
        <div className="wrapper mt-35 width-1240" />
        <div className="flex-row flex-center m-b-10" style={{ float: "right" }}>
          <label htmlFor="">검색기간&nbsp;</label>
          <RangePicker
            locale={locale}
            onChange={(date) => {
              this.handleChangeDate(date);
            }}
          />
          <Button
            type="primary"
            onClick={() => {
              this.getLoginStatisticsList();
            }}>
            검색
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="login-table"
          // rowKey={record => record.statDate}
          columns={statisticsColumns}
          dataSource={this.state.list}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default LoginStatistics;
