import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Button, Icon, Select, Table } from "antd";
import { httpDownload, httpGet, httpUrl } from "../../api/httpClient";

import {
  numberFormat,
  formatDate,
  amountFormat,
  checkData,
  checkCoinName
} from "../../util";
import CoinSelectBox from "../../components/common/CoinSelectBox";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import fileDownload from "js-file-download";

const Option = Select.Option;
class FoundationWalletChargeLog extends Component {
  state = {
    foundationList: [],
    foundationCoinList: [],
    foundationCoinType: 0,
    coinList: [],

    list: [],
    pagination: {
      current: 0,
      total: 0
    },
    foundationIdx: 0,
    coinType: 0,
    orderByType: "ASC",
    startDate: "",
    endDate: ""
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getFoundationWalletChargeList({ pageNum: pager.current });
  };

  // 코인으로 재단 idx 가져오기
  foundationIdxHandleChange = (value) => {
    const { foundationList } = this.state;

    if (foundationList.find((coin) => coin.coinType === value))
      this.setState(
        {
          isLoaded: true,
          foundationCoinType: value,
          foundationIdx: foundationList.find((coin) => coin.coinType === value)
            .idx
        },
        () => {
          this.getFoundationWalletList();
        }
      );
  };

  // 코인 종류 선택
  coinTypeHandleChange = (value) => {
    this.setState({
      coinType: value,
      isLoaded: true
    });
  };

  // 정렬 순서 선택
  orderByTypeHandleChange = (value) => {
    this.setState({
      orderByType: value,
      isLoaded: true
    });
  };

  // 엑셀 다운로드
  downloadExcel = () => {
    const {
      isLoaded,
      pagination,
      foundationCoinType,
      foundationIdx,
      coinType,
      orderByType,
      startDate,
      endDate
    } = this.state;

    if (isLoaded) {
      alert("검색 후 가능합니다.");
    } else {
      let total = pagination.total <= 9 ? 10 : pagination.total;
      let title = `${checkCoinName(foundationCoinType)} ${checkCoinName(
        coinType
      )} 재단 지갑 충전 내역`;

      if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
        httpDownload(
          httpUrl.getFoundationWalletChargeExcel,
          [total, 1, foundationIdx, coinType, orderByType, startDate, endDate],
          {}
        )
          .then((res) => {
            fileDownload(res, `${title}.xlsx`);
          })
          .catch((e) => {
            alert(`다운로드 실패`);
          });
      }
    }
  };

  // 재단 지갑 충전 내역 목록 조회
  getFoundationWalletChargeList = (params = {}) => {
    const { foundationIdx, coinType, orderByType, startDate, endDate } =
      this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getFoundationWalletChargeList,
      [10, pageNum, foundationIdx, coinType, orderByType, startDate, endDate],
      {}
    )
      .then((res) => {
        // console.log(JSON.stringify(res.data, null, 4));
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination
        });
      })
      .catch((e) => {});
  };

  // 재단 지갑의 코인 조회
  getFoundationWalletList = (params = {}) => {
    httpGet(httpUrl.getFoundationWalletList, [this.state.foundationIdx], {})
      .then((res) => {
        let coinList = reactLocalStorage.getObject("coinList");
        let arr = [];

        // 코인 종류로 코인 이름 추가
        for (let i = 0; i < coinList.length; i++) {
          for (let j = 0; j < res.data.length; j++) {
            if (res.data[j].coinType === coinList[i].coinType)
              arr.push({
                coinType: res.data[j].coinType,
                name: coinList[i].name
              });
          }
        }

        this.setState({
          coinList: arr
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    // 재단 조회
    httpGet(httpUrl.getFoundationSelectList, [], {})
      .then((res) => {
        // console.log(`data = ${JSON.stringify(res, null, 4)}`);
        this.setState({
          foundationList: res.data,
          foundationCoinList: res.data.map((item) => {
            return item.coinType;
          })
        });
      })
      .catch((e) => {});
  }

  render() {
    const {
      foundationCoinList,
      foundationCoinType,
      coinList,
      coinType,
      orderByType,
      foundationIdx,
      list,
      pagination
    } = this.state;
    let columns = [
      {
        width: 200,
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        width: 200,
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      // {
      //   key: "coinType",
      //   title: "코인 종류",
      //   dataIndex: "coinType",
      //   className: "text-center",
      //   render: (data) => <div>{checkCoinName(data)}</div>
      // },
      {
        title: "구분",
        dataIndex: "type",
        className: "text-center",
        render: (data, row) => <div>{string.externalChargeLogString[data]}</div>
      },
      {
        title: "수량",
        dataIndex: "amount",
        className: "text-center",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      },
      {
        title: "메모",
        dataIndex: "memo",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      }
    ];

    return (
      <div>
        <div className="title">재단 지갑 충전 내역</div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <div className="flex-row flex-center ">
            <label htmlFor="">
              재단{" "}
              <CoinSelectBox
                type="coinType"
                placeholder="선택해주세요."
                value={
                  foundationCoinType !== 0 ? foundationCoinType : "선택해주세요"
                }
                coinCode={foundationCoinList}
                style={{ width: "160px", marginRight: 10, marginLeft: 10 }}
                onChange={(value) => {
                  if (value !== foundationCoinType)
                    this.foundationIdxHandleChange(value);
                }}
              />
            </label>
            {/* {coinList.length !== 0 && (  )} */}
            <label htmlFor="">
              코인
              <Select
                style={{ width: "160px", marginRight: "10px", marginLeft: 10 }}
                // value={coinList.length === 0 ? "선택해주세요" : coinType}
                value={coinType !== 0 ? coinType : "선택해주세요"}
                onChange={this.coinTypeHandleChange}>
                {coinList.map((coin) => (
                  <Option key={coin.coinType} value={coin.coinType}>
                    {coin.name}
                  </Option>
                ))}
              </Select>
            </label>
            <label htmlFor="">
              순서
              <SelectBox
                value={string.orderByString[orderByType]}
                codeString={string.orderByString}
                onChange={(value) => {
                  if (value !== orderByType)
                    this.orderByTypeHandleChange(value);
                }}
                style={{ width: "160px", marginLeft: 10 }}
              />
            </label>
            <Button
              className="m-l-10"
              onClick={() => {
                if (foundationIdx === 0) alert("재단을 선택해주세요");
                if (
                  foundationIdx !== 0 &&
                  coinList.length !== 0 &&
                  coinType === 0
                )
                  alert("코인 종류를 선택해주세요");
                else {
                  this.setState({
                    isLoaded: false
                  });
                  this.getFoundationWalletChargeList();
                }
              }}>
              검색
            </Button>
          </div>
          <Button
            className="m-b-10"
            onClick={this.downloadExcel}
            disabled={list.length === 0 ? true : false}>
            <Icon type="download" style={{ fontSize: "20px" }} />
            엑셀 다운로드
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default FoundationWalletChargeLog;
