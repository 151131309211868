import React, { Component } from "react";
import fileDownload from "js-file-download";

import { Table, Input, Button, Icon } from "antd";
import { httpGet, httpUrl, httpDownload } from "../../api/httpClient";

import CoinSelectBox from "./../../components/common/CoinSelectBox";
import SelectBox from "../../components/common/SelectBox";
import LockDetail from "../../components/foundation/LockDetail";

import { numberFormat, amountFormat, checkCoinName } from "../../util";
import string from "../../string";
import { showAlert } from "../../components/common/AlertModal";

const Search = Input.Search;
class FoundationList extends Component {
  state = {
    foundationList: [],
    fCoinList: [],

    listData: [],
    pagination: {
      total: 0,
      current: 1
    },
    orderType: "BALANCE",
    orderByType: "ASC",
    searchText: "",
    totalBalance: 0,
    totalLock: 0,
    totalAvailable: 0,

    userLockVisible: false,
    selectedUser: {},

    isLoaded: false
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getFoundationUserList({
      pageNum: pager.current
    });
  };

  // 코인 종류 선택
  coinTypeHandleChange = (value) => {
    this.setState({
      coinType: value,
      isLoaded: true
    });
  };

  // 정렬 기준 선택
  orderTypeHandleChange = (value) => {
    const { coinType } = this.state;
    if (coinType === 0 || coinType === undefined) {
      showAlert("coin");
    } else {
      this.setState({
        orderType: value,
        isLoaded: true
      });
    }
  };

  // 정렬 순서 선택
  orderByTypeHandleChange = (value) => {
    const { coinType } = this.state;
    if (coinType === 0 || coinType === undefined) {
      showAlert("coin");
    } else {
      this.setState({
        orderByType: value,
        isLoaded: true
      });
    }
  };

  // 검색
  onSearch = (value) => {
    const { coinType } = this.state;
    if (coinType === 0 || coinType === undefined) {
      showAlert("coin");
    } else {
      this.setState(
        {
          searchText: value,
          isLoaded: false
        },
        () => {
          this.getFoundationCount();
          this.getFoundationUserList({
            pageNum: 1
          });
        }
      );
    }
  };

  // 엑셀 다운로드
  downloadExcel = () => {
    const {
      coinType,
      pagination,
      orderType,
      orderByType,
      searchText,
      isLoaded
    } = this.state;

    if (coinType === 0 || coinType === undefined) {
      showAlert("coin");
    } else {
      if (isLoaded) {
        alert("검색버튼을 먼저 클릭해주세요.");
      } else {
        let total = pagination.total <= 9 ? 10 : pagination.total;

        if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
          httpDownload(
            httpUrl.foundationListExcel,
            [total, 1, coinType, orderType, orderByType, searchText],
            {}
          )
            .then((res) => {
              // console.log(JSON.stringify(res, null, 4));
              fileDownload(
                res,
                `${checkCoinName(coinType)} 보유현황 조회.xlsx`
              );
            })
            .catch((error) => {
              alert(`다운로드 실패`);
            });
        }
      }
    }
  };

  refresh = () => {
    this.getFoundationCount();
    this.getFoundationUserList({ pageNum: this.state.pagination.current });
  };

  // 보유현황 목록 조회
  getFoundationUserList = (params = {}) => {
    const { coinType, orderType, orderByType, searchText } = this.state;
    let pageNum = params.pageNum || 1;
    httpGet(
      httpUrl.foundationUserList,
      [10, pageNum, coinType, orderType, orderByType, searchText],
      {}
    )
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          listData:
            res.data.foundationUserList === null
              ? []
              : res.data.foundationUserList,
          pagination
        });
      })
      .catch((error) => {});
  };

  // 코인 총 금액 조회
  getFoundationCount = (params = {}) => {
    const { coinType } = this.state;

    httpGet(httpUrl.getFoundationCount, [coinType], {})
      .then((res) => {
        this.setState({
          totalBalance: res.data.totalBalance,
          totalLock: res.data.totalLock,
          totalAvailable: res.data.totalAvailable
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    // 재단 코인 조회
    httpGet(httpUrl.getFoundationList, [], {})
      .then((res) => {
        // console.log(`foundationCoinList: ${JSON.stringify(res.data, null, 4)}`);
        this.setState(
          {
            foundationList: res.data,
            fCoinList: res.data.map((item) => {
              // console.log(`item: ${JSON.stringify(item, null, 4)}`);
              return item.coinType;
            }),
            coinType: res.data[0].coinType
          },
          () => this.refresh()
        );
      })
      .catch((e) => {});
  }

  render() {
    const {
      orderType,
      orderByType,
      listData,
      coinType,
      fCoinList,
      totalBalance,
      totalLock,
      totalAvailable,
      userLockVisible,
      selectedUser,
      pagination
    } = this.state;

    const listColumns = [
      {
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "아이디",
        dataIndex: "id",
        className: "text-center"
      },
      {
        title: "지갑주소",
        dataIndex: "address",
        className: "text-center"
      },
      {
        title: "보유수량",
        dataIndex: "balance",
        className: "text-center",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      },
      {
        title: "Lock 수량",
        dataIndex: "lockBalance",
        className: "text-center",
        render: (data, row) => (
          <div>
            {data === -1 ? "전체잠금" : amountFormat(data, row.coinType)}
          </div>
        )
      },
      {
        title: "출금 가능 수량",
        dataIndex: "availableBalance",
        className: "text-center",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      },
      // {
      //   title: "상태",
      //   dataIndex: "deleted",
      //   className: "text-center",
      //   render: data => <div>{string.foundationString[data]}</div>
      // },
      {
        title: "Lock 현황",
        dataIndex: "status",
        className: "text-center",
        render: (data, row, index) => (
          <Button
            onClick={() => {
              this.setState({
                selectedUser: row,
                userLockVisible: true
              });
            }}>
            현황
          </Button>
        )
      }
    ];

    return (
      <div>
        {/* {JSON.stringify(this.props, null, 4)} */}
        <div className="title">보유현황 조회</div>
        <div className="wrapper mt-35 width-1240" />
        {userLockVisible && (
          <LockDetail
            pageType="foundationStatus"
            title="LOCK 상세 현황"
            visible={userLockVisible}
            selected={selectedUser}
            coinList={{
              coinType: selectedUser.coinType,
              address: selectedUser.address
            }}
            onCancel={() => {
              this.setState({
                userLockVisible: false
              });
            }}
            refresh={this.refresh}
          />
        )}
        <div style={{ marginBottom: "10px" }}>
          <label htmlFor="" style={{ marginRight: "20px" }}>
            코인{" "}
            <CoinSelectBox
              type="coinType"
              placeholder="선택해주세요."
              value={coinType}
              coinCode={fCoinList}
              onChange={(value) => {
                if (value !== coinType) this.coinTypeHandleChange(value);
              }}
              style={{ width: "160px" }}
            />
          </label>
          <label htmlFor="">
            순서{" "}
            <SelectBox
              value={string.orderByString[orderByType]}
              code={string.orderByCode}
              codeString={string.orderByString}
              onChange={(value) => {
                if (value !== orderByType) this.orderByTypeHandleChange(value);
              }}
              style={{ width: "160px", marginRight: "10px" }}
            />
            <SelectBox
              value={string.foundationOrderString[orderType]}
              code={string.foundationOrderCode}
              codeString={string.foundationOrderString}
              onChange={(value) => {
                if (value !== orderType) this.orderTypeHandleChange(value);
              }}
              style={{ width: "160px" }}
            />
          </label>
          <div style={{ float: "right" }}>
            <div className="flex-row flex-center">
              <label htmlFor="search">검색 기준&nbsp;&nbsp;</label>
              <Search
                placeholder="아이디, 지갑주소를 입력하세요"
                enterButton="검색"
                allowClear
                onSearch={this.onSearch}
                style={{ width: "300px", marginLeft: "10px" }}
              />
            </div>
          </div>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <span>{`total : ${numberFormat(pagination.total)}`}</span>
          <span style={{ marginLeft: "20px" }}>
            {`총 보유수량 : ${numberFormat(totalBalance, coinType)}`}
          </span>
          <span style={{ marginLeft: "20px" }}>
            {`총 LOCK수량 : ${numberFormat(totalLock, coinType)}`}
          </span>
          <span style={{ marginLeft: "20px" }}>
            {`총 출금가능수량 : ${numberFormat(totalAvailable, coinType)}`}
          </span>
          <Button
            style={{ float: "right", marginBottom: "10px" }}
            onClick={this.downloadExcel}>
            <Icon type="download" style={{ fontSize: "20px" }} />
            엑셀 다운로드
          </Button>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <div style={{ color: "blue" }} className="m-b-1">
            * 사용자가 잔액 갱신을 하지 않을 경우 총 수량이 일치 하지 않을 수
            있습니다.
          </div>
          <div style={{ color: "blue" }}>
            * 전체잠금이 설정된 기간동안은 개별 락의 상태와 관계없이 해당 지갑의
            해당 코인에 대해 모든 출금이 금지됩니다.
          </div>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="lock-table"
          rowKey={(record) => record.idx}
          columns={listColumns}
          dataSource={listData}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default FoundationList;
