import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Table, Input, Select, Button } from "antd";
import moment from "moment";
import { httpGet, httpPost, httpUrl } from "../../api/httpClient";
import {
  numberFormat,
  coinDivideFormat,
  releaseTimeDate,
  formatDateSecond,
  formatDate,
  checkCoinName,
  checkData
} from "../../util";
import string from "../../string";

import SelectBox from "../../components/common/SelectBox";
import LockModal from "../../components/coin/LockModal";
import { showAlert } from "../../components/common/AlertModal";

const Search = Input.Search;
const Option = Select.Option;
class DecentralLockList extends Component {
  state = {
    lockList: [],
    coinList: [],
    coinType: 0,
    lockSearchType: "ID",
    lockSearchText: "",

    visible: false,

    list: [],
    pagination: {
      current: 0,
      total: 0
    },
    lockType: "ALL",
    statusType: "ALL",
    searchType: "ADDRESS",
    searchText: ""
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getLockRequestList({
      pageNum: pager.current
    });
  };

  // 코인 종류 선택
  handleChangeCoinType = (value) => {
    this.setState({
      coinType: value
    });
  };

  // 검색
  onLockSearch = (value) => {
    this.setState(
      {
        lockSearchText: value
      },
      () => this.getLockList()
    );
  };

  // 검색
  onRequestSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => this.getLockRequestList()
    );
  };

  // LOCK 해지
  unLock = (row) => {
    const { coinType } = this.state;
    let body = { ...row, coinType };
    // console.log(`body =${JSON.stringify(body, null, 4)}`);

    if (global.confirm("해제하시겠습니까?"))
      httpPost(httpUrl.unLock, [], body)
        .then((res) => {
          // console.log(JSON.stringify(res, null, 4));
          if (res.data) {
            showAlert("unlock");
            this.getLockList();
            this.getLockRequestList();
          }
        })
        .catch((e) => {});
  };

  // LOCK 목록
  getLockList = () => {
    const { coinType, lockSearchType, lockSearchText } = this.state;

    httpGet(httpUrl.getLockList, [coinType, lockSearchType, lockSearchText], {})
      .then((res) => {
        // console.log(`-- LOCK LIST --`);
        // console.log(`res =${JSON.stringify(res.data, null, 4)}`);
        this.setState({
          lockList: res.data.list.sort((x, y) => y.releaseTime - x.releaseTime)
        });
      })
      .catch((e) => {});
  };

  // LOCK REQUEST 목록
  getLockRequestList = (params = {}) => {
    const { lockType, statusType, searchType, searchText } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getLockRequestList,
      [10, pageNum, lockType, statusType, searchType, searchText],
      {}
    )
      .then((res) => {
        // console.log(`-- LOCK REQUEST LIST --`);
        // console.log(`res =${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({ list: res.data.list, pagination });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    let coinList = reactLocalStorage.getObject("coinList");
    let arr = [];
    coinList.forEach((coin) => {
      if (coin.category === "KCT") arr.push({ ...coin });
    });

    this.setState({ coinList: arr, coinType: arr[0].coinType });
    this.getLockRequestList();
  }

  render() {
    const {
      visible,
      coinList,
      lockList,
      coinType,
      lockSearchType,
      lockSearchText,
      lockType,
      statusType,
      searchType,
      list,
      pagination
    } = this.state;

    const lockColumns = [
      {
        key: "lockIdx",
        title: "Lock번호",
        dataIndex: "lockIdx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "해제일",
        dataIndex: "releaseTime",
        className: "text-center",
        render: (data) => <div>{releaseTimeDate(data)}</div>
      },
      {
        title: "주소",
        dataIndex: "address",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      },
      {
        title: "수량",
        dataIndex: "amount",
        className: "text-center",
        render: (data) => <div>{coinDivideFormat(data, coinType)}</div>
      },
      {
        title: "상태",
        dataIndex: "",
        className: "text-center",
        render: (data, row) =>
          releaseTimeDate(row.releaseTime) >
          formatDateSecond(
            moment()
              .endOf("day" - 1)
              .toString()
          ) ? (
            <Button
              onClick={() => {
                this.unLock(row);
              }}>
              해지
            </Button>
          ) : (
            "-"
          )
      }
    ];
    const columns = [
      {
        key: "idx",
        title: "번호",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "코인",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "TYPE",
        dataIndex: "lockType",
        className: "text-center",
        render: (data) => <div>{string.lockRequesetString[data]}</div>
      },
      {
        title: "아이디",
        dataIndex: "id",
        className: "text-center",
        render: (data) => <div>{data ? checkData(data) : "외부"}</div>
      },
      {
        title: "주소",
        dataIndex: "address",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      },
      {
        title: "수량",
        dataIndex: "amount",
        className: "text-center",
        render: (data) => <div>{coinDivideFormat(data, coinType)}</div>
      },
      {
        width: 120,
        title: "해제일",
        dataIndex: "releaseTimestamp",
        className: "text-center",
        render: (data) => <div>{releaseTimeDate(data)}</div>
      },
      {
        width: 300,
        title: "txId",
        dataIndex: "txId",
        className: "text-center",
        render: (data) => <div> {checkData(data)} </div>
      },
      {
        width: 120,
        title: "상태",
        dataIndex: "status",
        className: "text-center",
        render: (data) => <div> {string.requestLogString[data]}</div>
      },
      {
        width: 120,
        title: "요청일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      }
    ];

    return (
      <div>
        {visible && (
          <LockModal
            visible={visible}
            onCancel={() => {
              this.setState({ visible: false });
            }}
            searchText={lockSearchText}
            lockRefresh={this.getLockList}
            requestRefresh={this.getLockRequestList}
          />
        )}
        <div className="title">LOCK 관리</div>
        <div className="flex-row flex-sb m-b-10">
          <Button
            onClick={() => {
              this.setState({ visible: true, type: "create" });
            }}>
            생성
          </Button>
          <div>
            <label htmlFor="" style={{ marginRight: "20px" }}>
              코인
              <Select
                style={{ width: "160px", marginLeft: "10px" }}
                value={coinType !== 0 ? coinType : "선택해주세요"}
                onChange={this.handleChangeCoinType}>
                {coinList.map((coin) => (
                  <Option key={coin.coinType} value={coin.coinType}>
                    {coin.name}
                  </Option>
                ))}
              </Select>
            </label>
            <label htmlFor="">
              검색기준{" "}
              <SelectBox
                style={{ width: "160px", marginRight: "10px" }}
                value={string.searchTypeString[lockSearchType]}
                code={string.memberSearchTypeCode}
                codeString={string.searchTypeString}
                onChange={(value) => {
                  if (value !== lockSearchType) {
                    this.setState({ lockSearchType: value });
                  }
                }}
              />
              <Search
                placeholder={`${
                  lockSearchType === "ID" ? "아이디" : "주소"
                }를 입력하세요`}
                enterButton="검색"
                allowClear
                onSearch={this.onLockSearch}
                style={{ width: "300px" }}
              />
            </label>
          </div>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={lockColumns}
          dataSource={lockList}
          pagination={false}
          rowClassName={(record) => {
            return releaseTimeDate(record.releaseTime) <=
              formatDateSecond(
                moment()
                  .endOf("day" - 1)
                  .toString()
              )
              ? "gray"
              : "";
          }}
        />
        <div style={{ clear: "both", height: "40px" }} />
        <div className="flex-row flex-end m-b-10">
          <label htmlFor="">
            TYPE{" "}
            <SelectBox
              style={{ width: "160px", marginRight: "10px" }}
              value={string.lockRequesetString[lockType]}
              code={string.lockRequestCode}
              codeString={string.lockRequesetString}
              onChange={(value) => {
                if (value !== lockType) {
                  this.setState({ lockType: value });
                }
              }}
            />
          </label>
          <label htmlFor="">
            상태{" "}
            <SelectBox
              style={{ width: "160px", marginRight: "10px" }}
              value={string.requestLogString[statusType]}
              code={string.requestLogCode}
              codeString={string.requestLogString}
              onChange={(value) => {
                if (value !== statusType) {
                  this.setState({ statusType: value });
                }
              }}
            />
          </label>
          <label htmlFor="">
            검색기준{" "}
            <SelectBox
              style={{ width: "160px", marginRight: "10px" }}
              value={string.lockRequesetSearchString[searchType]}
              code={string.lockRequestSearchCode}
              codeString={string.lockRequesetSearchString}
              onChange={(value) => {
                if (value !== searchType) {
                  this.setState({ searchType: value });
                }
              }}
            />
            <Search
              placeholder={`${
                searchType === "ID"
                  ? "아이디"
                  : searchType === "ADDRESS"
                  ? "주소"
                  : "TXID"
              }를 입력하세요`}
              enterButton="검색"
              allowClear
              onSearch={this.onRequestSearch}
              style={{ width: "300px" }}
            />
          </label>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
          // expandedRowRender={expandedRowRender}
        />
      </div>
    );
  }
}

export default DecentralLockList;
