import React, { Component } from "react";
import { Form, Icon, Input, Button, Result } from "antd";

import { httpUrl, httpPut } from "../api/httpClient";

const FormItem = Form.Item;

class ChangePassword extends Component {
  state = {
    confirmDirty: false
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("newPassword")) {
      callback("비밀번호가 다릅니다.");
    } else {
      callback();
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let body = { ...values };
        if (global.confirm("입력하신 비밀번호로 변경하시겠습니까?")) {
          // console.log(
          //   `${JSON.stringify(this.props, null, 4)}${JSON.stringify(
          //     body,
          //     null,
          //     4
          //   )}`
          // );
          httpPut(httpUrl.changePassword, [], {
            password: body.newPassword,
            token: this.props.match.params.token
          }).then((res) => {
            if (res.data) {
              this.props.history.push("/changePassword/success");
            } else {
              this.props.history.push("/changePassword/fail");
            }
          });
        }
      }
    });
  };

  componentDidMount() {}

  render() {
    const { getFieldDecorator } = this.props.form;
    const { match } = this.props;

    return match.params.token === "success" ? (
      <div
        style={{ height: "100%", display: "flex" }}
        className="flex-center justify-center">
        <Result
          status="success"
          title="비밀번호 변경"
          subTitle="비밀번호 변경이 완료되었습니다."
        />
      </div>
    ) : match.params.token === "fail" ? (
      <div
        style={{ height: "100%", display: "flex" }}
        className="flex-center justify-center">
        <Result
          status="error"
          title="비밀번호 변경"
          subTitle="비밀번호 변경이 실패하였습니다."
        />
      </div>
    ) : (
      <div className="login-container">
        <Form onSubmit={this.handleSubmit} className="login-form">
          <FormItem>
            {getFieldDecorator("newPassword", {
              rules: [
                { required: true, message: "변경할 비밀번호를 입력해주세요" },
                {
                  validator: this.validateToNextPassword
                }
              ]
            })(
              <Input.Password
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="비밀번호"
              />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator("confirm", {
              rules: [
                { required: true, message: "비밀번호를 한번 더 입력해주세요." },
                {
                  validator: this.compareToFirstPassword
                }
              ]
            })(
              <Input.Password
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="비밀번호 확인"
              />
            )}
          </FormItem>

          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ width: "100%" }}>
              비밀번호 변경
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}

export default Form.create()(ChangePassword);
