import React, { Component } from "react";
import { Button, Modal, Table } from "antd";
import moment from "moment";

import {
  formatDate,
  numberFormat,
  endDateFormat,
  formatDateSecond,
  amountFormat,
  checkData
} from "../../util";
import string from "../../string";
import SelectBox from "../common/SelectBox";
import { httpGet, httpPut, httpUrl } from "../../api/httpClient";
import ConfirmInputModal from "../common/ConfirmInputModal";
import LockCreateModal from "../common/LockCreateModal";
import { ShowErrorAlert } from "../common/ErrorAlertModal";
import { showAlert } from "../common/AlertModal";

class LockDetail extends Component {
  state = {
    list: [],
    pagination: {
      current: 0,
      total: 0
    },
    selectedRowKeys: [],
    selectedRows: [],
    deleteFlag: 0,

    deleteVisible: false,
    selectedRow: 0,
    createVisible: false
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.userLockPagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    const { pageType } = this.props;
    if (pageType === "foundationStatus")
      this.getUserLockList({
        pageNum: pager.current
      });
    else this.getLockLogList({ pageNum: pager.current });
  };

  // 날짜 제한 설정
  disabledDate = (current) => {
    // Can not select days before today and today
    return current < moment().endOf("day" - 1);
  };

  // 삭제 메모 작성 설정
  onDeleteHandle = (row) => {
    if (row.deleted === 1) {
      this.onDelete({
        idx: row.idx,
        memoDeleted: row.memoDeleted,
        deleted: row.deleted
      });
    } else {
      this.setState({
        deleteVisible: true,
        selectedRow: row
      });
    }
  };

  // 삭제
  onDelete = (params = {}) => {
    if (
      global.confirm(
        `해당 LOCK을 ${params.deleted === 1 ? "복구" : "삭제"}하시겠습니까?`
      )
    ) {
      httpPut(httpUrl.lockDelete, [], {
        idx: params.idx,
        memoDeleted: params.memoDeleted
      })
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            params.deleted === 1 ? showAlert("restore") : showAlert("delete");
            this.setState({
              deleteVisible: false
            });

            const { pagination } = this.state;
            if (this.props.pageType === "foundationStatus") {
              this.getUserLockList({
                pageNum: pagination.current
              });
              this.props.refresh();
            } else this.getLockLogList({ pageNum: pagination.current });
          } else ShowErrorAlert(res.data.result);
        })
        .catch((e) => {});
    }
  };

  // 다중선택
  onRowSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRowKeys,
      selectedRows: selectedRows.map((row) => {
        return {
          ...row,
          memoDeleted: ""
        };
      })
    });
  };

  // 다중선택 삭제
  onMuptiDelete = (parmas = {}) => {
    if (global.confirm(`선택하신 Lock들을 삭제 하시겠습니까?`)) {
      let idxs = [];
      this.state.selectedRows.map((row) => {
        idxs.push(row.idx);
      });
      // console.log(`idxs= ${idxs}`);
      let deleteDate = {
        idxs,
        memoDeleted: ""
      };
      // console.log(`deleteDate= ${JSON.stringify(deleteDate, null, 4)}`);
      httpPut(httpUrl.lockMuptiDelete, [], deleteDate)
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            showAlert("delete");
            this.setState({
              selectedRows: [],
              selectedRowKeys: []
            });
            const { pagination } = this.state;
            if (this.props.pageType === "foundationStatus") {
              this.getUserLockList({
                pageNum: pagination.current
              });
              this.props.refresh();
            } else this.getLockLogList({ pageNum: pagination.current });
          } else ShowErrorAlert(res.data.result);
        })
        .catch((e) => {});
    }
  };

  refresh = () => {
    this.getUserLockList();
    this.props.refresh();
  };

  // 재단 배포 내역 조회 - lock 내역 조회
  getLockLogList = (params = {}) => {
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.foundationCoinLogLock,
      [10, pageNum, this.props.selected.idx],
      {}
    )
      .then((result) => {
        // console.log(`result = ${JSON.stringify(result, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = result.data.currentPage;
        pagination.total = result.data.totalCount;
        this.setState({
          list: result.data.lockList,
          pagination,
          deleteFlag:
            result.data.lockList === null
              ? 0
              : result.data.lockList.every((item) =>
                  item.deleted ||
                  endDateFormat(item.expireDate) <=
                    formatDateSecond(
                      moment()
                        .endOf("day" - 1)
                        .toString()
                    )
                    ? 1
                    : 0
                )
        });
      })
      .catch((error) => {});
  };

  // 보유 현황 조회 -  회원의 코인 lock정보 조회
  getUserLockList = (params = {}) => {
    const { selected } = this.props;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.foundationUserLockDetail,
      [10, pageNum, selected.coinType, selected.address],
      {}
    )
      .then((result) => {
        const pagination = { ...this.state.pagination };
        pagination.current = result.data.currentPage;
        pagination.total = result.data.totalCount;
        // console.log(`userLock = ${JSON.stringify(result.data, null, 4)}`);
        this.setState({
          list: result.data.lockDetail,
          pagination,
          deleteFlag:
            result.data.lockDetail === null
              ? 0
              : result.data.lockDetail.every((item) =>
                  item.deleted ||
                  endDateFormat(item.expireDate) <=
                    formatDateSecond(
                      moment()
                        .endOf("day" - 1)
                        .toString()
                    )
                    ? 1
                    : 0
                )
        });
      })
      .catch((error) => {});
  };

  componentDidMount() {
    const { pageType } = this.props;
    // console.log(`props = ${JSON.stringify(this.props, null, 4)}`);

    if (pageType === "foundationStatus") this.getUserLockList();
    else this.getLockLogList();
  }

  render() {
    const { visible, title, onCancel } = this.props;
    const { selectedRowKeys, deleteFlag } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onRowSelectChange,
      getCheckboxProps: (record) => ({
        disabled:
          endDateFormat(record.expireDate) <=
            formatDateSecond(
              moment()
                .endOf("day" - 1)
                .toString()
            ) || record.deleted === 1,
        key: record.key
      })
    };

    const lockColumns = [
      {
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data, row) => <div>{numberFormat(data)}</div>
      },
      {
        title: "등록일자",
        dataIndex: "createDate",
        className: "text-center",
        render: (data, row) => <div>{formatDate(data)}</div>
      },
      {
        title: "마감일자",
        dataIndex: "expireDate",
        className: "text-center",
        render: (data, row) => <div>{formatDate(data)}</div>
      },
      {
        title: "수량",
        dataIndex: "amount",
        className: "text-center",
        render: (data, row) => (
          <div>
            {data === -1
              ? "전체잠금"
              : amountFormat(data, this.props.selected.coinType)}
          </div>
        )
      },
      {
        width: 300,
        title: "메모",
        dataIndex: "memo",
        className: "text-center",
        render: (data) => (
          <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
            {checkData(data)}
          </div>
        )
      },
      {
        width: 300,
        title: "삭제 메모",
        dataIndex: "memoDeleted",
        className: "text-center",
        render: (data) => (
          <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
            {checkData(data)}
          </div>
        )
      },
      {
        title: "상태",
        dataIndex: "deleted",
        className: "text-center",
        render: (data, row, index) => (
          <div>
            {endDateFormat(row.expireDate) >
            formatDateSecond(
              moment()
                .endOf("day" - 1)
                .toString()
            ) ? (
              <SelectBox
                value={string.deleteString[data]}
                code={string.toggleCode}
                codeString={string.deleteString}
                onChange={(value) => {
                  if (parseInt(value) !== row.deleted) {
                    this.onDeleteHandle(row);
                  }
                }}
              />
            ) : (
              <span>{`만료`}</span>
            )}
          </div>
        )
      }
    ];

    return (
      <Modal
        visible={visible}
        title={title}
        onCancel={onCancel}
        style={{ minWidth: "1400px" }}
        footer={null}>
        <div style={{ clear: "both" }} />
        {/* 삭제 메모 작성 */}
        {this.state.deleteVisible && (
          <ConfirmInputModal
            rootPage="lock"
            visible={this.state.deleteVisible}
            onOk={this.onDelete}
            onCancel={() => {
              this.setState({
                deleteVisible: false
              });
            }}
            selectedRow={this.state.selectedRow}
          />
        )}
        {/* 코인 락 생성 */}
        {this.state.createVisible && (
          <LockCreateModal
            pageType={this.props.pageType}
            modalType={this.state.modalType}
            editData={this.state.selectData}
            visible={this.state.createVisible}
            coinList={this.props.coinList}
            onCancel={() => {
              this.setState({
                createVisible: false,
                modalType: "create",
                selectData: {}
              });
            }}
            refresh={this.refresh}
          />
        )}
        <div style={{ clear: "both" }} />
        <div className="flex-row flex-end m-b-10">
          {/* 보유현황 조회로 들어온 경우 생성하기 버튼 보이도록 설정 */}
          {this.props.pageType === "foundationStatus" && (
            <Button
              style={{ marginRight: "10px" }}
              onClick={() => {
                this.setState({
                  createVisible: true,
                  modalType: "create"
                });
              }}>
              Lock 생성
            </Button>
          )}
          <Button
            onClick={this.onMuptiDelete}
            disabled={deleteFlag ? true : false}>
            선택한 LOCK 삭제
          </Button>
        </div>
        <Table
          id="lock-table"
          rowClassName={(record) => {
            // console.log(moment().endOf("day" - 1));
            return endDateFormat(record.expireDate) <=
              formatDateSecond(
                moment()
                  .endOf("day" - 1)
                  .toString()
              ) || record.deleted === 1
              ? "gray"
              : "";
          }}
          columns={lockColumns}
          rowKey={(record) => record.idx}
          style={{ marginBottom: "10px" }}
          dataSource={this.state.list}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          rowSelection={rowSelection}
        />
      </Modal>
    );
  }
}

export default LockDetail;
