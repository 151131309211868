import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import fileDownload from "js-file-download";

import { Table, Input, Select, Button, DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";

import { httpGet, httpUrl, httpDownload } from "../../api/httpClient";
import {
  numberFormat,
  amountFormat,
  formatDate,
  startDateFormat,
  endDateFormat,
  excelFormat,
  checkCoinName,
  checkData
} from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import P2PLogModal from "../../components/user/P2PLogModal";
import Const from "../../const";

const Option = Select.Option;
const { RangePicker } = DatePicker;
class TransferLog extends Component {
  state = {
    fullCoinList: [],

    transferLog: [],
    pagination: {
      current: 0,
      total: 0
    },
    coinType: 0,
    requestType: "ALL",
    //searchType: ALL일 경우 searchText: null
    searchType: "ALL",
    transferType: "ALL",
    startDate: "",
    endDate: "",
    searchText: "",
    isLoaded: false,

    p2pVisible: false,
    logIdx: 0
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getTransferLog({
      pageNum: pager.current
    });
  };

  // 코인 종류 선택
  handleChangeCoinType = (value) => {
    this.setState({
      coinType: value,
      isLoaded: true
    });
  };

  // 검색 날짜 선택
  handleChangeDate = (value) => {
    this.setState({
      startDate: value.length !== 0 ? startDateFormat(value[0]) : "",
      endDate: value.length !== 0 ? endDateFormat(value[1]) : "",
      isLoaded: true
    });
    // console.log(JSON.stringify(value, null, 4));
  };

  // 요청 조건 선택
  handleChangeRequestType = (value) => {
    this.setState({
      requestType: value,
      isLoaded: true
    });
  };

  // 전송 방법 선택
  handleChangeTransferType = (value) => {
    this.setState({
      transferType: value,
      isLoaded: true
    });
  };

  // 검색 조건 선택
  handleChangeSearchType = (value) => {
    let searchText = "";
    if (value !== "ALL") {
      searchText = this.state.searchText;
    }
    this.setState({
      searchType: value,
      searchText,
      isLoaded: true
    });
  };

  // 검색어 입력
  handleChangeSearchText = (e) => {
    this.setState({
      searchText: e.target.value,
      isLoaded: true
    });
  };

  // 엑셀 다운로드
  downloadExcel = () => {
    let {
      pagination,
      coinType,
      requestType,
      searchType,
      startDate,
      endDate,
      searchText,
      isLoaded,
      transferType
    } = this.state;

    if (!coinType || !startDate || !endDate) {
      alert("코인 종류 및 검색 기간 날짜를 선택해주세요.");
    } else {
      if (isLoaded) {
        alert("조회버튼을 먼저 클릭해주세요.");
      } else {
        let total = pagination.total <= 9 ? 10 : pagination.total;

        if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
          httpDownload(
            httpUrl.downloadTransferLogExcel,
            [
              total,
              1,
              coinType,
              requestType,
              searchType,
              startDate,
              endDate,
              searchText,
              transferType
            ],
            {}
          )
            .then((res) => {
              fileDownload(
                res,
                `${excelFormat(startDate)} ~ ${excelFormat(
                  endDate
                )} ${checkCoinName(coinType)} 거래현황.xlsx`
              );
            })
            .catch((e) => {
              alert(`다운로드 실패`);
            });
        }
      }
    }
  };

  getTransferLog = (params = {}) => {
    const {
      coinType,
      requestType,
      searchType,
      startDate,
      endDate,
      searchText,
      transferType
    } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getTransferLog,
      [
        10,
        pageNum,
        coinType,
        requestType,
        searchType,
        startDate,
        endDate,
        searchText,
        transferType
      ],
      {}
    )
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          transferLog: res.data.list === null ? [] : res.data.list,
          pagination,
          isLoaded: false
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    const coinList = reactLocalStorage.getObject("coinList");
    this.setState({
      fullCoinList: [
        {
          coinType: 0,
          name: "전체"
        },
        ...coinList
      ]
    });
    this.getTransferLog();
  }

  render() {
    const { p2pVisible, logIdx, transferLog } = this.state;

    const columns = [
      {
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "거래 일시",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        key: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "전송 방법",
        dataIndex: "type",
        key: "type",
        className: "text-center",
        render: (data) => (
          <div>
            {string.transferString[data] ? string.transferString[data] : "기타"}
          </div>
        )
      },
      {
        title: "보낸 주소",
        dataIndex: "from",
        className: "text-center"
      },
      {
        title: "받은 주소",
        dataIndex: "to",
        className: "text-center"
      },
      {
        title: "상태",
        dataIndex: "status",
        className: "text-center",
        render: (data) => <div>{string.requestLogString[data]}</div>
      },
      !Const.isDecentralFlag
        ? {
            title: "LOCK 내역",
            dataIndex: "p2p",
            className: "text-center",
            render: (data, row) => (
              <Button
                onClick={() => {
                  this.setState({ p2pVisible: true, logIdx: row.idx });
                }}>
                내역보기
              </Button>
            )
          }
        : {
            colSpan: 0,
            width: 0,
            render: () => {
              return {
                props: {
                  colSpan: 0
                }
              };
            }
          }
    ];

    const expandedRowRender = (record) => {
      let dataArr = [record];
      const dropColumns = [
        {
          title: "txid",
          dataIndex: "txid",
          className: "text-center",
          render: (data) => <div>{checkData(data)}</div>
        },
        {
          title: "수량",
          dataIndex: "amount",
          className: "text-center",
          render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
        },
        {
          title: "수수료",
          dataIndex: "fee",
          className: "text-center",
          render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
        },
        {
          title: "메모",
          dataIndex: "memo",
          className: "text-center",
          render: (data) => <div>{checkData(data)}</div>
        },
        record.coinType === 3
          ? {
              title: "destinationTag",
              key: "destinationTag",
              dataIndex: "destinationTag",
              className: "text-center"
            }
          : {
              colSpan: 0,
              width: 0,
              render: () => {
                return {
                  props: {
                    colSpan: 0
                  }
                };
              }
            }
      ];

      return (
        <Table
          rowKey={(record) => record.txid}
          columns={dropColumns}
          dataSource={dataArr}
          pagination={false}
        />
      );
    };

    return (
      <div>
        <div className="title">거래 현황</div>
        {p2pVisible && (
          <P2PLogModal
            type="transfer"
            visible={p2pVisible}
            logIdx={logIdx}
            onCancel={() => {
              this.setState({ p2pVisible: false });
            }}
          />
        )}
        <div className="m-b-10 flex-row flex-sb">
          <div className="flex-row flex-center">
            <label>코인 종류&nbsp;</label>
            <Select
              placeholder="코인종류"
              style={{ width: "160px", marginRight: "10px" }}
              value={this.state.coinType}
              onChange={this.handleChangeCoinType}>
              {this.state.fullCoinList.map((coin) => (
                <Option key={coin.coinType} value={coin.coinType}>
                  {coin.name}
                </Option>
              ))}
            </Select>
            <label>요청 조건&nbsp;</label>
            <SelectBox
              code={string.requestLogCode}
              codeString={string.requestLogString}
              value={string.requestLogString[this.state.requestType]}
              onChange={(value) => {
                this.handleChangeRequestType(value);
              }}
              style={{ width: "160px", marginRight: 10 }}
            />
            <label>전송 방법&nbsp;</label>
            <SelectBox
              codeString={string.transferString}
              value={string.transferString[this.state.transferType]}
              onChange={(value) => {
                this.handleChangeTransferType(value);
              }}
              style={{ width: "160px" }}
            />
          </div>
          <div className="flex-row flex-center">
            <div className="flex-row flex-center m-r-10">
              <label>검색 조건&nbsp;</label>
              <SelectBox
                code={string.walletLogCode}
                codeString={string.transferSearchString}
                value={string.transferSearchString[this.state.searchType]}
                onChange={(value) => {
                  this.handleChangeSearchType(value);
                }}
                style={{ width: "160px" }}
              />
            </div>
            <div className="flex-row flex-center m-r-10">
              <div>검색 기준&nbsp;</div>
              <Input
                placeholder="주소를 입력해주세요."
                disabled={this.state.searchType === "ALL"}
                value={this.state.searchText}
                onChange={this.handleChangeSearchText}
                style={{ width: "240px" }}
              />
            </div>
            <Button
              onClick={() => {
                this.getTransferLog();
              }}>
              검색
            </Button>
          </div>
        </div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <div className="flex-row flex-center">
            <label>검색 기간&nbsp;</label>
            <RangePicker
              locale={locale}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
            />
          </div>
          <Button
            onClick={this.downloadExcel}
            disabled={transferLog.length === 0 ? true : false}>
            엑셀 다운로드
          </Button>
        </div>
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={transferLog}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          expandedRowRender={expandedRowRender}
        />
      </div>
    );
  }
}

export default TransferLog;
