export const GET_START = "coin/GET_START";
export const GET_SUCCESS = "coin/GET_SUCCESS";
export const GET_REJECTED = "coin/GET_REJECTED";

export const coinStart = bool => {
  return {
    type: GET_START,
    payload: bool
  };
};

export const coinSuccess = data => {
  return {
    type: GET_SUCCESS,
    coinData: data,
    loading: false
  };
};

export const coinRejected = error => {
  return {
    type: GET_REJECTED,
    payload: error,
    loading: false
  };
};
