import React, { Component } from "react";
import { Table, Input, Select } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";
import string from "../../string";
import { formatDate, amountFormat } from "../../util";
import UserDetail from "../../components/user/UserDetail";
import FranchiseDetail from "../../components/frachise/FranchiseDetail";

const Search = Input.Search;
const { Option } = Select;

export default class PurchaseLog extends Component {
  state = {
    purchaseList: [],
    pagination: {
      current: 1,
      total: 0
    },
    searchType: "USER_ID",
    searchText: "",
    coinType: 0,

    modalVisible: false,
    modalType: "create",
    modalData: {},

    userDetailVisible: false,
    userDetailData: {},

    franchiseDetailVisible: false,
    franchiseDetailData: {},

    franchiseList: [],
    fCoinList: []
  };

  handleChangeSearchType = (value) => {
    this.setState({
      searchType: value
    });
  };

  onSearch = (value) => {
    this.getPurchaseLog();
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getPurchaseLog({
      pageNum: pager.current
    });
  };

  getPurchaseLog = (params = {}) => {
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getPurchaseLog,
      [
        10,
        pageNum,
        this.state.searchType,
        this.state.searchText,
        this.state.coinType
      ],
      {}
    )
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          purchaseList: res.data.log,
          pagination
        });
      })
      .catch((e) => {});
  };

  showUserDetail = (data) => {
    httpGet(httpUrl.getUserDetail, [data], {})
      .then((res) => {
        this.setState({
          userDetailVisible: true,
          userDetailData: res.data
        });
      })
      .catch((e) => {});
  };

  showFranchiseDetail = (data) => {
    httpGet(httpUrl.getFranchiseDetail, [data], {}).then((res) => {
      this.setState({
        franchiseDetailVisible: true,
        franchiseDetailData: res.data
      });
    });
  };

  componentDidMount() {
    httpGet(httpUrl.franchiseCoinType, [], {}).then((res) => {
      this.setState(
        {
          franchiseList: res.data,
          fCoinList: res.data.map((item) => {
            return item.coinType;
          })
        },
        () => {
          this.getPurchaseLog();
          // console.log(JSON.stringify(this.state.franchiseList, null, 4));
        }
      );
    });
  }

  render() {
    const searchMenu = (
      <Select
        defaultValue="USER_ID"
        style={{ width: 140 }}
        disabled={this.state.searchText === ""}
        onChange={this.handleChangeSearchType}>
        <Option value="USER_ID">사용자 아이디</Option>
        <Option value="FRANCHISE_ID">가맹점 아이디</Option>
      </Select>
    );
    const columns = [
      {
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center"
      },
      {
        key: "createDate",
        title: "생성일",
        width: 160,
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        key: "coinType",
        title: "코인 종류",
        dataIndex: "coinType",
        width: 120,
        className: "text-center",
        render: (data) => (
          <div>
            {
              this.state.franchiseList.find(
                (coin) => parseInt(coin.coinType) === parseInt(data)
              ).name
            }
            {/* {this.state.franchiseList.find(coin => coin.coinType === data) !==
            -1 ? (
              <div>

              </div>
            ) : (
              data
            )} */}
          </div>
        )
      },
      {
        key: "userId",
        title: "사용자",
        dataIndex: "userId",
        className: "text-center pointer",
        render: (data, row) => (
          <div
            onClick={() => {
              this.showUserDetail(data);
            }}>
            {data}
          </div>
        )
      },
      {
        title: "가맹점",
        key: "franchiseId",
        dataIndex: "franchiseId",
        className: "text-center pointer",
        render: (data, row) => (
          <div
            onClick={() => {
              this.showFranchiseDetail(data);
            }}>
            {data}
          </div>
        )
      },
      {
        title: "수량",
        key: "amount",
        dataIndex: "amount",
        className: "text-center",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      },
      {
        title: "페이백 수량",
        key: "paybackAmount",
        dataIndex: "paybackAmount",
        className: "text-center",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      },
      {
        title: "수수료",
        key: "fee",
        dataIndex: "fee",
        className: "text-center",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      },
      {
        title: "삭제상태",
        key: "deleted",
        dataIndex: "deleted",
        className: "text-center",
        render: (data) => <div>{string.deleteString[data]}</div>
      }
    ];
    return (
      <div>
        <div className="title">결제 내역</div>
        <UserDetail
          modalData={this.state.userDetailData}
          visible={this.state.userDetailVisible}
          rootPage="franchise"
          onCancel={() => {
            this.setState({
              userDetailVisible: false
            });
          }}
        />
        <FranchiseDetail
          visible={this.state.franchiseDetailVisible}
          modalData={this.state.franchiseDetailData}
          onCancel={() => {
            this.setState({
              franchiseDetailVisible: false
            });
          }}
        />
        <div className="flex-row flex-center flex-sb m-b-10">
          <div></div>
          <div className="flex-row flex-center">
            <label>검색기준&nbsp;</label>
            <Search
              addonBefore={searchMenu}
              placeholder="검색어를 입력하세요"
              enterButton="검색"
              allowClear
              onChange={(e) => {
                this.setState({
                  searchText: e.target.value
                });
              }}
              onSearch={this.onSearch}
              style={{ width: "500px" }}
            />
          </div>
        </div>
        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.purchaseList}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}
