import React, { Component } from "react";

import { Button, Input, InputNumber, Table } from "antd";
import { httpGet, httpPut, httpUrl } from "../../api/httpClient";

import { showAlert } from "../../components/common/AlertModal";

class CustomConfig extends Component {
  state = {
    list: [],
    isUpdate: false
  };

  onChangeModal = (value, type, row) => {
    var data = Object.assign({}, this.state.selectData);
    data[type] = value;
    // console.log(`change = ${JSON.stringify(data, null, 4)}`);
    this.setState({
      selectData: data
    });
  };

  onUpdate = () => {
    const { selectData } = this.state;
    // console.log(JSON.stringify(selectData, null, 4));

    if (global.confirm(`수정하시겠습니까?`)) {
      httpPut(httpUrl.customConfigUpdate, [], selectData)
        .then((result) => {
          showAlert("update");
          this.setState({
            selectedRow: "",
            selectData: {},
            prevData: {}
          });
          this.getCustomConfigList();
        })
        .catch((e) => {});
    }
  };

  getCustomConfigList = () => {
    httpGet(httpUrl.getCustomConfigList, [], {})
      .then((res) => {
        // console.log(`data = ${JSON.stringify(res.data, null, 4)}`);
        this.setState({
          list: res.data
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getCustomConfigList();
  }

  render() {
    const { selectedRow, selectData, prevData, list } = this.state;
    const columns = [
      {
        key: "title",
        title: "코드",
        dataIndex: "title",
        className: "text-center"
      },
      {
        key: "value",
        title: "값",
        dataIndex: "value",
        className: "text-center",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index ? (
              data
            ) : (
              <InputNumber
                min={0}
                allowClear
                value={selectData.value}
                onChange={(value) => {
                  this.onChangeModal(value, "value");
                }}
              />
            )}
          </div>
        )
      },
      {
        key: "memo",
        title: "메모",
        dataIndex: "memo",
        className: "text-center",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index ? (
              data
            ) : (
              <Input.TextArea
                allowClear
                value={selectData.memo}
                onChange={(e) => {
                  this.onChangeModal(e.target.value, "memo");
                }}
              />
            )}
          </div>
        )
      },
      {
        dataIndex: "update",
        className: "text-center",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index && (
              <Button
                onClick={() => {
                  this.setState({
                    isUpdate: true,
                    selectedRow: index,
                    selectData: row,
                    prevData: row
                  });
                }}>
                수정하기
              </Button>
            )}
            {selectedRow === index && (
              <div>
                <Button style={{ marginRight: "8px" }} onClick={this.onUpdate}>
                  수정
                </Button>
                <Button
                  onClick={() => {
                    this.setState({
                      selectedRow: "",
                      selectData: prevData
                    });
                  }}>
                  취소
                </Button>
              </div>
            )}
          </div>
        )
      }
    ];

    return (
      <div>
        <div className="title">기타 설정</div>
        <div className="wrapper mt-35 width-1240" />
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={false}
        />
      </div>
    );
  }
}

export default CustomConfig;
