import React, { Component } from "react";

import {
  Modal,
  Descriptions,
  Input,
  Switch,
  Icon,
  Button,
  InputNumber,
  Upload
} from "antd";
import { httpPut, httpGet, httpUrl, serverUrl } from "../../api/httpClient";
import { formatDate } from "../../util";
import UserList from "./UserList";
import Address from "./Address";
import { showAlert } from "../common/AlertModal";
import BasicImage from "../common/BasicImage";
import Axios from "axios";
import Const from "../../const";

const Ditems = Descriptions.Item;

class OrganizationDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailData: {},
      changeVisible: false,
      addressVisible: false,
      thumbnail: "",
      image: "",

      images: [],
      // 원본 이미지
      subImages: [],
      // 300*300 이미지
      subPreview: []
    };
  }

  onChangeModal = (value, type) => {
    var data = this.state.detailData;
    data[type] = value;
    if (
      type === "feeRatio" ||
      type === "shareRatio1" ||
      type === "shareRatio2"
    ) {
      if (value === "") data[type] = null;
      // else data[type] = value;
    }
    if (type === "parentId") {
      data[type] = value.id;
      data.parentName = value.name;
      data.parentIdx = value.idx;
    }
    this.setState({
      detailData: data
    });
    // console.log(JSON.stringify(data, null, 4));
  };

  addressSeach = (value) => {
    // console.log(JSON.stringify(value, null, 4));
    var data = this.state.detailData;
    data.locationAddress = value;
    this.setState({ addressVisible: false, detailData: data });
    httpGet(httpUrl.getGeocode, [value]).then((res) => {
      let address = "";
      if (res.data.y != 0 && res.data.x != 0)
        address = `${res.data.y}, ${res.data.x}`;
      var data = this.state.detailData;
      data.locationPoint = address;
      this.setState({ detailData: data });
    });
  };

  handleSubImgDeleteFile = (image) => {
    const { subPreview, subImages, images } = this.state;
    // console.log(JSON.stringify(image));

    // 원본 이미지 삭제 작업
    let idx = 0;
    subPreview.forEach((list, index) => {
      if (list.subPhoto.includes(image)) return (idx = list.idx);
    });
    // console.log(`index = ${idx}`);
    let subImg = [];
    if (subImages.length !== 0) {
      subImages.forEach((list, index) => {
        if (list.idx !== idx) subImg.push({ photo: list.photo, idx: list.idx });
      });
    }
    // 300*300 이미지
    let arr = [];
    subPreview.forEach((list) => {
      if (!list.subPhoto.includes(image))
        arr.push({ subPhoto: list.subPhoto, idx: list.idx });
      return arr;
    });

    images.push({ idx, request: "DELETE" });
    this.setState({ subImages: subImg, subPreview: arr, images });
  };

  onUpdate = () => {
    const { detailData, subImages, subPreview, images } = this.state;

    // 상세 이미지 병합 작업
    let temp = [];
    subImages.forEach((list, index) => {
      if (!list.idx) temp.push({ photo: list.photo, index });
    });
    subPreview.forEach((list, index) => {
      temp.forEach((img) => {
        if (!list.idx)
          if (img.index === index)
            images.push({
              photo: img.photo,
              subPhoto: list.subPhoto,
              request: "ADD"
            });
      });
    });

    let updateData = {
      ...detailData,
      franchise: detailData.franchise ? 1 : 0,
      autoAdjustment: detailData.autoAdjustment ? 1 : 0,
      thumbnail: this.state.thumbnail,
      image: this.state.image,
      images
    };

    // console.log(`update = ${JSON.stringify(updateData, null, 4)}`);
    if (global.confirm(`변경하시겠습니까?`))
      httpPut(httpUrl.organizationUpdate, [], updateData)
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            this.props.onOk();
            showAlert("modify");
          }
        })
        .catch((error) => {});
  };

  componentDidMount() {
    const { modalData } = this.props;
    const obj = Object.assign(
      {},
      {
        ...modalData,
        franchise: modalData.franchise === 1 ? true : false,
        autoAdjustment: this.propsautoAdjustment === 1 ? true : false
      }
    );
    this.setState({
      detailData: obj,
      image: obj.image,
      thumbnail: obj.thumbnail,
      subImages:
        Const.isOrganizationFlag && obj.images.length !== 0
          ? obj.images.map((list) => {
              return { photo: list.photo, idx: list.idx };
            })
          : [],
      subPreview:
        Const.isOrganizationFlag && obj.images.length !== 0
          ? obj.images.map((list) => {
              return { subPhoto: list.subPhoto, idx: list.idx };
            })
          : []
    });
    // console.log(`detail = ${JSON.stringify(obj, null, 4)}`);
    // console.log(
    //   `subPreview = ${JSON.stringify(this.state.subPreview, null, 4)}`
    // );
  }

  render() {
    const { visible, onCancel } = this.props;
    const {
      changeVisible,
      addressVisible,
      detailData,
      subPreview
    } = this.state;

    const uploadProps = {
      withCredentials: true,
      showUploadList: false,
      multiple: false,
      transformFile: async (file) => {
        let newImages = [];
        // console.log(file);
        newImages = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const canvas1 = document.createElement("canvas");
            const canvas2 = document.createElement("canvas");
            const img = document.createElement("img");
            img.src = reader.result;
            this.setState({
              preview: reader.result
            });
            img.onload = () => {
              let images = [];
              const ctx1 = canvas1.getContext("2d");
              const ctx2 = canvas2.getContext("2d");
              canvas1.width = 100;
              canvas1.height = 100;
              ctx1.drawImage(img, 0, 0, 100, 100);
              canvas1.toBlob(
                (blob) => {
                  images.push(new File([blob], `100x100${file.name}`));
                  // resolve(images);
                },
                "image/png",
                0.5
              );
              canvas2.width = 300;
              canvas2.height = 300;
              ctx2.drawImage(img, 0, 0, 300, 300);
              canvas2.toBlob(
                (blob) => {
                  images.push(new File([blob], `300x300${file.name}`));
                  resolve(images);
                },
                "image/png",
                0.5
              );
            };
          };
        });
        await new Promise((resolve) => {
          const formData100 = new FormData();
          const formData300 = new FormData();
          formData100.append("file", newImages[0]);
          formData300.append("file", newImages[1]);
          Axios({
            method: "POST",
            url: serverUrl + httpUrl.imageUpload,
            data: formData100,
            withCredentials: true
          }).then((res) => {
            if (res.data.data.result) {
              const obj = this.state.detailData;
              obj.thumbnail = res.data.data.fileId;
              this.setState({
                thumbnail: res.data.data.fileId,
                detailData: obj
              });
            } else {
              showAlert(res.data.data.reason);
            }
          });
          Axios({
            method: "POST",
            url: serverUrl + httpUrl.imageUpload,
            data: formData300,
            withCredentials: true
          }).then((res) => {
            if (res.data.data.result) {
              this.setState({
                image: res.data.data.fileId
              });
            }
          });
        });
      }
    };

    const subUploadProps = {
      withCredentials: true,
      showUploadList: false,
      multiple: false,
      transformFile: async (file) => {
        let newImages = [];
        newImages = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const canvas = document.createElement("canvas");
            const img = document.createElement("img");
            img.src = reader.result;
            img.onload = () => {
              let image = [];
              const ctx = canvas.getContext("2d");
              canvas.width = 300;
              canvas.height = 300;
              ctx.drawImage(img, 0, 0, 300, 300);
              canvas.toBlob(
                (blob) => {
                  image.push(new File([blob], `300x300${file.name}`));
                  image.push(file);
                  resolve(image);
                },
                "image/png",
                0.5
              );
            };
          };
        });
        await new Promise((resolve) => {
          const formData = new FormData();
          const formData300 = new FormData();
          formData.append("file", newImages[1]);
          formData300.append("file", newImages[0]);
          Axios({
            method: "POST",
            url: serverUrl + httpUrl.imageUpload,
            data: formData,
            withCredentials: true
          }).then((res) => {
            if (res.data.data.result) {
              let arr = this.state.subImages;
              arr.push({ photo: res.data.data.fileId });
              this.setState({
                subImages: arr
              });
            } else {
              showAlert(res.data.data.reason);
            }
          });
          Axios({
            method: "POST",
            url: serverUrl + httpUrl.imageUpload,
            data: formData300,
            withCredentials: true
          }).then((res) => {
            if (res.data.data.result) {
              let arr = this.state.subPreview;
              arr.push({ subPhoto: res.data.data.fileId });
              this.setState({
                subPreview: arr
              });
            }
          });
        });
      }
    };

    return (
      <Modal
        visible={visible}
        title="조직 상세"
        onCancel={onCancel}
        onOk={this.onUpdate}
        okText={"변경사항저장"}
        cancelText="취소"
        style={{ minWidth: "1000px" }}>
        {changeVisible && (
          <UserList
            visible={changeVisible}
            onCancel={() => {
              this.setState({ changeVisible: false });
            }}
            onOk={this.onChangeModal}
            userIdx={detailData.idx}
            type="organization"
          />
        )}
        {addressVisible && (
          <Address
            visible={addressVisible}
            onCancel={() => {
              this.setState({ addressVisible: false });
            }}
            onOk={this.addressSeach}
          />
        )}
        <Descriptions
          bordered
          column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}>
          <Ditems label="생성일" span={2}>
            {formatDate(detailData.createDate)}
          </Ditems>
          <Ditems label="조직 계정(ID / 이름)">
            {detailData.userId} /{" "}
            {detailData.userName === null ? "-" : detailData.userName}
          </Ditems>
          <Ditems label="상위 조직" className="flex-row flex-center">
            {detailData.parentName === null ? "-" : detailData.parentName}
            <Button
              style={{ marginLeft: "10px" }}
              onClick={() => {
                this.setState({
                  changeVisible: true
                });
              }}>
              수정
            </Button>
          </Ditems>
          <Ditems label="조직명" span={2}>
            <Input
              placeholder="조직명을 입력해주세요."
              value={detailData.name}
              onChange={(e) => {
                this.onChangeModal(e.target.value, "name");
              }}
            />
          </Ditems>
          <Ditems label="사업자번호">
            <Input
              placeholder="사업자번호를 입력해주세요."
              value={detailData.businessNum}
              onChange={(e) => {
                let value = e.target.value.replace(/[^0-9]/g, "");
                this.onChangeModal(value, "businessNum");
              }}
            />
          </Ditems>
          <Ditems label="전화번호">
            <Input
              placeholder="전화번호를 입력해주세요."
              value={detailData.tel}
              onChange={(e) => {
                let value = e.target.value.replace(/[^0-9]/g, "");
                this.onChangeModal(value, "tel");
              }}
            />
          </Ditems>
          <Ditems label="주소" span={2}>
            {detailData.locationAddress}
            <Button
              className={detailData.locationAddress ? "m-l-10" : ""}
              onClick={() => {
                this.setState({ addressVisible: true });
              }}>
              {detailData.locationAddress ? "주소 변경" : "주소 찾기"}
            </Button>
          </Ditems>
          <Ditems label="상세주소" span={2}>
            <Input
              placeholder="상세주소를 입력해주세요."
              value={detailData.locationAddressDetail}
              onChange={(e) => {
                this.onChangeModal(e.target.value, "locationAddressDetail");
              }}
            />
          </Ditems>
          <Ditems label="주소 좌표" span={2}>
            <div style={{ color: detailData.locationPoint ? "" : "red" }}>
              {detailData.locationPoint
                ? detailData.locationPoint
                : detailData.locationAddress !== null
                ? "입력한 주소를 네이버 지도에서 찾지 못하였습니다. 앱에서 가맹점 위치가 정상적으로 표출되지 않을 수 있습니다."
                : ""}
            </div>
          </Ditems>
          <Ditems label="메모" span={2}>
            <Input.TextArea
              placeholder="메모를 입력해주세요."
              value={detailData.memo}
              onChange={(e) => {
                this.onChangeModal(e.target.value, "memo");
              }}
            />
          </Ditems>
          <Ditems label="가맹점 여부" span={2}>
            {detailData.franchise ? "O" : "x"}
          </Ditems>
          {detailData.franchise && (
            <Ditems
              label={
                <div>
                  <div className="m-b-10">대표이미지</div>
                  <Upload {...uploadProps}>
                    <Button>
                      {detailData.thumbnail !== "" ? "변경" : "추가"}
                    </Button>
                  </Upload>
                </div>
              }
              span={2}>
              <div style={{ maxWidth: "300px", height: "auto" }}>
                {/* {detailData.thumbnail !== "" && ( */}
                <BasicImage
                  image={detailData.thumbnail}
                  src={serverUrl + httpUrl.getImage + detailData.thumbnail}
                  style={{ maxWidth: "300px", height: "auto" }}
                />
                {/* )} */}
              </div>
            </Ditems>
          )}
          {Const.isOrganizationFlag && detailData.franchise && (
            <Ditems label={<div className="m-b-10">상세 이미지</div>} span={2}>
              {subPreview.length !== 0 &&
                subPreview.map((list, index) => (
                  <div
                    key={index}
                    className="flex-row flex-center flex-sb"
                    style={{
                      marginBottom: subPreview.length === index ? 0 : "10px"
                    }}>
                    <BasicImage
                      image={list.subPhoto}
                      src={serverUrl + httpUrl.getImage + list.subPhoto}
                      style={{ maxWidth: "300px", height: "auto" }}
                    />
                    <Icon
                      type="delete"
                      className="pointer"
                      onClick={() => {
                        this.handleSubImgDeleteFile(list.subPhoto);
                      }}
                    />
                  </div>
                ))}
              <Upload {...subUploadProps}>
                <Button>추가하기</Button>
              </Upload>
            </Ditems>
          )}
          {detailData.franchise && (
            <Ditems label="자동 정산 여부">
              <Switch
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
                checked={detailData.autoAdjustment}
                onChange={(checked) => {
                  this.onChangeModal(checked, "autoAdjustment");
                }}
              />
            </Ditems>
          )}
          {detailData.franchise && (
            <Ditems label="정산 수수료 비율 (%)">
              <InputNumber
                min={0}
                max={100}
                style={{ width: "200px" }}
                value={detailData.feeRatio}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(value) => {
                  let feeRatio = value;
                  this.onChangeModal(feeRatio, "feeRatio");
                }}
              />
            </Ditems>
          )}
          {detailData.franchise && (
            <Ditems label="수익분배1단계 (%)">
              <InputNumber
                min={0}
                max={100}
                style={{ width: "200px" }}
                value={detailData.shareRatio1}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(value) => {
                  let shareRatio1 = value;
                  this.onChangeModal(shareRatio1, "shareRatio1");
                }}
              />
            </Ditems>
          )}
          {detailData.franchise && (
            <Ditems label="수익분배2단계 (%)">
              <InputNumber
                min={0}
                max={100}
                style={{ width: "200px" }}
                value={detailData.shareRatio2}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(value) => {
                  let shareRatio2 = value;
                  this.onChangeModal(shareRatio2, "shareRatio1");
                }}
              />
            </Ditems>
          )}
          {detailData.franchise && (
            <Ditems label="외부연동KEY" span={2}>
              {/* <Input
                placeholder="수익배분 매출의 외부연동이 있는 경우 외부시스템의 KEY를 입력"
                value={detailData.externalKey}
                maxLength={18}
                onChange={(e) => {
                  let value = e.target.value.match(/^[A-Za-z0-9+]*$/);
                  if (value) this.onChangeModal(value[0], "externalKey");
                }}
              /> */}
              {detailData.externalKey}
            </Ditems>
          )}
          {detailData.franchise && (
            <Ditems label="interfaceUri" span={2}>
              <Input
                placeholder="http://aaa.com/pay/complete?orderid={0}&token={1}"
                value={detailData.interfaceUri}
                onChange={(e) => {
                  this.onChangeModal(e.target.value, "interfaceUri");
                }}
              />
            </Ditems>
          )}
          {/* {detailData.franchise && (
            <Ditems label="redirectUri" span={2}>
              <Input
                value={detailData.redirectUri}
                onChange={(e) => {
                  this.onChangeModal(e.target.value, "redirectUri");
                }}
              />
            </Ditems>
          )} */}
          {Const.isOrganizationFlag && detailData.franchise && (
            <Ditems label="영업 시간" span={2}>
              <Input.TextArea
                placeholder="영업시간을 입력해주세요."
                value={detailData.openTime}
                onChange={(e) => {
                  this.onChangeModal(e.target.value, "openTime");
                }}
              />
            </Ditems>
          )}
          {Const.isOrganizationFlag && detailData.franchise && (
            <Ditems label="기타" span={2}>
              <Input.TextArea
                placeholder="기타를 입력해주세요."
                value={detailData.etc}
                onChange={(e) => {
                  this.onChangeModal(e.target.value, "etc");
                }}
              />
            </Ditems>
          )}
        </Descriptions>
        {detailData.franchise && (
          <div className="m-t-10">
            <span style={{ color: "blue" }}>
              정산 수수료 비율, 수익분배 비율을 미입력시 결제관리 > 설정값관리에
              설정된 값이 적용됩니다.
              <br />이 가맹점만 특별히 다른 비율을 적용하고 싶은 경우에만
              입력해주세요.
            </span>
          </div>
        )}
      </Modal>
    );
  }
}

export default OrganizationDetail;
