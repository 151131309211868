import React, { Component } from "react";

import { Form, Input, InputNumber, Modal } from "antd";
import { httpPost, httpUrl } from "../../api/httpClient";
import { checkCoinName, coinDivideFormat, coinMultiFormat } from "../../util";
import CoinSelectBox from "../common/CoinSelectBox";
import { showAlert } from "../common/AlertModal";

const FormItem = Form.Item;
const FoundationChargeModal = Form.create()(
  class extends Component {
    state = {};

    // 회수 시 실행
    onWithdrawRequest = (e) => {
      const { form, selectedData } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
        if (!err)
          if (global.confirm("회수하시겠습니까?")) {
            let body = {
              ...values,
              idx: selectedData.idx,
              coinType: selectedData.coinType,
              amount: coinMultiFormat(values.amount, selectedData.coinType)
            };
            // console.log(`body = ${JSON.stringify(body, null, 4)}`);
            httpPost(httpUrl.externalFoundationBalanceWithdraw, [], body)
              .then((res) => {
                showAlert("withdraw");
                this.props.onCancel();
                this.props.refresh({ refresh: true });
              })
              .catch((e) => {});
          }
      });
    };

    // 충전 시 실행
    onChargeRequest = (e) => {
      const { form, foundationIdx } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
        if (!err)
          if (global.confirm("충전하시겠습니까?")) {
            let body = {
              ...values,
              idx: foundationIdx,
              amount: coinMultiFormat(values.amount, values.coinType)
            };
            // console.log(`data = ${JSON.stringify(body, null, 4)}`);
            httpPost(httpUrl.externalFoundationBalanceCharge, [], body)
              .then((res) => {
                showAlert("charge");
                this.props.onCancel();
                this.props.refresh({ refresh: true });
              })
              .catch((e) => {});
          }
      });
    };

    onSubmit = (e) => {
      const { visibleType } = this.props;

      if (visibleType === "charge") this.onChargeRequest(e);
      else if (visibleType === "withdraw") this.onWithdrawRequest(e);
    };

    componentDidMount() {
      // console.log(`selectedData = ${JSON.stringify(this.props, null, 4)}`);
      const { visibleType, form, selectedData } = this.props;
      if (visibleType === "withdraw")
        form.setFieldsValue({ coinType: selectedData.coinType });
    }

    render() {
      const { visible, visibleType, onCancel, form, selectedData } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12 }
        }
      };

      return (
        <Modal
          style={{ minWidth: "600px" }}
          visible={visible}
          title={`코인 ${visibleType === "charge" ? "충전" : "회수"}`}
          okText={`${visibleType === "charge" ? "충전" : "회수"}하기`}
          onOk={this.onSubmit}
          cancelText="취소"
          onCancel={onCancel}>
          <Form {...formItemLayout} onSubmit={this.onSubmit}>
            <FormItem label={<span>코인 종류&nbsp;</span>}>
              {getFieldDecorator("coinType", {
                rules: [
                  {
                    required: false,
                    message: "코인 종류를 선택해주세요"
                  }
                ]
              })(
                <div>
                  {visibleType === "charge" ? (
                    <CoinSelectBox
                      style={{ width: "160px" }}
                      placeholder="선택해주세요"
                      value={this.state.coinType}
                      onChange={(value) => {
                        this.setState({ coinType: value });
                        form.setFieldsValue({ coinType: value });
                      }}
                    />
                  ) : (
                    checkCoinName(selectedData.coinType)
                  )}
                </div>
              )}
            </FormItem>
            <FormItem label={<span>코인 수량&nbsp;</span>}>
              {getFieldDecorator("amount", {
                rules: [
                  {
                    required: true,
                    message: "수량을 입력해주세요!"
                  },
                  {
                    validator: (rule, value, cb) => {
                      if (
                        visibleType === "withdraw" &&
                        value >
                          coinDivideFormat(
                            selectedData.balance,
                            selectedData.coinType
                          )
                      )
                        cb("보유 수량보다 큰 수량을 입력하셨습니다.");
                      cb();
                    }
                  }
                ]
              })(<InputNumber style={{ width: "250px" }} />)}
              {visibleType === "withdraw" && (
                <div>
                  코인 보유 수량 :{" "}
                  {coinDivideFormat(
                    selectedData.balance,
                    selectedData.coinType
                  )}
                </div>
              )}
            </FormItem>
            <FormItem label={<span>메모&nbsp;</span>}>
              {getFieldDecorator("memo", {
                rules: [
                  {
                    required: false,
                    message: "메모를 입력해주세요!"
                  }
                ]
              })(<Input.TextArea />)}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

export default FoundationChargeModal;
