import { GET_START, GET_SUCCESS, GET_REJECTED } from "../actions/coinFetch";

const initState = {
  loading: true,
  errorMessage: "",
  coinData: []
};

const coinReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_START:
      return Object.assign({}, state, {
        ...state,
        loading: action.payload
      });
    case GET_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        coinData: Array.isArray(action.coinData)
          ? { data: action.coinData }
          : action.coinData,
        loading: action.loading
      });
    case GET_REJECTED:
      return Object.assign({}, state, {
        ...state,
        errorMessage: action.payload,
        loading: action.loading
      });
    default:
      return state;
  }
};

export default coinReducer;
