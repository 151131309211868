import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Form, Input, Modal } from "antd";
import { httpPut, httpUrl } from "../../api/httpClient";
import { showAlert } from "../common/AlertModal";

const FormItem = Form.Item;
const WithdrawCurrencyUpdate = Form.create()(
  class extends Component {
    state = {};

    onUpdate = (e) => {
      const { form } = this.props;
      e.preventDefault();
      form.validateFields((err, values) => {
        // console.log(`valeus = ${JSON.stringify(values, null, 4)}`);
        if (!err)
          if (global.confirm("수정하시겠습니까?"))
            httpPut(httpUrl.withdrawCurrencyUpdate, [], values)
              .then((res) => {
                showAlert("update");
                this.props.onCancel();
                this.props.refresh();
              })
              .catch((e) => {});
      });
    };

    componentDidMount() {
      const { form, modalData } = this.props;

      form.setFieldsValue({ ...modalData });
    }

    render() {
      const { form, visible, onCancel } = this.props;
      const { getFieldDecorator } = form;
      const coinList = reactLocalStorage.getObject("coinList");

      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12 }
        }
      };

      return (
        <Modal
          visible={visible}
          title="출금시세 상세"
          onCancel={onCancel}
          onOk={this.onUpdate}
          okText="수정"
          cancelText="취소">
          <Form {...formItemLayout} onSubmit={this.onUpdate}>
            <FormItem label={<span>날짜&nbsp;</span>}>
              {getFieldDecorator("logDate", {
                rules: [
                  {
                    required: false
                  }
                ]
              })(<div>{form.getFieldValue("logDate")}</div>)}
            </FormItem>
            <FormItem label={<span>코인종류&nbsp;</span>}>
              {getFieldDecorator("coinType", {
                rules: [
                  {
                    required: false
                  }
                ]
              })(
                <div>
                  {coinList.find(
                    (coin) => coin.coinType === form.getFieldValue("coinType")
                  )
                    ? coinList.find(
                        (coin) =>
                          coin.coinType === form.getFieldValue("coinType")
                      ).name
                    : "-"}
                </div>
              )}
            </FormItem>
            <FormItem label={<span>금액&nbsp;</span>}>
              {getFieldDecorator("price", {
                rules: [
                  {
                    required: false
                  }
                ]
              })(<Input />)}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

export default WithdrawCurrencyUpdate;
