import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Modal, Descriptions, Input, InputNumber } from "antd";
import string from "../../string";
import { formatDate, priceDivideFormat, priceMultiFormat } from "../../util";
import { httpPut, httpUrl } from "../../api/httpClient";
import SelectBox from "./../common/SelectBox";

const Ditems = Descriptions.Item;

class WalletLinkusDetail extends Component {
  state = {
    detailData: {}
  };

  onChangeModal = (value, type) => {
    var data = this.state.detailData;
    data[type] = value;
    this.setState({
      detailData: data
    });
  };

  onUpdate = () => {
    const { detailData } = this.state;

    let body = {
      ...detailData,
      price: priceMultiFormat(detailData.price)
    };
    // console.log(`body = ${JSON.stringify(body, null, 4)}`);
    if (global.confirm(`변경하시겠습니까?`)) {
      httpPut(httpUrl.foundationUpdate, [], body)
        .then((result) => {
          this.props.onOk();
        })
        .catch((error) => {});
    }
  };

  componentDidUpdate(prevProps) {
    // if (modalData.idx !== prevProps.modalData.idx) {
    //   const obj = Object.assign({}, modalData);
    // }
  }

  componentDidMount() {
    const { modalData } = this.props;
    let data = {
      ...modalData,
      price: priceDivideFormat(modalData.price)
    };
    this.setState({
      detailData: data
    });
    // console.log(JSON.stringify(data, null, 4));
  }

  render() {
    const { detailData } = this.state;
    const { visible, onCancel } = this.props;
    const coinList = reactLocalStorage.getObject("coinList");

    let coinName = "";
    coinList.forEach((coin) => {
      if (coin.coinType === detailData.coinType) {
        coinName = coin.name;
      }
    });

    return (
      <Modal
        visible={visible}
        title={"재단"}
        okText="수정"
        cancelText="취소"
        onOk={this.onUpdate}
        onCancel={onCancel}
        style={{ minWidth: "800px" }}>
        {/* <div>{JSON.stringify(detailData)}</div> */}
        <Descriptions
          bordered
          column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}>
          <Ditems label="작성일">{formatDate(detailData.createDate)}</Ditems>
          <Ditems label="설정 가격">
            <SelectBox
              type="usePrice"
              style={{ width: "160px" }}
              value={string.activeHandlesCodes[detailData.usePrice]}
              code={string.toggleCode}
              codeString={string.activeHandlesCodes}
              onChange={this.onChangeModal}
            />
          </Ditems>
          <Ditems label="코인 종류">{coinName}</Ditems>
          <Ditems label="국가">
            <Input
              allowClear
              style={{ width: "200px" }}
              value={detailData.country}
              onChange={(e) => {
                this.onChangeModal(e.target.value, "country");
              }}
            />
          </Ditems>
          <Ditems label="시가총액">
            <InputNumber
              value={detailData.marketCap}
              style={{ width: "200px" }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              onChange={(value) => {
                this.onChangeModal(value, "marketCap");
              }}
            />
          </Ditems>
          <Ditems label="금액">
            <InputNumber
              value={detailData.price}
              style={{ width: "200px" }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              onChange={(value) => {
                this.onChangeModal(value, "price");
              }}
            />
          </Ditems>
        </Descriptions>
      </Modal>
    );
  }
}

export default WalletLinkusDetail;
