import { combineReducers } from "redux";
import async from "./asyncReducer";
import login from "./loginReducer";
import coin from "./coinReducer";

export default combineReducers({
  async,
  login,
  coin
});
