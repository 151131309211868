import React, { Component } from "react";
import { Table, Input, Button } from "antd";
import { httpGet, httpUrl, httpPut } from "../../api/httpClient";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import FranchiseUpdateModal from "../../components/frachise/FranchiseUpdateModal";
import { formatDate } from "../../util";
import { showAlert } from "../../components/common/AlertModal";

const Search = Input.Search;

export default class Franchise extends Component {
  state = {
    franchiseList: [],
    pagination: {
      current: 1,
      total: 0
    },
    statusType: "ALL",
    searchText: "",

    modalVisible: false,
    modalType: "create",
    modalData: {}
  };

  handleModalVisible = (modalType, row) => {
    this.setState({
      modalType,
      modalVisible: true,
      modalData: modalType === "edit" ? row : {}
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getFranchiseList({
      pageNum: pager.current
    });
  };

  handleChangeListStatusType = (value) => {
    this.setState(
      {
        statusType: value
      },
      () => {
        this.getFranchiseList();
      }
    );
  };

  onDelete = (row) => {
    if (
      global.confirm(
        `${row.id}을(를) ${row.deleted ? "복구" : "삭제"}하시겠습니까?`
      )
    ) {
      httpPut(httpUrl.franchiseDelete, [row.id], {})
        .then((result) => {
          if (row.deleted) showAlert("restore");
          else showAlert("delete");
          this.getFranchiseList({ pageNum: this.state.pagination.current });
        })
        .catch((error) => {});
    }
  };

  onChangeRowStatus = (value, row) => {
    if (
      global.confirm(
        `${row.id}을(를) ${string.franchiseStatusString[value]}(으)로 변경하시겠습니까?`
      )
    ) {
      httpPut(httpUrl.franchiseStatusUpdate, [row.id, value], {}).then(
        (res) => {
          showAlert("modify");
          this.getFranchiseList({ pageNum: this.state.pagination.current });
        }
      );
    }
  };

  onSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.getFranchiseList();
      }
    );
  };

  getFranchiseList = (params = {}) => {
    let pageNum = params.pageNum || 1;
    httpGet(httpUrl.getFranchiseList, [
      10,
      pageNum,
      this.state.statusType,
      this.state.searchText
    ])
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          franchiseList: res.data.list,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getFranchiseList();
  }

  render() {
    const columns = [
      {
        key: "id",
        title: "아이디",
        dataIndex: "id",
        className: "text-center"
      },
      {
        key: "createDate",
        title: "생성일",
        width: 160,
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        key: "name",
        title: "이름",
        dataIndex: "name",
        className: "text-center pointer",
        render: (data, row, index) => (
          <div
            onClick={() => {
              this.handleModalVisible("edit", row);
            }}>
            {data}
          </div>
        )
      },
      {
        title: "주소",
        key: "address",
        dataIndex: "address",
        className: "text-center"
      },
      {
        title: "전화번호",
        key: "phone",
        dataIndex: "phone",
        className: "text-center"
      },
      {
        title: "삭제상태",
        key: "deleted",
        dataIndex: "deleted",
        className: "text-center",
        render: (data, row) => (
          <SelectBox
            value={string.deleteString[data]}
            code={string.toggleCode}
            codeString={string.deleteString}
            onChange={(value) => {
              if (value !== data) {
                this.onDelete(row);
              }
            }}
          />
        )
      },
      {
        title: "상태",
        key: "status",
        dataIndex: "status",
        className: "text-center",
        render: (data, row) => (
          <SelectBox
            value={string.franchiseStatusString[data]}
            code={string.franchiseStatusCode}
            codeString={string.franchiseStatusString}
            onChange={(value) => {
              if (value !== data) {
                this.onChangeRowStatus(value, row);
              }
            }}
          />
        )
      }
    ];
    return (
      <div>
        <div className="title">가맹점 목록</div>
        <FranchiseUpdateModal
          visible={this.state.modalVisible}
          modalType={this.state.modalType}
          modalData={this.state.modalData}
          onCancel={() => {
            this.setState({
              modalVisible: false,
              modalType: "create",
              modalData: {}
            });
          }}
          refresh={() => {
            if (this.state.modalType === "create") {
              this.getFranchiseList();
            } else {
              this.getFranchiseList({ pageNum: this.state.pagination.current });
            }
          }}
        />
        <div
          className="flex-row flex-center flex-sb"
          style={{ marginBottom: "10px" }}>
          <div className="flex-row flex-center">
            <Button
              onClick={() => {
                this.handleModalVisible("create");
              }}>
              생성
            </Button>
          </div>
          <div className="flex-row flex-center">
            <div style={{ marginRight: "10px" }}>
              <label>대상&nbsp;</label>
              <SelectBox
                value={string.franchiseListStatusString[this.state.statusType]}
                code={string.franchiseListStatusCode}
                codeString={string.franchiseListStatusString}
                onChange={(value) => {
                  if (value !== this.state.statusType)
                    this.handleChangeListStatusType(value);
                }}
              />
            </div>
            <label>검색기준&nbsp;</label>
            <Search
              placeholder="검색어를 입력하세요"
              enterButton="검색"
              allowClear
              onSearch={this.onSearch}
              style={{ width: "300px" }}
            />
          </div>
        </div>
        <Table
          rowKey={(record) => record.id}
          dataSource={this.state.franchiseList}
          columns={columns}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}
