import React, { Component } from "react";
import fileDownload from "js-file-download";

import { Table, Input, DatePicker, Button, Icon, Checkbox } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import { httpGet, httpUrl, httpDownload } from "../../api/httpClient";

import CoinSelectBox from "../../components/common/CoinSelectBox";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import {
  numberFormat,
  formatDate,
  amountFormat,
  startDateFormat,
  excelFormat,
  endDateFormat,
  checkCoinName,
  checkData
} from "../../util";

const Search = Input.Search;
const { RangePicker } = DatePicker;
class FoundationLog extends Component {
  state = {
    foundationList: [],
    fCoinList: [],

    lockList: [],
    pagination: {
      total: 0,
      current: 0
    },
    orderType: "CREATE_DATE",
    orderByType: "ASC",
    dateType: "CREATE_DATE",
    searchType: [],
    searchText: "",
    expireDate: false,

    isLoaded: false
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getDesignationList({
      pageNum: pager.current
    });
  };

  // 코인 종류 선택
  coinTypeHandleChange = (value) => {
    this.setState({
      coinType: value,
      isLoaded: true
    });
  };

  // 정렬 기준 선택
  orderTypeHandleChange = (value) => {
    const { coinType, startDate, endDate } = this.state;
    if (!coinType || !startDate || !endDate) {
      alert("코인 종류 및 검색 기간을 선택해주세요.");
    } else {
      this.setState({
        orderType: value,
        isLoaded: true
      });
    }
  };

  // 정렬 순서 선택
  orderByTypeHandleChange = (value) => {
    const { coinType, startDate, endDate } = this.state;
    if (!coinType || !startDate || !endDate) {
      alert("코인 종류 및 검색 기간을 선택해주세요.");
    } else {
      this.setState({
        orderByType: value,
        isLoaded: true
      });
    }
  };

  // 검색 기간 종류 선택
  dateTypeHandleChange = (value) => {
    const { coinType, startDate, endDate } = this.state;
    if (!coinType || !startDate || !endDate) {
      alert("코인 종류 및 검색 기간을 선택해주세요.");
    } else {
      this.setState({
        dateType: value,
        isLoaded: true
      });
    }
  };

  // 검색 기간 설정
  handleChangeDate = (value) => {
    this.setState({
      startDate: value.length !== 0 ? startDateFormat(value[0]) : "",
      endDate: value.length !== 0 ? endDateFormat(value[1]) : "",
      isLoaded: true
    });
    // console.log(JSON.stringify(value, null, 4));
  };

  // 검색 조건 선택 - 삭제포함, 전체잠금포함, 만료포함 여부
  searchTypeHandleChange = (checkedValues) => {
    // console.log(`checked = ${JSON.stringify(checkedValues)}`);
    const { coinType, startDate, endDate } = this.state;
    if (!coinType || !startDate || !endDate) {
      alert("코인 종류 및 검색 기간을 선택해주세요.");
    } else {
      this.setState({
        searchType: checkedValues,
        isLoaded: true
      });
    }
  };

  // 검색
  onSearch = (value) => {
    const { coinType, startDate, endDate } = this.state;
    if (!coinType || !startDate || !endDate) {
      alert("코인 종류 및 검색 기간을 선택해주세요.");
    } else {
      this.setState(
        {
          searchText: value
        },
        () => {
          this.getDesignationList();
        }
      );
    }
  };

  // 엑셀 다운로드
  downloadExcel = () => {
    const {
      coinType,
      pagination,
      orderType,
      orderByType,
      dateType,
      searchType,
      startDate,
      endDate,
      searchText,
      isLoaded
    } = this.state;

    if (!coinType || !startDate || !endDate) {
      alert("코인 종류 및 검색 기간을 선택해주세요.");
    } else {
      // console.log(`${excelFormat(startDate)} ~ ${excelFormat(endDate)}`);
      if (isLoaded) {
        alert("조회버튼을 먼저 클릭해주세요.");
      } else {
        let deleted = false;
        let allLock = false;
        searchType.forEach((type) => {
          if (type === "deleted") deleted = true;
          else if (type === "allLock") allLock = true;
        });
        let total = pagination.total <= 9 ? 10 : pagination.total;

        if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
          httpDownload(
            httpUrl.designationListExcel,
            [
              total,
              1,
              coinType,
              orderType,
              orderByType,
              dateType,
              deleted,
              allLock,
              startDate,
              endDate,
              searchText
            ],
            {}
          )
            .then((res) => {
              // console.log(JSON.stringify(res, null, 4));
              fileDownload(
                res,
                `${excelFormat(startDate)} ~ ${excelFormat(
                  endDate
                )} ${checkCoinName(coinType)} LOCK 지정 내역.xlsx`
              );
            })
            .catch((error) => {
              alert(`다운로드 실패`);
            });
        }
      }
    }
  };

  // lock지정 내역 조회
  getDesignationList = (params = {}) => {
    const {
      coinType,
      orderType,
      orderByType,
      dateType,
      searchType,
      startDate,
      endDate,
      searchText
    } = this.state;
    let pageNum = params.pageNum || 1;

    if (coinType && startDate && endDate) {
      let deleted = false;
      let allLock = false;
      let expireDate = false;
      searchType.forEach((type) => {
        if (type === "deleted") deleted = true;
        else if (type === "allLock") allLock = true;
        else if (type === "expireDate") expireDate = true;
      });
      httpGet(
        httpUrl.designationList,
        [
          10,
          pageNum,
          coinType,
          orderType,
          orderByType,
          dateType,
          deleted,
          allLock,
          startDate,
          endDate,
          searchText,
          expireDate
        ],
        {}
      )
        .then((res) => {
          // console.log(JSON.stringify(res, null, 4));
          const pagination = { ...this.state.pagination };
          pagination.current = res.data.currentPage;
          pagination.total = res.data.totalCount;
          this.setState({
            lockList:
              res.data.lockSelectLog === null ? [] : res.data.lockSelectLog,
            pagination,
            isLoaded: false
          });
        })
        .catch((error) => {});
    }
  };

  componentDidMount() {
    // 재단 코인 종류 조회
    httpGet(httpUrl.getFoundationList, [], {})
      .then((res) => {
        this.setState({
          foundationList: res.data,
          fCoinList: res.data.map((item) => {
            return item.coinType;
          })
        });
      })
      .catch((e) => {});
  }

  render() {
    const {
      lockList,
      coinType,
      fCoinList,
      orderType,
      orderByType,
      dateType,
      pagination
    } = this.state;

    const lockColumns = [
      {
        width: 120,
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        // width: 120
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "마감일",
        dataIndex: "expireDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "아이디",
        dataIndex: "id",
        className: "text-center"
      },
      {
        title: "지갑주소",
        dataIndex: "address",
        className: "text-center"
      },
      {
        title: "금액",
        dataIndex: "amount",
        className: "text-center",
        render: (data, row) => (
          <div>
            {data === -1 ? "전체잠금" : amountFormat(data, row.coinType)}
          </div>
        )
      },
      {
        width: 200,
        title: "메모",
        dataIndex: "memo",
        className: "text-center",
        render: (data) => (
          <div
            style={{
              whiteSpace: "nowrap",
              width: "200px",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}>
            {checkData(data)}
          </div>
        )
      }
    ];

    const options = [
      { label: "삭제 포함", value: "deleted" },
      { label: "전체잠금 포함", value: "allLock" },
      {
        label: "만료 포함",
        value: "expireDate"
      }
    ];

    return (
      <div>
        {/* {JSON.stringify(this.props.result, null, 4)} */}
        <div className="title">LOCK 지정 내역</div>
        <div className="wrapper mt-35 width-1240" />
        <div style={{ marginBottom: "10px" }}>
          <label htmlFor="" style={{ marginRight: "20px" }}>
            코인{" "}
            <CoinSelectBox
              type="coinType"
              placeholder="선택해주세요."
              value={coinType}
              coinCode={fCoinList}
              onChange={(value) => {
                if (value !== coinType) this.coinTypeHandleChange(value);
              }}
              style={{ width: "160px" }}
            />
          </label>
          <label htmlFor="">
            순서{" "}
            <SelectBox
              value={string.orderByString[orderByType]}
              code={string.orderByCode}
              codeString={string.orderByString}
              onChange={(value) => {
                if (value !== orderByType) this.orderByTypeHandleChange(value);
              }}
              style={{ width: "160px", marginRight: "10px" }}
            />
            <SelectBox
              value={string.designationString[orderType]}
              code={string.foundationLogCode}
              codeString={string.designationString}
              onChange={(value) => {
                if (value !== orderType) this.orderTypeHandleChange(value);
              }}
              style={{ width: "160px" }}
            />
          </label>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <label htmlFor="" style={{ marginRight: "20px" }}>
            검색기간{" "}
            <SelectBox
              value={string.designationLogString[dateType]}
              code={string.designationLogCode}
              codeString={string.designationLogString}
              onChange={(value) => {
                if (value !== dateType) this.dateTypeHandleChange(value);
              }}
              style={{ width: "160px", marginRight: "10px" }}
            />
            <RangePicker
              // placeholder="시작일"
              locale={locale}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
            />
          </label>
          <div
            className="flex-row flex-center"
            style={{ marginBottom: "10px", float: "right" }}>
            <label>검색조건&nbsp;&nbsp;</label>
            <Checkbox.Group
              options={options}
              onChange={this.searchTypeHandleChange}
            />
            <Search
              placeholder="아이디, 지갑주소를 입력하세요"
              enterButton="검색"
              allowClear
              onSearch={this.onSearch}
              style={{ width: "300px" }}
            />
          </div>
        </div>
        <div
          style={{
            width: "100%",
            marginBottom: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
          <span>{`total : ${numberFormat(pagination.total)}`}</span>
          <Button onClick={this.downloadExcel}>
            <Icon type="download" style={{ fontSize: "20px" }} />
            엑셀 다운로드
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="lock-table"
          rowKey={(record) => record.idx}
          columns={lockColumns}
          dataSource={lockList}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default FoundationLog;
