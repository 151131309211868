import Const from "./const";

const string = {
  memberSearchTypeCode: ["ID", "ADDRESS", "NAME", "PHONE"],
  memberSearchTypeString: {
    ID: "아이디",
    ADDRESS: "지갑주소",
    NAME: "이름",
    PHONE: "전화번호"
  },
  searchTypeString: {
    ID: "아이디",
    ADDRESS: "지갑주소"
  },
  walletLogCode: ["ALL", "FROM", "TO"],
  walletLogString: Const.isFoundationInFee
    ? {
        ALL: "전체",
        FROM: "보낸 지갑주소",
        TO: "받은 지갑주소",
        SERVER: "재단 에어드랍"
      }
    : {
        ALL: "전체",
        FROM: "보낸 지갑주소",
        TO: "받은 지갑주소"
      },
  transferSearchString: {
    ALL: "전체",
    FROM: "보낸 지갑주소",
    TO: "받은 지갑주소"
  },
  sendString: {
    I2I: "내부 전송",
    I2E: "외부 송금",
    E2I: "외부 입금",
    S2I: "샵 전송",
    AIR: "에어드랍",
    P2P: "P2P 전송"
  },
  transfeCode: ["ALL", "I2I", "I2E", "E2I", "AIR", "P2P"],
  transferString: Const.isP2PFlag
    ? {
        ALL: "전체",
        I2I: "내부 전송",
        I2E: "외부 송금",
        E2I: "외부 입금",
        AIR: "에어드랍",
        P2P: "P2P 전송"
      }
    : {
        ALL: "전체",
        I2I: "내부 전송",
        I2E: "외부 송금",
        E2I: "외부 입금",
        AIR: "에어드랍"
      },
  typeCode: ["FROM", "TO"],
  cautionString: {
    FROM: "송신 제한",
    TO: "수신 제한"
  },
  activeCode: ["ALL", "ACTIVE", "NOT"],
  activeString: {
    ALL: "전체",
    ACTIVE: "활성화",
    NOT: "비활성화"
  },
  statusCode: ["ALL", "REQUEST", "REJECT", "ACCEPT", "FINISH"],
  statusString: {
    ALL: "전체",
    REQUEST: "요청",
    REJECT: "거절",
    ACCEPT: "승인",
    FINISH: "완료"
  },
  acceptString: {
    REQUEST: "요청",
    REJECT: "거절",
    ACCEPT: "승인",
    FINISH: "완료"
  },
  feeLogTypeString: Const.isFoundationInFee
    ? {
        ALL: "전체",
        TRADE_IN: "교환",
        SEND_OUT: "외부 거래",
        SEND_IN: "내부 거래",
        FOUNDER_IN: "재단 내부 전송",
        FOUNDER_EX: "재단 외부 전송"
      }
    : Const.isPurchaseInFee
    ? {
        ALL: "전체",
        TRADE_IN: "교환",
        SEND_OUT: "외부 거래",
        SEND_IN: "내부 거래",
        PURCHASE: "구매"
      }
    : Const.isP2PFlag
    ? {
        ALL: "전체",
        SEND_OUT: "외부 거래",
        SEND_IN: "내부 거래",
        P2P: "P2P 전송"
      }
    : {
        ALL: "전체",
        SEND_OUT: "외부 거래",
        SEND_IN: "내부 거래"
      },
  reasonCode: [
    "ALL",
    "EXCEED_AMOUNT",
    "TOO_FREQUENT",
    "CAUTION_TO",
    "CAUTION_FROM"
  ],
  reasonString: {
    ALL: "전체",
    EXCEED_AMOUNT: "금액 초과",
    TOO_FREQUENT: "20분내 동일한 주소로 3회 이상 출금",
    CAUTION_TO: "의심되는 지갑으로 출금",
    CAUTION_FROM: "의심되는 지갑에서 출금"
  },
  mergeLogCode: ["ALL", "SUCCESS", "FAIL", "PENDING"],
  mergeLogString: {
    ALL: "전체",
    SUCCESS: "성공",
    FAIL: "실패",
    PENDING: "전송 중"
  },
  distributeCode: ["ALL", "MANUAL", "AUTO"],
  distributeString: {
    ALL: "전체",
    MANUAL: "수동",
    AUTO: "자동"
  },
  distributeTypeString: {
    MANUAL: "수동",
    LOGIN: "로그인",
    RECMND: "추천가입(추천자)",
    RECMNDED: "추천가입",
    NOT: "미추천가입"
  },
  dropString: {
    LOGIN: "로그인",
    RECMND: "추천가입(추천자)",
    RECMNDED: "추천가입",
    NOT: "미추천가입"
  },
  noticeCode: ["ALL", "NOTE", "EVNT", "NEWS"],
  noticeString: {
    ALL: "전체",
    NOTE: "공지사항",
    EVNT: "이벤트",
    NEWS: "소식"
  },
  noticeSelectCode: ["NOTE", "EVNT", "NEWS"],
  noticeSelect: {
    NOTE: "공지사항",
    EVNT: "이벤트",
    NEWS: "소식"
  },
  lockString: {
    LOG: "내부 전송",
    REQUEST: "외부 전송"
  },
  logCode: ["ALL", "REQUEST", "SUCCESS", "PENDING"],
  logString: {
    ALL: "전체",
    REQUEST: "요청",
    SUCCESS: "성공",
    PENDING: "전송 중"
  },
  requestLogCode: ["ALL", "REQUEST", "SUCCESS", "PENDING", "FAIL"],
  requestLogString: {
    ALL: "전체",
    REQUEST: "요청",
    SUCCESS: "성공",
    PENDING: "전송 중",
    FAIL: "실패"
  },
  foundationOrderCode: ["ID", "BALANCE"],
  foundationOrderString: {
    ID: "아이디",
    BALANCE: "보유수량"
  },
  foundationLockCode: ["IDX", "CREATE_DATE", "EXPIRE_DATE", "ID"],
  foundationLockString: {
    IDX: "순번",
    CREATE_DATE: "등록일",
    EXPIRE_DATE: "마감일",
    ID: "아이디"
  },
  foundationLogCode: ["ID", "CREATE_DATE"],
  foundationLogString: { ID: "아이디", CREATE_DATE: "배포일자" },
  designationString: { ID: "아이디", CREATE_DATE: "등록일자" },
  designationOrderCode: ["ID", "EXPIRE_DATE"],
  designationOrderString: { ID: "아이디", EXPIRE_DATE: "배포일자" },
  designationLogCode: ["CREATE_DATE", "EXPIRE_DATE"],
  designationLogString: { CREATE_DATE: "등록일자", EXPIRE_DATE: " 마감일자" },
  designationSelectCode: ["ALL", "DELETED", "EXPIRE_DATE", "ALL_LOCK"],
  designationSelectString: {
    ALL: "전체",
    DELETED: "삭제 제외",
    EXPIRE_DATE: "만료 전",
    ALL_LOCK: "전체 잠금"
  },
  statCode: ["COIN_TYPE", "STAT_DATE"],
  statString: { COIN_TYPE: "코인 종류", STAT_DATE: "일자" },
  statDetailCode: ["COIN_TYPE", "CREATE_DATE"],
  statDetailString: { COIN_TYPE: "코인 종류", CREATE_DATE: "일자" },
  stateSelectCode: ["ALL", "IN", "OUT", "FEE"],
  stateSelectString: {
    ALL: "전체",
    IN: "입금",
    OUT: "출금",
    FEE: "수수료"
  },
  paybackCode: ["USER_ID", "FRANCHISE_ID"],
  paybackString: { USER_ID: "유저 아이디", FRANCHISE_ID: "가맹점 아이디" },
  tradeLogCode: ["ALL", "BEFORE", "AFTER"],
  tradeLogString: {
    ALL: "전체",
    BEFORE: "교환 전",
    AFTER: "교환 후"
  },
  paybackType: ["NULL", "AMOUNT", "PRICE"],
  paybackTypeString: { NULL: "선택안함", AMOUNT: "코인", PRICE: "원화" },
  payType: { ONLINE: "온라인", OFFLINE: "오프라인" },
  organizationCode: ["ALL", "FRANCHISE", "NOT"],
  organizationString: {
    ALL: "전체",
    FRANCHISE: "가맹점",
    NOT: "비가맹점"
  },
  proofType: {
    EXCHANGE: "거래소구매", // 구매내역화면
    P2P: "개인거래", // 계약서, 송금내역
    ICO: "ICO참여", // 구매내역서
    ETC: "기타"
  },
  tradeCode: ["ALL", "REQUEST", "SUCCESS", "REJECT"],
  tradeStatus: {
    ALL: "전체",
    REQUEST: "요청",
    SUCCESS: "승인",
    REJECT: "거절"
  },
  tpointChargeSearchTypeCode: ["ALL", "USER_ID", "MALL_ID"],
  pointChargeSearchType: {
    ALL: "전체",
    USER_ID: "유저 아이디",
    MALL_ID: "몰 아이디"
  },
  externalChargeLog: ["ALL", "IN", "OUT"],
  externalChargeLogString: {
    ALL: "전체",
    IN: "충전",
    OUT: "회수"
  },
  orderByCode: ["ASC", "DESC"],
  orderByString: {
    ASC: "정순",
    DESC: "역순"
  },
  urlLinkCode: ["ALL", "ONLINE", "OFFLINE"],
  urlLinkString: {
    ALL: "전체",
    ONLINE: "온라인",
    OFFLINE: "오프라인"
  },
  lockRequestCode: ["SET_LOCK", "UN_LOCK"],
  lockRequesetString: {
    ALL: "전체",
    SET_LOCK: "LOCK",
    UN_LOCK: "UNLOCK"
  },
  lockRequestSearchCode: ["ID", "ADDRESS", "TXID"],
  lockRequesetSearchString: {
    ID: "아이디",
    ADDRESS: "지갑주소",
    TXID: "TXID"
  },
  linkString: {
    0: "오프라인",
    1: "온라인"
  },
  exchageString: {
    0: "해외",
    1: "국내"
  },
  toggleCode: [0, 1],
  deleteString: {
    0: " - ",
    1: "삭제"
  },
  activeHandlesCodes: {
    0: "비활성화",
    1: "활성화"
  },
  adminString: {
    0: "일반관리자",
    1: "슈퍼관리자"
  },
  authString: {
    0: "불가능",
    1: "가능"
  },
  foundationString: {
    0: "가능",
    1: "불가능"
  },
  userString: {
    0: "-",
    1: "탈퇴"
  },
  certifiedString: {
    0: "미완료",
    1: "완료"
  },
  ethFeeString: {
    0: "수수료 미보유",
    1: "수수료 보유"
  },
  requestString: {
    0: "요청 가능",
    1: "요청 중"
  },
  otpVerifyString: {
    0: "미인증",
    1: "인증"
  },
  autoFlagString: {
    0: "-",
    1: "승인"
  },
  loginString: {
    0: "실패",
    1: "성공"
  },
  franchiseListStatusCode: ["ALL", "REQUEST", "APPROVAL", "REJECT"],
  franchiseListStatusString: {
    ALL: "전체",
    REQUEST: "요청",
    APPROVAL: "승인",
    REJECT: "거절"
  },
  franchiseStatusCode: ["REQUEST", "APPROVAL", "REJECT"],
  franchiseStatusString: {
    REQUEST: "요청",
    APPROVAL: "승인",
    REJECT: "거절"
  },
  adjustmentCode: ["ALL", "CREATED", "COMPLETE", "REJECT"],
  adjustmentString: {
    ALL: "전체",
    CREATED: "대기",
    COMPLETE: "지급완료",
    REJECT: "지급거절"
  },
  adjustmentChangeStatusString: {
    CREATED: "대기",
    COMPLETE: "지급완료",
    REJECT: "지급거절"
  },
  adjustmentSettingString: {
    FEE_RATIO: "정산수수료",
    SHARE_RATIO_1: "수익배분1단계",
    SHARE_RATIO_2: "수익배분2단계"
  },
  exchangeCode: [
    "ALL",
    "REQUEST",
    "ACCEPT",
    "REJECT",
    "PENDING",
    "SUCCESS",
    "FAIL"
  ],
  exchangeString: {
    ALL: "전체",
    REQUEST: "요청",
    ACCEPT: "승인",
    REJECT: "거절",
    PENDING: "전송 중",
    SUCCESS: "성공",
    FAIL: "실패"
  },
  exchangeSearchCode: ["FROM", "TO", "EMAIL", "TXID"],
  exchangeSearchString: {
    FROM: "보낸주소",
    TO: "받은 주소",
    EMAIL: "이메일",
    TXID: "TXID"
  },
  bankCode: [
    "BANK_AMERICA",
    "BANK_BUSAN",
    "BANK_CU",
    "BANK_DB",
    "BANK_DGB",
    "BANK_HSBC",
    "BANK_IBK",
    "BANK_JEJU",
    "BANK_K",
    "BANK_KAKAO",
    "BANK_KB",
    "BANK_KDB",
    "BANK_KEB",
    "BANK_KJ",
    "BANK_KN",
    "BANK_MG",
    "BANK_NH",
    "BANK_SC",
    "BANK_SH",
    "BANK_SHINHAN",
    "BANK_WOORI",
    "BANK_POST"
  ],
  bankString: {
    BANK_KN: "경남은행",
    BANK_KJ: "광주은행",
    BANK_KB: "KB국민은행",
    BANK_IBK: "IBK기업은행",
    BANK_NH: "농협은행",
    BANK_DGB: "대구은행",
    BANK_BUSAN: "부산은행",
    BANK_KDB: "산업은행",
    BANK_MG: "새마을금고",
    BANK_SH: "수협은행",
    BANK_SHINHAN: "신한은행",
    BANK_CU: "신협은행",
    BANK_SC: "SC은행",
    BANK_WOORI: "우리은행",
    BANK_POST: "우체국",
    BANK_JEJU: "제주은행",
    BANK_KAKAO: "카카오뱅크",
    BANK_K: "k뱅크",
    BANK_KEB: "KEB하나은행",
    BANK_HSBC: "HSBC은행",
    BANK_AMERICA: "뱅크오브아메리카",
    BANK_DB: "도이치은행"
  },
  countryCode: [
    "KO",
    "CN",
    "US",
    "JP",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "AZ",
    "BH",
    "BD",
    "BY",
    "BE",
    "BZ",
    "BJ",
    "BT",
    "BO",
    "BA",
    "BW",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "BI",
    "KH",
    "CM",
    "CA",
    "CV",
    "CF",
    "TD",
    "CL",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "HR",
    "CU",
    "CW",
    "CY",
    "CZ",
    "CD",
    "DK",
    "DJ",
    "TL",
    "EC",
    "EG",
    "SV",
    "GQ",
    "ER",
    "EE",
    "ET",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "PF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GT",
    "GN",
    "GW",
    "GY",
    "HT",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IR",
    "IQ",
    "IE",
    "IL",
    "IT",
    "CI",
    "JO",
    "KZ",
    "KE",
    "KI",
    "XK",
    "KW",
    "KG",
    "LA",
    "LV",
    "LB",
    "LS",
    "LR",
    "LY",
    "LI",
    "LT",
    "LU",
    "MO",
    "MK",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MR",
    "MU",
    "YT",
    "MX",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MA",
    "MZ",
    "MM",
    "NA",
    "NR",
    "NP",
    "NL",
    "AN",
    "NC",
    "NZ",
    "NI",
    "NE",
    "NG",
    "NU",
    "KP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PS",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "QA",
    "CG",
    "RE",
    "RO",
    "RU",
    "RW",
    "BL",
    "SH",
    "MF",
    "PM",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "SO",
    "ZA",
    "SS",
    "ES",
    "LK",
    "SD",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "SY",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TG",
    "TK",
    "TO",
    "TN",
    "TR",
    "TM",
    "TV",
    "UG",
    "UA",
    "AE",
    "GB",
    "UY",
    "UZ",
    "VU",
    "VA",
    "VE",
    "VN",
    "WF",
    "EH",
    "YE",
    "ZM",
    "ZW"
  ],
  countryTelCode: [
    "82-",
    "86-",
    "1-",
    "81-",
    "54-",
    "374-",
    "297-",
    "61-",
    "43-",
    "994-",
    "973-",
    "880-",
    "375-",
    "32-",
    "501-",
    "229-",
    "975-",
    "591-",
    "387-",
    "267-",
    "55-",
    "246-",
    "673-",
    "359-",
    "226-",
    "257-",
    "855-",
    "237-",
    "1-",
    "238-",
    "236-",
    "235-",
    "56-",
    "61-",
    "61-",
    "57-",
    "269-",
    "682-",
    "506-",
    "385-",
    "53-",
    "599-",
    "357-",
    "420-",
    "243-",
    "45-",
    "253-",
    "670-",
    "593-",
    "20-",
    "503-",
    "240-",
    "291-",
    "372-",
    "251-",
    "500-",
    "298-",
    "679-",
    "358-",
    "33-",
    "689-",
    "241-",
    "220-",
    "995-",
    "49-",
    "233-",
    "350-",
    "30-",
    "299-",
    "502-",
    "224-",
    "245-",
    "592-",
    "509-",
    "504-",
    "852-",
    "36-",
    "354-",
    "91-",
    "62-",
    "98-",
    "964-",
    "353-",
    "972-",
    "39-",
    "225-",
    "962-",
    "7-",
    "254-",
    "686-",
    "383-",
    "965-",
    "996-",
    "856-",
    "371-",
    "961-",
    "266-",
    "231-",
    "218-",
    "423-",
    "370-",
    "352-",
    "853-",
    "389-",
    "261-",
    "265-",
    "60-",
    "960-",
    "223-",
    "356-",
    "692-",
    "222-",
    "230-",
    "262-",
    "52-",
    "691-",
    "373-",
    "377-",
    "976-",
    "382-",
    "212-",
    "258-",
    "95-",
    "264-",
    "674-",
    "977-",
    "31-",
    "599-",
    "687-",
    "64-",
    "505-",
    "227-",
    "234-",
    "683-",
    "850-",
    "47-",
    "968-",
    "92-",
    "680-",
    "970-",
    "507-",
    "675-",
    "595-",
    "51-",
    "63-",
    "64-",
    "48-",
    "351-",
    "974-",
    "242-",
    "262-",
    "40-",
    "7-",
    "250-",
    "590-",
    "290-",
    "590-",
    "508-",
    "685-",
    "378-",
    "239-",
    "966-",
    "221-",
    "381-",
    "248-",
    "232-",
    "65-",
    "421-",
    "386-",
    "677-",
    "252-",
    "27-",
    "211-",
    "34-",
    "94-",
    "249-",
    "597-",
    "47-",
    "268-",
    "46-",
    "41-",
    "963-",
    "886-",
    "992-",
    "255-",
    "66-",
    "228-",
    "690-",
    "676-",
    "216-",
    "90-",
    "993-",
    "688-",
    "256-",
    "380-",
    "971-",
    "44-",
    "598-",
    "998-",
    "678-",
    "379-",
    "58-",
    "84-",
    "681-",
    "212-",
    "967-",
    "260-",
    "263-"
  ],
  countryName: {
    KO: "대한민국",
    CN: "중국",
    US: "미국",
    JP: "일본",
    AR: "Argentina",
    AM: "Afghanistan",
    AW: "Albania",
    AU: "Algeria",
    AT: "American Samoa",
    AZ: "Andorra",
    BH: "Anguilla",
    BD: "Antarctica",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BT: "Bhutan",
    BO: "Bolivia",
    BA: "Bosnia and Herzegovina",
    BW: "Botswana",
    BR: "Brazil",
    IO: "British Indian Ocean Territory",
    BN: "Brunei",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    CV: "Cape Verde",
    CF: "Central African Republic",
    TD: "Chad",
    CL: "Chile",
    CX: "Christmas Island",
    CC: "Cocos Islands",
    CO: "Colombia",
    KM: "Comoros",
    CK: "Cook Islands",
    CR: "Costa Rica",
    HR: "Croatia",
    CU: "Cuba",
    CW: "Curacao",
    CY: "Cyprus",
    CZ: "Czech Republic",
    CD: "Democratic Republic of the Congo",
    DK: "Denmark",
    DJ: "Djibouti",
    TL: "East Timor",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    ET: "Ethiopia",
    FK: "Falkland Islands",
    FO: "Faroe Islands",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    PF: "French Polynesia",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GT: "Guatemala",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran",
    IQ: "Iraq",
    IE: "Ireland",
    IL: "Israel",
    IT: "Italy",
    CI: "Ivory Coast",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    XK: "Kosovo",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: "Laos",
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macau",
    MK: "Macedonia",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands",
    MR: "Mauritania",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexico",
    FM: "Micronesia",
    MD: "Moldova",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands",
    AN: "Netherlands Antilles",
    NC: "New Caledonia",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    KP: "North Korea",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestine",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PT: "Portugal",
    QA: "Qatar",
    CG: "Republic of the Congo",
    RE: "Reunion",
    RO: "Romania",
    RU: "Russia",
    RW: "Rwanda",
    BL: "Saint Barthelemy",
    SH: "Saint Helena",
    MF: "Saint Martin",
    PM: "Saint Pierre and Miquelon",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome and Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SK: "Slovakia",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    SS: "South Sudan",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SJ: "Svalbard and Jan Mayen",
    SZ: "Swaziland",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syria",
    TW: "Taiwan",
    TJ: "Tajikistan",
    TZ: "Tanzania",
    TH: "Thailand",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates",
    GB: "United Kingdom",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VA: "Vatican",
    VE: "Venezuela",
    VN: "Vietnam",
    WF: "Wallis and Futuna",
    EH: "Western Sahara",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe"
  },
  countryTel: {
    KO: "82-",
    CN: "86-",
    US: "1-",
    JP: "81-",
    AR: "54-",
    AM: "374-",
    AW: "297-",
    AU: "61-",
    AT: "43-",
    AZ: "994-",
    BH: "973-",
    BD: "880-",
    BY: "375-",
    BE: "32-",
    BZ: "501-",
    BJ: "229-",
    BT: "975-",
    BO: "591-",
    BA: "387-",
    BW: "267-",
    BR: "55-",
    IO: "246-",
    BN: "673-",
    BG: "359-",
    BF: "226-",
    BI: "257-",
    KH: "855-",
    CM: "237-",
    CA: "1-",
    CV: "238-",
    CF: "236-",
    TD: "235-",
    CL: "56-",
    CX: "61-",
    CC: "61-",
    CO: "57-",
    KM: "269-",
    CK: "682-",
    CR: "506-",
    HR: "385-",
    CU: "53-",
    CW: "599-",
    CY: "357-",
    CZ: "420-",
    CD: "243-",
    DK: "45-",
    DJ: "253-",
    TL: "670-",
    EC: "593-",
    EG: "20-",
    SV: "503-",
    GQ: "240-",
    ER: "291-",
    EE: "372-",
    ET: "251-",
    FK: "500-",
    FO: "298-",
    FJ: "679-",
    FI: "358-",
    FR: "33-",
    PF: "689-",
    GA: "241-",
    GM: "220-",
    GE: "995-",
    DE: "49-",
    GH: "233-",
    GI: "350-",
    GR: "30-",
    GL: "299-",
    GT: "502-",
    GN: "224-",
    GW: "245-",
    GY: "592-",
    HT: "509-",
    HN: "504-",
    HK: "852-",
    HU: "36-",
    IS: "354-",
    IN: "91-",
    ID: "62-",
    IR: "98-",
    IQ: "964-",
    IE: "353-",
    IL: "972-",
    IT: "39-",
    CI: "225-",
    JO: "962-",
    KZ: "7-",
    KE: "254-",
    KI: "686-",
    XK: "383-",
    KW: "965-",
    KG: "996-",
    LA: "856-",
    LV: "371-",
    LB: "961-",
    LS: "266-",
    LR: "231-",
    LY: "218-",
    LI: "423-",
    LT: "370-",
    LU: "352-",
    MO: "853-",
    MK: "389-",
    MG: "261-",
    MW: "265-",
    MY: "60-",
    MV: "960-",
    ML: "223-",
    MT: "356-",
    MH: "692-",
    MR: "222-",
    MU: "230-",
    YT: "262-",
    MX: "52-",
    FM: "691-",
    MD: "373-",
    MC: "377-",
    MN: "976-",
    ME: "382-",
    MA: "212-",
    MZ: "258-",
    MM: "95-",
    NA: "264-",
    NR: "674-",
    NP: "977-",
    NL: "31-",
    AN: "599-",
    NC: "687-",
    NZ: "64-",
    NI: "505-",
    NE: "227-",
    NG: "234-",
    NU: "683-",
    KP: "850-",
    NO: "47-",
    OM: "968-",
    PK: "92-",
    PW: "680-",
    PS: "970-",
    PA: "507-",
    PG: "675-",
    PY: "595-",
    PE: "51-",
    PH: "63-",
    PN: "64-",
    PL: "48-",
    PT: "351-",
    QA: "974-",
    CG: "242-",
    RE: "262-",
    RO: "40-",
    RU: "7-",
    RW: "250-",
    BL: "590-",
    SH: "290-",
    MF: "590-",
    PM: "508-",
    WS: "685-",
    SM: "378-",
    ST: "239-",
    SA: "966-",
    SN: "221-",
    RS: "381-",
    SC: "248-",
    SL: "232-",
    SG: "65-",
    SK: "421-",
    SI: "386-",
    SB: "677-",
    SO: "252-",
    ZA: "27-",
    SS: "211-",
    ES: "34-",
    LK: "94-",
    SD: "249-",
    SR: "597-",
    SJ: "47-",
    SZ: "268-",
    SE: "46-",
    CH: "41-",
    SY: "963-",
    TW: "886-",
    TJ: "992-",
    TZ: "255-",
    TH: "66-",
    TG: "228-",
    TK: "690-",
    TO: "676-",
    TN: "216-",
    TR: "90-",
    TM: "993-",
    TV: "688-",
    UG: "256-",
    UA: "380-",
    AE: "971-",
    GB: "44-",
    UY: "598-",
    UZ: "998-",
    VU: "678-",
    VA: "379-",
    VE: "58-",
    VN: "84-",
    WF: "681-",
    EH: "212-",
    YE: "967-",
    ZM: "260-",
    ZW: "263-"
  }
};

export default string;
