export function showAlert(alertType) {
  if (alertType === "create") alert("생성이 완료되었습니다.");
  if (alertType === "update") alert("수정이 완료되었습니다.");
  if (alertType === "modify") alert("변경이 완료되었습니다.");
  if (alertType === "delete") alert("삭제가 완료되었습니다.");
  if (alertType === "authUpdate") alert("권한이 수정되었습니다.");
  if (alertType === "restore") alert("복구가 완료되었습니다.");
  if (alertType === "renew") alert("갱신이 완료 되었습니다.");
  if (alertType === "add") alert("추가 되었습니다.");
  if (alertType === "send") alert("배포가 완료되었습니다.");
  if (alertType === "charge") alert("충전이 완료되었습니다.");
  if (alertType === "withdraw") alert("회수가 완료되었습니다.");
  if (alertType === "UNKNOWN_TYPE")
    alert(
      "이미지 형식이 올바르지 않습니다.\njpg, png, bmp 형식의 파일을 올려주세요."
    );
  if (alertType === "SAVE_FAIL") alert("이미지 저장에 실패하였습니다.");

  if (alertType === "coin") alert("코인 종류를 선택해주세요.");
  if (alertType === "lock") alert("");
  if (alertType === "unlock") alert("해제되었습니다.");
  if (alertType === "unlockRequest") alert("해제를 요청하였습니다.");
}
