import React, { Component } from "react";

import { Table, InputNumber, Button } from "antd";
import { httpGet, httpUrl, httpPut } from "../../api/httpClient";

import { checkCoinName, checkData, formatDate } from "../../util";
import { showAlert } from "../../components/common/AlertModal";

export default class PaybackSetting extends Component {
  state = {
    logList: [],
    selectedRow: "",
    selectedData: {},
    prevData: {}
  };

  onChangeModal = (value, type, row) => {
    var data = Object.assign({}, this.state.selectData);
    if (type === "coinType") {
      data[type] = value;
      data.ratio = row.ratio * 100;
    } else data[type] = value;
    // console.log(data);
    this.setState({
      selectData: data
    });
  };

  // 수정
  onUpdate = () => {
    const { selectData } = this.state;

    let updateData = {
      coinType: selectData.coinType,
      ratio: selectData.ratio / 100
    };
    // console.log(`updateData = ${JSON.stringify(updateData, null, 4)}`);
    httpPut(httpUrl.paybackUpdate, [], updateData)
      .then((result) => {
        this.setState({ selectedRow: "", selectData: {}, prevData: {} }, () =>
          this.getPaybackSetting()
        );
      })
      .catch((error) => {});
  };

  // 삭제
  onDelete = (value) => {
    // console.log(value);
    if (global.confirm(`${checkCoinName(value)}을(를) 삭제하시겠습니까?`)) {
      httpPut(httpUrl.paybackDelete, [value], {})
        .then((result) => {
          showAlert("delete");
          this.getPaybackSetting();
        })
        .catch((error) => {});
    }
  };

  getPaybackSetting = (params = {}) => {
    httpGet(httpUrl.getPaybackSetting, [], {})
      .then((result) => {
        this.setState({
          logList: result.data
        });
      })
      .catch((error) => {});
  };

  componentDidMount() {
    this.getPaybackSetting();
  }

  render() {
    const { logList, selectedRow, selectData, prevData } = this.state;

    const logColums = [
      {
        key: "coinType",
        title: "코인 종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "비율",
        dataIndex: "ratio",
        className: "text-center",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index ? (
              `${data * 100} %`
            ) : (
              <InputNumber
                min={0}
                max={100}
                value={selectData.ratio}
                onChange={(value) => {
                  this.onChangeModal(value, "ratio");
                }}
              />
            )}
          </div>
        )
      },
      {
        title: "수정일",
        dataIndex: "updateDate",
        className: "text-center",
        render: (data, row, index) => <div>{checkData(formatDate(data))}</div>
      },
      {
        title: "설정",
        dataIndex: "set",
        // width: 80,
        className: "text-center",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index ? (
              <Button
                onClick={() => {
                  this.setState({
                    selectedRow: index,
                    prevData: row
                  });
                  this.onChangeModal(row.coinType, "coinType", row);
                }}>
                {row.updateDate === null ? "생성하기" : "수정하기"}
              </Button>
            ) : (
              <div>
                <Button
                  style={{ marginRight: "8px" }}
                  type="primary"
                  shape="circle"
                  icon="check"
                  onClick={this.onUpdate}
                />
                <Button
                  type="danger"
                  shape="circle"
                  icon="close"
                  onClick={() => {
                    this.setState({
                      selectedRow: "",
                      selectData: prevData
                    });
                  }}
                />
              </div>
            )}
          </div>
        )
      },
      {
        title: "상태",
        dataIndex: "deleted",
        className: "text-center",
        render: (data, row, index) => (
          <div>
            {selectedRow === index ? (
              "-"
            ) : (
              <Button
                onClick={() => {
                  if (row.updateDate === null)
                    alert("생성 후 삭제가 가능합니다.");
                  else this.onDelete(row.coinType);
                }}>
                삭제하기
              </Button>
            )}
          </div>
        )
      }
    ];
    return (
      <div>
        <div className="title">페이백 설정</div>
        <div className="wrapper mt-35 width-1240" />
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.coinType}
          columns={logColums}
          dataSource={logList}
          pagination={false}
        />
      </div>
    );
  }
}
