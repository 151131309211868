import React, { Component } from "react";

import { Table, Input, Select } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";

import SelectBox from "../../components/common/SelectBox";
import { formatDate, amountFormat, checkCoinName } from "../../util";
import string from "../../string";

const Search = Input.Search;
const Option = Select.Option;
export default class PaybackLog extends Component {
  state = {
    franchiseList: [],
    fCoinList: [],

    logList: [],
    pagination: {
      total: 0,
      current: 0
    },
    coinType: 0,
    searchType: "USER_ID",
    searchText: ""
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getPaybackLog({ pageNum: pager.current });
  };

  // 코인 종류 선택
  coinTypeHandleChange = (value) => {
    this.setState({
      coinType: value
    });
  };

  // 검색 조건 선택
  searchTypeHandleChange = (value) => {
    this.setState({
      searchType: value
    });
  };

  // 검색
  onSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.getPaybackLog({ pageNum: 1 });
      }
    );
  };

  // 페이백 내역 조회
  getPaybackLog = (params = {}) => {
    const { coinType, searchType, searchText } = this.state;
    let pageNum = params.pageNum || 1;
    httpGet(
      httpUrl.getPaybackLog,
      [10, pageNum, coinType, searchType, searchText],
      {}
    )
      .then((result) => {
        // console.log(JSON.stringify(result, null, 4));
        const pagination = { ...this.state.pagination };
        pagination.current = result.data.currentPage;
        pagination.total = result.data.totalCount;
        this.setState({
          logList: result.data.log,
          pagination
        });
      })
      .catch((error) => {});
  };

  componentDidMount() {
    httpGet(httpUrl.franchiseCoinType, [], {})
      .then((res) => {
        this.setState({
          franchiseList: res.data,
          fCoinList: res.data.map((item) => {
            return item.coinType;
          })
        });
        this.getPaybackLog();
      })
      .catch((error) => {});
  }

  renderOption = () => {
    const { franchiseList, fCoinList } = this.state;
    let result = [];
    for (let i = 0; i < franchiseList.length; i++) {
      for (let j = 0; j < fCoinList.length; j++) {
        if (franchiseList[i].coinType === fCoinList[j]) {
          result.push(
            <Option
              value={franchiseList[i].coinType}
              key={`${franchiseList[i]}${i}`}>
              {franchiseList[i].name}
            </Option>
          );
        }
      }
    }
    return result;
  };

  render() {
    const { coinType, searchType, logList, pagination } = this.state;
    const logColums = [
      {
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center"
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data, row, index) => (
          <div key={`createDate${index}`}>{formatDate(data)}</div>
        )
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "가맹점 아이디",
        dataIndex: "franchiseId",
        // width: 80,
        className: "text-center"
      },
      {
        title: "유저 아이디",
        dataIndex: "userId",
        // width: 80,
        className: "text-center"
      },
      {
        title: "금액",
        dataIndex: "amount",
        className: "text-center",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      },
      {
        title: "비율",
        dataIndex: "ratio",
        // width: 80,
        className: "text-center",
        render: (data) => <div>{`${data * 100} %`}</div>
      }
    ];

    return (
      <div>
        <div className="title">페이백 내역</div>
        <div className="wrapper mt-35 width-1240" />
        <div
          className="flex-row flex-center flex-sb"
          style={{
            marginBottom: "10px"
          }}>
          <label htmlFor="" style={{ marginRight: "20px" }}>
            코인{" "}
            <Select
              placeholder="선택해주세요."
              value={coinType}
              onChange={(value) => {
                if (value !== coinType) this.coinTypeHandleChange(value);
              }}
              style={{ width: "160px" }}>
              {this.renderOption()}
            </Select>
          </label>
          <div className="flex-row flex-center">
            <label htmlFor="" style={{ marginRight: "20px" }}>
              검색 조건{" "}
              <SelectBox
                value={string.paybackString[searchType]}
                code={string.paybackCode}
                codeString={string.paybackString}
                onChange={(value) => {
                  if (value !== searchType) this.searchTypeHandleChange(value);
                }}
                style={{ width: "160px" }}
              />
            </label>
            <label htmlFor="" style={{ marginRight: "10px" }}>
              검색
            </label>
            <Search
              placeholder="검색어를 입력하세요"
              enterButton="검색"
              allowClear
              onSearch={(value) => {
                if (coinType === undefined) alert("코인을 선택해주세요.");
                else this.onSearch(value);
              }}
              style={{ width: "300px" }}
            />
          </div>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={logColums}
          dataSource={logList}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}
