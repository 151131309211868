import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Table, Input, Select, Button } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";

import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import {
  numberFormat,
  formatDate,
  amountFormat,
  coinDivideFormat,
  checkCoinName
} from "../../util";

const { Option } = Select;
class TradeLog extends Component {
  state = {
    tradeList: [],
    pagination: {
      current: 1,
      total: 0
    },
    tradeType: "ALL",
    searchText: "",
    coinType: 0,

    logCoinList: []
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getTradeList({
      pageNum: pager.current
    });
  };

  handleChangeTradeType = (value) => {
    const coinList = reactLocalStorage.getObject("coinList");
    let stableFlag = false;
    if (value === "BEFORE") stableFlag = null;
    else if (value === "AFTER") stableFlag = 1;
    else stableFlag = 0;

    if (stableFlag !== 0) {
      this.setState({
        tradeType: value,
        logCoinList: coinList.filter((coin) => coin.stableFlag === stableFlag)
      });
    } else {
      this.setState({
        tradeType: value,
        searchText: "",
        coinType: 0
      });
    }
  };

  // 코인 종류 선택
  handleChangeCoinType = (value) => {
    this.setState({
      coinType: value
    });
  };

  // 검색
  onSearch = () => {
    if (this.state.tradeType !== "ALL" && this.state.coinType === 0) {
      alert("코인 종류를 선택해주세요.");
    } else {
      this.getTradeList();
    }
  };

  // 교환관리 목록 조회
  getTradeList = (params = {}) => {
    let pageNum = params.pageNum || 1;
    httpGet(
      httpUrl.getTradeLog,
      [
        10,
        pageNum,
        this.state.tradeType,
        this.state.searchText,
        this.state.coinType
      ],
      {}
    )
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          tradeList: res.data.logList,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    const coinList = reactLocalStorage.getObject("coinList");
    this.getTradeList();
    this.setState({
      logCoinList: [
        {
          category: "string",
          coinType: 0,
          decimal: 0,
          name: "전체",
          stableFlag: null
        },
        ...coinList
      ]
    });
  }

  render() {
    const { tradeList, tradeType, logCoinList, coinType } = this.state;

    const walletColums = [
      {
        title: "순번",
        dataIndex: "idx",
        key: "idx",
        width: 80,
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        // width: 80,
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "코인 종류(전)",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "지갑 주소(전)",
        dataIndex: "address",
        className: "text-center"
      },
      {
        title: "코인 종류(후)",
        dataIndex: "toCoinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "지갑 주소(후)",
        dataIndex: "toAddress",
        className: "text-center"
      },
      {
        title: "수수료",
        dataIndex: "fee",
        className: "text-center",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      },

      {
        title: "수량(전)",
        dataIndex: "amount",
        className: "text-center",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      },
      {
        title: "수량(후)",
        dataIndex: "ratio",
        className: "text-center",
        render: (data, row) => (
          <div>
            {amountFormat(
              coinDivideFormat(row.amount, row.coinType) * row.ratio
            )}
          </div>
        )
      }
    ];

    return (
      <div>
        <div className="title">교환 관리</div>
        <div className="flex-row flex-center flex-end m-b-10">
          <label>
            교환 종류&nbsp;
            <SelectBox
              value={string.tradeLogString[tradeType]}
              code={string.tradeLogCode}
              codeString={string.tradeLogString}
              onChange={(value) => {
                if (value !== tradeType) {
                  this.handleChangeTradeType(value);
                }
              }}
              style={{ width: "160px", marginRight: "10px" }}
            />
          </label>
          <label>
            코인 종류&nbsp;
            <Select
              disabled={tradeType === "ALL" ? true : false}
              style={{ width: "180px", marginRight: "10px" }}
              placeholder="코인을 선택해주세요."
              value={
                logCoinList.find((coin) => coin.coinType === coinType) &&
                logCoinList.find((coin) => coin.coinType === coinType).name
              }
              defaultValue={
                logCoinList.length !== 0 ? logCoinList[0].name : undefined
              }
              onChange={this.handleChangeCoinType}>
              {logCoinList.map((coin) => (
                <Option key={`${coin.coinType}`} value={coin.coinType}>
                  {coin.name}
                </Option>
              ))}
            </Select>
          </label>
          <div className="flex-row flex-center">
            <label>검색어&nbsp;</label>
            <Input
              disabled={coinType === 0 ? true : false}
              value={this.state.searchText}
              onChange={(e) => {
                this.setState({
                  searchText: e.target.value
                });
              }}
              placeholder="주소를 입력하세요"
              allowClear
              style={{ width: "300px", marginRight: "10px" }}
            />
          </div>
          <Button type="primary" onClick={this.onSearch}>
            조회
          </Button>
        </div>
        <Table
          id="member-log-table"
          rowKey={(record) => record.idx}
          columns={walletColums}
          dataSource={tradeList}
          style={{ marginBottom: "10px" }}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default TradeLog;
