import { GET_START, GET_SUCCESS, GET_REJECTED } from '../actions/asyncFetch';

const initState = {
  loading: true,
  errorMessage: '',
  fetchData: [],
};

const fetchReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_START:
      return Object.assign({}, state, {
        ...state,
        loading: action.payload,
      });
    case GET_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        fetchData: Array.isArray(action.fetchData)
          ? { data: action.fetchData }
          : action.fetchData,
        loading: action.loading,
      });
    case GET_REJECTED:
      return Object.assign({}, state, {
        ...state,
        errorMessage: action.payload,
        loading: action.loading,
      });
    default:
      return state;
  }
};

export default fetchReducer;
