import React, { Component } from "react";
import { Table, Input, Button } from "antd";
import { httpGet, httpUrl, httpPut } from "../../api/httpClient";
import CategoryModal from "../../components/payment/CategoryModal";
import { showAlert } from "../../components/common/AlertModal";

const Search = Input.Search;
class Category extends Component {
  state = {
    list: [],
    searchText: "",

    type: false,
    visible: false,
    detailData: {}
  };

  // 검색
  onSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.getCategoryList();
      }
    );
  };

  // 삭제
  onDelete = (row) => {
    if (global.confirm("카테고리를 삭제하시겠습니까?"))
      httpPut(httpUrl.categoryDelete, [row.idx], {})
        .then((res) => {
          showAlert("delete");
          this.getCategoryList();
        })
        .catch((e) => {});
  };

  // 카테고리 목록 조회
  getCategoryList = (params = {}) => {
    httpGet(httpUrl.getCategoryList, [0, this.state.searchText], {})
      .then((res) => {
        this.setState({
          list: res.data.list,
          total: res.data.total
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getCategoryList();
  }

  render() {
    const { list, visible, type, detailData, total } = this.state;

    const columns = [
      {
        key: "name",
        title: "카테고리",
        dataIndex: "name",
        className: "text-center"
      },
      {
        title: "색상",
        dataIndex: "color",
        className: "text-center"
      },
      {
        title: "랭크",
        dataIndex: "rank",
        className: "text-center"
      },
      {
        title: "",
        dataIndex: "under",
        className: "text-center",
        render: (data, row) => (
          <div>
            <Button
              onClick={() => {
                this.setState({ visible: true, type: true, detailData: row });
              }}>
              수정
            </Button>
            <Button
              className="m-l-10"
              onClick={() => {
                this.onDelete(row);
              }}>
              삭제
            </Button>
          </div>
        )
      }
    ];

    return (
      <div>
        {/* 카테고리 생성 / 수정 */}
        {visible && (
          <CategoryModal
            type={type}
            visible={visible}
            detail={detailData}
            total={total}
            onCancel={() => {
              this.setState({
                visible: false,
                detailData: {}
              });
            }}
            onOk={this.getCategoryList}
          />
        )}
        <div className="title">카테고리 관리</div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <Button
            onClick={() => {
              this.setState({ visible: true, type: false });
            }}>
            생성
          </Button>
          <Search
            placeholder="카테고리를 입력해주세요."
            enterButton="검색"
            allowClear
            onSearch={this.onSearch}
            style={{ width: 400 }}
          />
        </div>
        <Table
          rowKey={(record) => record.name}
          columns={columns}
          dataSource={list}
          style={{ marginBottom: "10px" }}
          pagination={false}
        />
      </div>
    );
  }
}

export default Category;
