import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import {
  Button,
  DatePicker,
  Form,
  Icon,
  Input,
  InputNumber,
  Modal,
  Select,
  Upload
} from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from "moment";

import { httpPost, httpUrl, serverUrl } from "../../api/httpClient";
import { coinMultiFormat, formatDateSecond } from "../../util";
import UserSearch from "./UserSearch";
import { showAlert } from "../common/AlertModal";

const FormItem = Form.Item;
const Option = Select.Option;
const LockModal = Form.create()(
  class extends Component {
    state = {
      coinList: [],
      coinType: 0,
      validateAddress: false,
      userVisible: false
    };

    // 코인 종류 선택
    handleChangeCoinType = (value) => {
      this.setState({
        coinType: value
      });
    };

    // 마감일 제한
    disabledDate = (current) => {
      // Can not select days before today and today
      return current < moment().endOf("day" - 1);
    };

    // 생성
    onSumbit = (e) => {
      const { form } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
        if (!err)
          if (global.confirm("생성하시겠습니까?")) {
            let body = {
              ...values,
              amount: coinMultiFormat(values.amount, values.coinType),
              releaseTime: formatDateSecond(values.releaseTime)
            };
            // console.log(`body = ${JSON.stringify(body, null, 4)}`);
            httpPost(httpUrl.lockSet, [], body)
              .then((res) => {
                showAlert("create");
                if (this.props.searchText !== "") this.props.lockRefresh();
                this.props.requestRefresh();
                this.props.onCancel();
              })
              .catch((e) => {});
          }
      });
    };

    componentDidMount() {
      let coinList = reactLocalStorage.getObject("coinList");
      let arr = [];
      coinList.forEach((coin) => {
        if (coin.category === "KCT") arr.push({ ...coin });
      });

      this.setState({ coinList: arr });
    }

    render() {
      const { coinList, coinType, userVisible } = this.state;
      const {
        form,
        visible,
        onCancel,
        searchText,
        lockRefresh,
        requestRefresh
      } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 8 },
          sm: { span: 8 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };
      const uploadProps = {
        action: serverUrl + httpUrl.lockSetExcel,
        multiple: false,
        withCredentials: true,
        beforeUpload: (file) => {
          if (global.confirm("LOCK을 생성하시겠습니까?")) {
            return true;
          } else {
            return false;
          }
        },
        onSuccess: (file) => {
          // console.log(`file = ${JSON.stringify(file, null, 4)}`);
          var success = file.data.success;
          var fail = file.data.fail;
          var total = success + fail;

          var s =
            "총 : " + total + ", 성공 : " + success + ", 실패 : " + fail + "\n";
          Modal.info({
            title: "LOCK 결과",
            content: (
              <div>
                <p>
                  {file.data.failAddresses.length > 0 ? (
                    <div>
                      <div>{`${s}`}</div>
                      <br />
                      {`- 실패한 LOCK\n`}
                      {file.data.failAddresses.length > 0 &&
                        file.data.failAddresses.map((value) => (
                          <div>{value}</div>
                        ))}
                    </div>
                  ) : (
                    <div>{s}</div>
                  )}
                </p>
              </div>
            ),
            onOk() {
              if (searchText !== "") lockRefresh();
              requestRefresh();
              onCancel();
            },
            okText: "확인"
          });
        },
        onError(err) {
          Modal.error({
            title: "LOCK 결과",
            content: "파일 업로드 실패"
          });
        }
      };

      return (
        <Modal
          visible={visible}
          title="LOCK 생성"
          okText="생성"
          cancelText="취소"
          onOk={this.onSumbit}
          onCancel={onCancel}
          style={{ minWidth: "800px" }}>
          {userVisible && (
            <UserSearch
              visible={userVisible}
              coinType={coinType}
              onCancel={() => this.setState({ userVisible: false })}
              onOk={(value) => {
                this.setState({ address: value });
                form.setFieldsValue({ address: value });
              }}
            />
          )}
          <Form {...formItemLayout}>
            <div className="flex-row flex-center justify-center m-b-10">
              <a href="/setLockExcelFomat.xlsx" download>
                <Button className="m-r-10">
                  <Icon type="download" style={{ fontSize: "20px" }} />
                  양식 다운로드
                </Button>
              </a>
              <Upload
                {...uploadProps}
                accept=".xlsx, .xls"
                showUploadList={false}>
                <Button className="m-r-10">
                  <Icon type="upload" style={{ fontSize: "20px" }} />
                  엑셀 업로드
                </Button>
              </Upload>
            </div>
            <div
              className="flex-row flex-center justify-center"
              style={{ marginBottom: "20px" }}>
              <span style={{ color: "blue", marginLeft: "10px" }}>
                * 여러 사람에게 락 생성 시 파일업로드 사용
              </span>
            </div>
            {Array.isArray(coinList) && (
              <FormItem label={<span>코인종류&nbsp;</span>}>
                {getFieldDecorator("coinType", {
                  rules: [
                    {
                      required: true,
                      message: "코인종류를 선택해주세요!"
                    }
                  ]
                })(
                  <Select
                    style={{ width: "160px" }}
                    value={coinType !== 0 && coinType}
                    placeholder="선택해주세요"
                    onChange={this.handleChangeCoinType}>
                    {coinList.map((coin) => (
                      <Option key={coin.coinType} value={coin.coinType}>
                        {coin.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            )}
            <FormItem label={<span>주소&nbsp;</span>}>
              {getFieldDecorator("address", {
                rules: [
                  {
                    required: true,
                    message: "주소를 입력해주세요!"
                  }
                ]
              })(
                <Input
                  allowClear
                  style={{ width: "360px" }}
                  placeholder="주소를 입력해주세요"
                />
              )}
            </FormItem>
            <FormItem label={<span></span>} colon={false}>
              <Button
                onClick={() => {
                  if (coinType !== 0) {
                    this.setState({ userVisible: true });
                  } else if (coinType === 0) alert("코인을 선택해주세요.");
                }}>
                회원검색
              </Button>
            </FormItem>
            <FormItem label={<span>해제일&nbsp;</span>}>
              {getFieldDecorator("releaseTime", {
                rules: [
                  {
                    required: true,
                    message: "해제일을 선택해주세요!"
                  }
                ]
              })(
                <DatePicker
                  placeholder="해제일을 선택해주세요."
                  disabledDate={this.disabledDate}
                  locale={locale}
                />
              )}
            </FormItem>
            <FormItem label={<span>수량&nbsp;</span>}>
              {getFieldDecorator("amount", {
                rules: [
                  {
                    required: true,
                    message: "수량을 입력해주세요!"
                  },
                  {
                    validator: (rule, value, cb) => {
                      if (
                        value &&
                        Array.isArray(coinList.coinType) &&
                        coinType === 0
                      ) {
                        cb("코인 종류를 선택해주세요.");
                      }
                      cb();
                    }
                  }
                ]
              })(<InputNumber allowClear style={{ width: "160px" }} />)}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

export default LockModal;
