import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Modal, Descriptions, Input, InputNumber, Switch, Icon } from "antd";
import string from "../../string";
import {
  formatDate,
  coinDivideFormat,
  coinMultiFormat,
  priceDivideFormat,
  priceMultiFormat
} from "../../util";
import { httpPut, httpUrl } from "../../api/httpClient";
import SelectBox from "./../common/SelectBox";

const Ditems = Descriptions.Item;

class WalletDetail extends Component {
  state = {
    detailData: {}
  };

  onChangeModal = (value, type) => {
    var data = this.state.detailData;
    data[type] = value;
    this.setState({
      detailData: data
    });
  };

  onUpdate = () => {
    const { modalData } = this.props;
    const { detailData } = this.state;

    let updateData = {
      ...detailData,
      idx: modalData.idx,
      balance:
        modalData.balance === detailData.balance
          ? detailData.balance
          : coinMultiFormat(detailData.balance, detailData.coinType),
      foundationBalance:
        modalData.foundationBalance === detailData.foundationBalance
          ? detailData.foundationBalance
          : coinMultiFormat(detailData.foundationBalance, detailData.coinType),
      price:
        modalData.price === detailData.price
          ? detailData.price
          : priceMultiFormat(detailData.price),
      stableFlag: detailData.stableFlag ? 1 : 0
    };
    // console.log(`updateData = ${JSON.stringify(updateData, null, 4)}`);
    if (global.confirm(`변경하시겠습니까?`)) {
      httpPut(httpUrl.foundationUpdate, [], updateData)
        .then((result) => {
          this.props.onOk();
        })
        .catch((error) => {});
    }
  };

  componentDidMount() {
    const { modalData } = this.props;
    // console.log(JSON.stringify(modalData, null, 4));
    let data = {
      ...modalData,
      stableFlag: modalData.stableFlag === 1 ? true : false,
      price: priceDivideFormat(modalData.price)
    };
    this.setState({
      detailData: data
    });
  }

  render() {
    const { detailData } = this.state;
    const { visible, onCancel } = this.props;
    const coinList = reactLocalStorage.getObject("coinList");

    let coinName = "";
    coinList.forEach((coin) => {
      if (coin.coinType === detailData.coinType) {
        coinName = coin.name;
      }
    });

    return (
      <Modal
        visible={visible}
        title={"재단"}
        okText="수정"
        cancelText="취소"
        onOk={this.onUpdate}
        onCancel={onCancel}
        style={{ minWidth: "1000px" }}>
        {/* <div>{JSON.stringify(detailData)}</div> */}
        <Descriptions
          bordered
          column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}>
          <Ditems label="작성일">{formatDate(detailData.createDate)}</Ditems>
          <Ditems label="설정 가격">
            <SelectBox
              type="usePrice"
              style={{ width: "160px" }}
              value={string.activeHandlesCodes[detailData.usePrice]}
              code={string.toggleCode}
              codeString={string.activeHandlesCodes}
              onChange={this.onChangeModal}
            />
          </Ditems>
          <Ditems label="코인 종류">{coinName}</Ditems>
          <Ditems label="국가">
            <Input
              allowClear
              style={{ width: "200px" }}
              value={detailData.country}
              onChange={(e) => {
                this.onChangeModal(e.target.value, "country");
              }}
            />
          </Ditems>
          <Ditems label="시가총액">
            <InputNumber
              value={detailData.marketCap}
              style={{ width: "200px" }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              onChange={(value) => {
                this.onChangeModal(value, "marketCap");
              }}
            />
          </Ditems>
          <Ditems label="금액">
            <InputNumber
              value={detailData.price}
              style={{ width: "200px" }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              onChange={(value) => {
                this.onChangeModal(value, "price");
              }}
            />
          </Ditems>
          <Ditems label="플랫 잔액">
            {coinDivideFormat(detailData.balance, detailData.coinType)}
          </Ditems>
          <Ditems label="재단 코인 잔액">
            {coinDivideFormat(
              detailData.foundationBalance,
              detailData.coinType
            )}
          </Ditems>
          <Ditems label="스테이블 코인여부" span={2}>
            <Switch
              checkedChildren={<Icon type="check" />}
              unCheckedChildren={<Icon type="close" />}
              checked={detailData.stableFlag}
              onChange={(checked) => {
                this.onChangeModal(checked, "stableFlag");
              }}
            />
          </Ditems>
          <Ditems label="웹사이트 링크" span={2}>
            <Input
              allowClear
              value={detailData.websiteLink}
              onChange={(e) => {
                this.onChangeModal(e.target.value, "websiteLink");
              }}
            />
          </Ditems>
          <Ditems label="커뮤니티 링크" span={2}>
            <Input
              allowClear
              value={detailData.communityLink}
              onChange={(e) => {
                this.onChangeModal(e.target.value, "communityLink");
              }}
            />
          </Ditems>
          <Ditems label="백서" span={2}>
            <Input
              allowClear
              value={detailData.whitepaperLink}
              onChange={(e) => {
                this.onChangeModal(e.target.value, "whitepaperLink");
              }}
            />
          </Ditems>
          <Ditems label="설명" span={2}>
            <Input.TextArea
              style={{ minHeight: "200px" }}
              value={detailData.description}
              onChange={(e) => {
                this.onChangeModal(e.target.value, "description");
              }}
            />
          </Ditems>
        </Descriptions>
      </Modal>
    );
  }
}

export default WalletDetail;
