import React, { Component } from "react";

import { Modal, Form, Input, Upload, Button, Switch, Icon } from "antd";

import {
  httpPost,
  httpPut,
  httpUrl,
  imageType,
  serverUrl
} from "../../api/httpClient";

import BasicImage from "../common/BasicImage";
import { showAlert } from "../common/AlertModal";
import { ShowErrorAlert } from "../common/ErrorAlertModal";

const FormItem = Form.Item;
const ExchageModal = Form.create()(
  class extends Component {
    state = {
      iamge: "",
      flag: false
    };

    // 생성
    onCreateSubmit = (e) => {
      const { image } = this.state;
      const { form } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
        // console.log(`create values = ${JSON.stringify(values, null, 4)}`);
        if (!err) {
          let body = {
            ...values,
            image: image,
            domestic: values.domestic === false ? 1 : 0
          };
          // console.log(`body = ${JSON.stringify(body, null, 4)}`);

          if (global.confirm("거래처를 생성하시겠습니까?"))
            httpPost(httpUrl.exchangeCreate, [], body)
              .then((res) => {
                // console.log(`res = ${JSON.stringify(res, null, 4)}`);
                if (res.result === "SUCCESS") {
                  this.props.onOk({ refresh: true });
                  this.onCancel();
                  showAlert("create");
                } else {
                  ShowErrorAlert(res.data.data);
                }
              })
              .catch((e) => {});
        }
      });
    };

    // 수정
    onUpdateSubmit = (e) => {
      const { form, selectedRow } = this.props;
      const { image } = this.state;

      e.preventDefault();
      form.validateFields((err, values) => {
        // console.log(`update values = ${JSON.stringify(values, null, 4)}`);
        if (!err) {
          let body = {
            ...selectedRow,
            ...values,
            image: image,
            domestic: values.domestic === false ? 0 : 1
          };
          // console.log(`body = ${JSON.stringify(body, null, 4)}`);

          if (global.confirm("거래처 정보를 수정하시겠습니까?"))
            httpPut(httpUrl.exchangeUpdate, [], body)
              .then((res) => {
                // console.log(`res = ${JSON.stringify(res, null, 4)}`);
                if (res.result === "SUCCESS") {
                  this.props.onOk();
                  this.onCancel();
                  showAlert("update");
                }
              })
              .catch((e) => {});
        }
      });
    };

    onSubmit = (e) => {
      const { type } = this.props;

      if (type === "create") this.onCreateSubmit(e);
      else this.onUpdateSubmit(e);
    };

    onCancel = () => {
      this.props.onCancel();
      this.props.form.resetFields();
    };

    componentDidMount() {
      const { type, selectedRow, form } = this.props;
      // console.log(`props = ${JSON.stringify(this.props, null, 4)}`);

      form.setFieldsValue({
        ...selectedRow,
        domestic: false
      });

      if (type === "edit") {
        form.setFieldsValue({
          ...selectedRow,
          domestic: selectedRow.domestic === 0 ? false : true
        });

        this.setState({
          image: selectedRow.image,
          flag: selectedRow.domestic === 0 ? false : true
        });
      }
    }

    render() {
      const { visible, type, form } = this.props;
      const { getFieldDecorator } = form;
      const { image, flag } = this.state;

      const formItemLayout = {
        labelCol: {
          xs: { span: 5 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };

      const imageProps = {
        action: serverUrl + httpUrl.imageUpload,
        multiple: false,
        withCredentials: true,
        beforeUpload: (file) => {
          if (!imageType.find((type) => type === file.type)) {
            Modal.error({
              title: "업로드 결과",
              content: (
                <span>
                  파일 업로드 실패
                  <br />
                  파일 형식을 확인해주세요.
                </span>
              )
            });
            return false;
          }
        },
        onSuccess: (file) => {
          try {
            // console.log(`image data = ${JSON.stringify(file, null, 4)}`);
            form.setFieldsValue({ image: file.data.fileId });
            this.setState({ image: file.data.fileId });
            // this.onChangeModal(file.data.encodingImage, "image");
          } finally {
            alert("이미지 업로드 완료");
          }
        },
        onError(err) {
          alert("이미지 업로드 실패");
        }
      };

      return (
        <Modal
          visible={visible}
          style={{ minWidth: "800px" }}
          title={`거래처 ${type === "create" ? "생성하기" : "수정하기"}`}
          okText={type === "create" ? "생성" : "수정"}
          cancelText="취소"
          onOk={this.onSubmit}
          onCancel={this.onCancel}>
          <Form {...formItemLayout}>
            <FormItem label={<span>이미지 &nbsp;</span>}>
              {getFieldDecorator("image", {
                rules: [
                  {
                    required: true,
                    message: "이미지를 등록해주세요!"
                  }
                ]
              })(
                <div showUploadList={false}>
                  {image !== "" && image && (
                    <BasicImage
                      image={image}
                      src={serverUrl + httpUrl.getImage + image}
                      style={{
                        width: "150px",
                        height: "100px",
                        marginRight: "10px"
                      }}
                    />
                  )}
                  <Upload {...imageProps} showUploadList={false}>
                    <Button>{image ? "변경" : "선택"}</Button>
                  </Upload>
                </div>
              )}
            </FormItem>
            <FormItem label={<span>해외 거래처 여부&nbsp;</span>}>
              {getFieldDecorator("domestic", {
                rules: [
                  {
                    required: true,
                    message: "해외 거래처 여부를 선택해주세요!"
                  }
                ]
              })(
                <Switch
                  checked={flag}
                  checkedChildren={<Icon type="check" />}
                  unCheckedChildren={<Icon type="close" />}
                  onChange={(checked) => {
                    this.setState({ flag: checked });
                  }}
                />
              )}
            </FormItem>
            <FormItem label={<span>URL</span>}>
              {getFieldDecorator("url", {
                rules: [
                  {
                    required: true,
                    message: "url을 입력해주세요!"
                  }
                ]
              })(
                <Input.TextArea
                  rows={4}
                  placeholder="url를 입력해주세요."
                  maxLength={100}
                />
              )}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);
export default ExchageModal;
