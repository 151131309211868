import React, { Component } from "react";

import { Modal, Form, Input, Upload, Button, Switch, Icon } from "antd";

import { httpPost, httpPut, httpUrl, serverUrl } from "../../api/httpClient";

import BasicImage from "../common/BasicImage";
import { showAlert } from "../common/AlertModal";
import { ShowErrorAlert } from "../common/ErrorAlertModal";
import Axios from "axios";

const FormItem = Form.Item;
const EventModal = Form.create()(
  class extends Component {
    state = {
      iamge: "",
      data: {
        flag: false,
        name: global.lanList.map((lan) => {
          return {
            content: "",
            language: lan
          };
        }),
        content: global.lanList.map((lan) => {
          return {
            content: "",
            language: lan
          };
        })
      },
      preview: "",
      changeImg: true
    };

    onChangeModal = (value, type, language) => {
      const data = Object.assign({}, this.state.data);
      let arr = data[type];

      if (type === "name") {
        arr = arr.map((item) => {
          if (item.language === language) {
            return {
              content: value,
              language: language
            };
          } else {
            return item;
          }
        });
        data.name = arr;
      } else if (type === "content") {
        arr = arr.map((item) => {
          if (item.language === language) {
            return {
              content: value,
              language: language
            };
          } else {
            return item;
          }
        });
        data.content = arr;
      } else data[type] = value;
      // console.log(JSON.stringify(data, null, 4));

      this.setState({ data });
    };

    // 생성
    onCreateSubmit = (e) => {
      const { image, data } = this.state;
      const { form } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
        // console.log(`create values = ${JSON.stringify(values, null, 4)}`);
        if (!err) {
          let body = {
            ...values,
            image: image,
            name: data.name,
            content: data.content,
            categoryIdx: values.online === false ? 0 : 1
          };
          // console.log(`body = ${JSON.stringify(body, null, 4)}`);

          if (global.confirm("사용처를 생성하시겠습니까?"))
            httpPost(httpUrl.urlLinkCreate, [], body)
              .then((res) => {
                // console.log(`res = ${JSON.stringify(res, null, 4)}`);
                if (res.result === "SUCCESS") {
                  this.props.onOk({ refresh: true });
                  this.onCancel();
                  showAlert("create");
                } else {
                  ShowErrorAlert(res.data.data);
                }
              })
              .catch((e) => {});
        }
      });
    };

    // 수정
    onUpdateSubmit = (e) => {
      const { form, selectedRow } = this.props;
      const { image, data } = this.state;

      e.preventDefault();
      form.validateFields((err, values) => {
        // console.log(`update values = ${JSON.stringify(values, null, 4)}`);
        if (!err) {
          let body = {
            ...selectedRow,
            ...values,
            image: image,
            name: data.name,
            content: data.content,
            categoryIdx: values.online === false ? 0 : 1
          };
          // console.log(`body = ${JSON.stringify(body, null, 4)}`);

          if (global.confirm("사용처 정보를 수정하시겠습니까?"))
            httpPut(httpUrl.urlLinkUpdate, [], body)
              .then((res) => {
                // console.log(`res = ${JSON.stringify(res, null, 4)}`);
                if (res.result === "SUCCESS") {
                  this.props.onOk();
                  this.onCancel();
                  showAlert("update");
                }
              })
              .catch((e) => {});
        }
      });
    };

    onSubmit = (e) => {
      const { type } = this.props;

      if (type === "create") this.onCreateSubmit(e);
      else this.onUpdateSubmit(e);
    };

    onCancel = () => {
      this.props.onCancel();
      this.props.form.resetFields();
    };

    componentDidMount() {
      const { type, selectedRow, form } = this.props;
      // console.log(`props = ${JSON.stringify(this.props, null, 4)}`);

      form.setFieldsValue({
        online: false
      });

      if (type === "edit") {
        let nameData = global.lanList.map((lan, index) => {
          if (selectedRow.name.find((item) => item.language === lan)) {
            return selectedRow.name.find((item) => item.language === lan);
          } else {
            return {
              content: "",
              language: lan
            };
          }
        });
        let contentData = global.lanList.map((lan, index) => {
          if (selectedRow.content.find((item) => item.language === lan)) {
            return selectedRow.content.find((item) => item.language === lan);
          } else {
            return {
              content: "",
              language: lan
            };
          }
        });

        form.setFieldsValue({
          ...selectedRow,
          name: nameData.map((item) => {
            return item.content;
          }),
          content: contentData.map((item) => {
            return item.content;
          })
        });

        this.setState({
          data: {
            name: nameData,
            content: contentData,
            flag: selectedRow.categoryIdx === 0 ? false : true
          },
          image: selectedRow.image,
          changeImg: false
        });
      }
    }

    render() {
      const { visible, type, form } = this.props;
      const { getFieldDecorator } = form;
      const { changeImg, preview, image, data } = this.state;

      const formItemLayout = {
        labelCol: {
          xs: { span: 5 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };

      const imageProps = {
        // action: serverUrl + httpUrl.imageUpload,
        // multiple: false,
        // withCredentials: true,
        // beforeUpload: (file) => {
        //   if (!imageType.find((type) => type === file.type)) {
        //     Modal.error({
        //       title: "업로드 결과",
        //       content: (
        //         <span>
        //           파일 업로드 실패
        //           <br />
        //           파일 형식을 확인해주세요.
        //         </span>
        //       )
        //     });
        //     return false;
        //   }
        // },
        // onSuccess: (file) => {
        //   try {
        //     // console.log(`image data = ${JSON.stringify(file, null, 4)}`);
        //     form.setFieldsValue({ image: file.data.fileId });
        //     this.setState({ image: file.data.fileId });
        //     // this.onChangeModal(file.data.encodingImage, "image");
        //   } finally {
        //     alert("이미지 업로드 완료");
        //   }
        // },
        // onError(err) {
        //   alert("이미지 업로드 실패");
        // }
        withCredentials: true,
        showUploadList: false,
        multiple: false,
        transformFile: async (file) => {
          let newImages = [];
          newImages = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              const canvas = document.createElement("canvas");
              const img = document.createElement("img");
              img.src = reader.result;
              this.setState({
                changeImg: true,
                preview: reader.result
              });
              img.onload = () => {
                let image = [];
                const ctx = canvas.getContext("2d");
                canvas.width = 120;
                canvas.height = 100;
                ctx.drawImage(img, 0, 0, 120, 100);
                canvas.toBlob(
                  (blob) => {
                    // console.log(`file = ${JSON.stringify(file, null, 4)}`);
                    image.push(new File([blob], `120x100${file.name}`));
                    resolve(image);
                  },
                  "image/png",
                  0.5
                );
              };
            };
          });
          await new Promise((resolve) => {
            // console.log(`resolve = ${JSON.stringify(resolve, null, 4)}`);
            const formData = new FormData();
            formData.append("file", newImages[0]);
            Axios({
              method: "POST",
              url: serverUrl + httpUrl.imageUpload,
              data: formData,
              withCredentials: true
            })
              .then((res) => {
                // console.log(`res = ${JSON.stringify(res, null, 4)}`);
                if (res.data.data.result) {
                  form.setFieldsValue({ image: res.data.data.fileId });
                  this.setState({
                    image: res.data.data.fileId
                  });
                }
              })
              .catch((e) => {});
          });
        }
      };

      return (
        <Modal
          visible={visible}
          style={{ minWidth: "800px" }}
          title={`사용처 ${type === "create" ? "생성하기" : "수정하기"}`}
          okText={type === "create" ? "생성" : "수정"}
          cancelText="취소"
          onOk={this.onSubmit}
          onCancel={this.onCancel}>
          <Form {...formItemLayout}>
            <FormItem label={<span>이미지 &nbsp;</span>}>
              {getFieldDecorator("image", {
                rules: [
                  {
                    required: true,
                    message: "이미지를 등록해주세요!"
                  }
                ]
              })(
                <div showUploadList={false}>
                  {(preview !== "" || image !== "") && (
                    <BasicImage
                      image={changeImg ? preview : image}
                      src={
                        changeImg
                          ? preview
                          : serverUrl + httpUrl.getImage + image
                      }
                      style={{
                        width: "120px",
                        height: "100px",
                        marginRight: "10px"
                      }}
                    />
                  )}
                  <Upload {...imageProps} showUploadList={false}>
                    <Button>{image ? "변경" : "선택"}</Button>
                  </Upload>
                </div>
              )}
            </FormItem>
            {/* 다국어 적용 */}
            {global.lanList.map((lan, index) => (
              <div key={index}>
                <FormItem
                  key={`name${lan}${index}`}
                  label={<span>사용처 이름({lan})&nbsp;</span>}>
                  {getFieldDecorator(`name[${index}]`, {
                    validateTrigger: ["onChange", "onBlur"],
                    rules: [
                      {
                        required: lan === "ko" ? true : false,
                        message: "사용처을 입력해주세요!"
                      }
                    ]
                  })(
                    <Input
                      allowClear
                      maxLength={100}
                      onChange={(e) => {
                        this.onChangeModal(e.target.value, "name", lan);
                      }}
                    />
                  )}
                </FormItem>
                <FormItem
                  key={`content${lan}${index}`}
                  label={<span>상세 설명({lan})&nbsp;</span>}>
                  {getFieldDecorator(`content[${index}]`, {
                    validateTrigger: ["onChange", "onBlur"],
                    rules: [
                      {
                        required: lan === "ko" ? true : false,
                        message: "내용을 입력해주세요!"
                      }
                    ]
                  })(
                    <Input.TextArea
                      maxLength={100}
                      onChange={(e) => {
                        this.onChangeModal(e.target.value, "content", lan);
                      }}
                    />
                  )}
                </FormItem>
              </div>
            ))}
            <FormItem label={<span>온라인 설정&nbsp;</span>}>
              {getFieldDecorator("online", {
                rules: [
                  {
                    required: true,
                    message: "온라인 설정 선택해주세요!"
                  }
                ]
              })(
                <Switch
                  checked={data.flag}
                  checkedChildren={<Icon type="check" />}
                  unCheckedChildren={<Icon type="close" />}
                  onChange={(checked) => {
                    this.onChangeModal(checked, "flag");
                  }}
                />
              )}
            </FormItem>
            <FormItem label={<span>URL</span>}>
              {getFieldDecorator("url", {
                rules: [
                  {
                    required: true,
                    message: "url을 입력해주세요!"
                  }
                ]
              })(
                <Input
                  rows={4}
                  placeholder="url를 입력해주세요."
                  maxLength={100}
                />
              )}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

export default EventModal;
