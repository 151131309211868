import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Spin } from "antd";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import App from "./App.js";

import rootReducer from "./reducers";

const store = createStore(rootReducer, applyMiddleware(thunk));

const Root = () => (
  <BrowserRouter>
    <Provider store={store}>
      <App />
      <div
        id="loadingSpinner"
        style={{
          display: "none",
          position: "fixed",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.3)",
          top: 0,
          left: 0,
          textAlign: "center",
          zIndex: 99999
          // pointerEvents: "none"
        }}>
        <Spin style={{ position: "absolute", top: "50%", zIndex: 99999 }} />
      </div>
    </Provider>
  </BrowserRouter>
);

export default Root;
