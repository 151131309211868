import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { Form, Modal, Select, Input, InputNumber } from "antd";

import string from "../../string";
import SelectBox from "../common/SelectBox";
import { coinMultiFormat, priceMultiFormat, numberFormat } from "../../util";
import { httpPost, httpUrl } from "../../api/httpClient";

const FormItem = Form.Item;
const Option = Select.Option;

const WalletLinkusRegistryModal = Form.create()(
  class extends Component {
    state = {
      registryData: {}
    };

    onSelect = (value, type) => {
      var data = this.state.registryData;
      data[type] = value;
      this.setState({
        registryData: data
      });
      // console.log(JSON.stringify(data));
    };

    onRegistry = () => {
      const { registryData } = this.state;

      this.props.form.validateFields((err, values) => {
        if (!err) {
          if (global.confirm(`생성하시겠습니까?`)) {
            let createData = {
              coinType: registryData.coinType,
              price: priceMultiFormat(registryData.price) || 0,
              usePrice: registryData.usePrice || 0,
              country: registryData.country,
              marketCap: registryData.marketCap || 0,
              foundationBalance: 0,
              balance: 0,
              stableFlag: 0
            };
            // console.log(JSON.stringify(createData, null, 4));
            httpPost(httpUrl.foundationCreate, [], createData)
              .then((result) => {
                if (result.data.result === "SUCCESS") {
                  this.props.onOk();
                  this.props.form.resetFields();
                } else {
                  alert(
                    "이미 등록되었거나 한번 등록되었다가 삭제된 지갑주소입니다.\n 삭제된 경우 해당 지갑주소를 검색하여 다시 활성화 시킬 수 있습니다."
                  );
                }
              })
              .catch((error) => {});
          }
        }
      });
    };

    onCancel = () => {
      this.setState({ registryData: {} });
      this.props.form.resetFields();
      this.props.onCancel();
    };

    render() {
      const { registryData } = this.state;
      const { visible, form } = this.props;
      const { getFieldDecorator } = form;
      let coinList = reactLocalStorage.getObject("coinList");

      const formItemLayout = {
        labelCol: {
          xs: { span: 8 },
          sm: { span: 8 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };

      return (
        <Modal
          visible={visible}
          title="재단"
          okText="생성"
          cancelText="취소"
          onOk={this.onRegistry}
          onCancel={this.onCancel}
          style={{ minWidth: "600px" }}>
          <div className="walletRegistryModal-wrapper">
            <Form {...formItemLayout} onSubmit={this.onRegistry}>
              <FormItem label={<span>코인 종류&nbsp;</span>}>
                {getFieldDecorator("coinType", {
                  rules: [
                    {
                      required: true,
                      message: "코인 종류를 선택해주세요!"
                    }
                  ]
                })(
                  <Select
                    style={{ width: "200px" }}
                    placeholder="선택해주세요."
                    onChange={(value) => {
                      this.onSelect(value, "coinType");
                    }}>
                    {coinList.map((coin) => {
                      if (coin.stableFlag === null) {
                        return (
                          <Option
                            key={`${coin.coinType}`}
                            value={coin.coinType}>
                            {coin.name}
                          </Option>
                        );
                      } else return null;
                    })}
                  </Select>
                )}
              </FormItem>
              <FormItem label={<span>시가 총액&nbsp;</span>}>
                {getFieldDecorator("marketCap", {
                  rules: [
                    {
                      required: false
                    },
                    {
                      validator: (rule, value, cb) => {
                        if (!registryData.coinType) {
                          cb("코인을 선택해주세요.");
                        } else {
                          if (
                            value &&
                            numberFormat(parseInt(value)) >= 9223372036854775807
                          ) {
                            cb("입력하신 금액이 너무 큽니다.");
                          }
                        }
                        cb();
                      }
                    }
                  ]
                })(
                  <InputNumber
                    min={0}
                    style={{ width: "200px" }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    onChange={(value) => {
                      this.onSelect(value, "marketCap");
                    }}
                  />
                )}
              </FormItem>
              <FormItem label={<span>금액&nbsp;</span>}>
                {getFieldDecorator("price", {
                  rules: [
                    {
                      required: false
                    },
                    {
                      validator: (rule, value, cb) => {
                        if (!registryData.coinType) {
                          cb("코인을 선택해주세요.");
                        } else {
                          if (
                            value &&
                            coinMultiFormat(
                              parseInt(value),
                              registryData.coinType
                            ) >= 9223372036854775807
                          ) {
                            cb("입력하신 금액이 너무 큽니다.");
                          }
                        }
                        cb();
                      }
                    }
                  ]
                })(
                  <InputNumber
                    // min={0}
                    // max={9223372036}
                    style={{ width: "200px" }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    onChange={(value) => {
                      this.onSelect(value, "price");
                    }}
                  />
                )}
              </FormItem>
              <FormItem label={<span>가격설정&nbsp;</span>}>
                <SelectBox
                  type="usePrice"
                  value={
                    this.state.registryData.usePrice ||
                    string.activeHandlesCodes[0]
                  }
                  style={{ width: "200px" }}
                  code={string.toggleCode}
                  codeString={string.activeHandlesCodes}
                  onChange={this.onSelect}
                />
                <div
                  style={{
                    color: "red",
                    marginTop: -10,
                    marginBottom: -20
                  }}>
                  입력하신 가격으로 사용하실려면 활성화를 선택해주세요.
                </div>
              </FormItem>
              <FormItem label={<span>국가&nbsp;</span>}>
                {getFieldDecorator("country", {
                  rules: [
                    {
                      required: true,
                      message: "국가를 입력해주세요!"
                    }
                  ]
                })(
                  <Input
                    allowClear
                    style={{ width: "200px" }}
                    placeholder="KOREA"
                    onChange={(e) => {
                      this.onSelect(e.target.value, "country");
                    }}
                  />
                )}
              </FormItem>
            </Form>
          </div>
        </Modal>
      );
    }
  }
);

export default WalletLinkusRegistryModal;
