import React, { Component } from "react";

import { Modal, Table, Button } from "antd";
import { httpGet, httpUrl, httpPut } from "../../api/httpClient";

import string from "../../string";
import { checkCoinName, numberFormat } from "../../util";
import SelectBox from "../common/SelectBox";
import PaymentSettingRegistry from "./PaymentSettingRegistry";
import { showAlert } from "../common/AlertModal";

class PaymentSetting extends Component {
  state = {
    settingList: [],
    settingVisible: false,
    selectedData: undefined
  };

  // 삭제 및 복구
  onDelete = (data) => {
    // console.log(`delete data = ${JSON.stringify(data, null, 4)}`);
    if (
      global.confirm(
        `${checkCoinName(data.coinType)} ${
          data.deleted === 0 ? "삭제" : "복구"
        }하시겠습니까?`
      )
    )
      httpPut(
        httpUrl.paymentSettingDelete,
        [data.organizationIdx, data.coinType],
        {}
      )
        .then((res) => {
          this.getPaymentSettingList();
          data.deleted === 0 ? showAlert("delete") : showAlert("restore");
        })
        .catch((error) => {});
  };

  // 조직별 결제 및 페이백 설정 목록 조회
  getPaymentSettingList = () => {
    httpGet(httpUrl.getPaymentSettingList, [this.props.organizationIdx], {})
      .then((res) => {
        let payment = res.data.map((pay, index) =>
          pay.paybackMethod === null
            ? { ...pay, paybackMethod: "NULL" }
            : { ...pay }
        );
        // console.log(`payment = ${JSON.stringify(payment, null, 4)}`);
        this.setState({ settingList: payment });
      })
      .catch((error) => {});
  };

  componentDidMount() {
    this.getPaymentSettingList();
  }

  render() {
    const { visible, onCancel } = this.props;
    const { settingVisible, settingList } = this.state;

    const columns = [
      {
        key: "coinType",
        title: "결제 코인",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "교환(전환) 코인",
        dataIndex: "payCoinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "페이백 코인",
        dataIndex: "paybackCoinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "페이백 방식",
        dataIndex: "paybackMethod",
        // width: 80,
        className: "text-center",
        render: (data) => <div>{string.paybackTypeString[data]}</div>
      },
      {
        title: "페이백 비율",
        dataIndex: "paybackRatio",
        // width: 80,
        className: "text-center",
        render: (data) => (
          <div>{data !== null ? `${numberFormat(data)} %` : "-"}</div>
        )
      },
      {
        title: "설정",
        dataIndex: "",
        className: "text-center",
        render: (data, row) => (
          <Button
            style={{ width: "80px" }}
            onClick={() => {
              this.setState({
                settingVisible: true,
                selectedData: row
              });
            }}>
            수정
          </Button>
        )
      },
      {
        title: "상태",
        dataIndex: "deleted",
        className: "text-center",
        render:
          // data => <div>{data === 0 ? '삭제' : '-'}</div>
          (data, row) => (
            <SelectBox
              value={string.deleteString[data]}
              code={string.toggleCode}
              codeString={string.deleteString}
              onChange={(value) => {
                if (parseInt(value) !== row.deleted) {
                  this.onDelete(row);
                }
              }}
            />
          )
      }
    ];

    return (
      <Modal
        visible={visible}
        title="결제 및 페이백 설정"
        onCancel={onCancel}
        footer={false}
        style={{ minWidth: "1200px" }}>
        {/* 추가 / 수정*/}
        {settingVisible && (
          <PaymentSettingRegistry
            type="edit"
            visible={settingVisible}
            modalData={this.state.selectedData}
            organizationIdx={this.props.organizationIdx}
            onCancel={() => {
              this.setState({ settingVisible: false });
            }}
            refresh={this.getPaymentSettingList}
          />
        )}
        <Button
          className="m-b-10"
          style={{ width: "80px" }}
          onClick={() => {
            this.setState({
              settingVisible: true,
              selectedData: undefined
            });
          }}>
          추가
        </Button>
        <Table
          rowKey={(record) => record.coinType}
          columns={columns}
          dataSource={settingList}
          style={{ marginBottom: "10px" }}
          pagination={false}
        />
      </Modal>
    );
  }
}
export default PaymentSetting;
