import React, { Component } from "react";

import { Table, Button, Checkbox } from "antd";

import { httpGet, httpPut, httpUrl } from "../../api/httpClient";
import { showAlert } from "../../components/common/AlertModal";
import SelectBox from "../../components/common/SelectBox";
import { formatDate, numberFormat } from "../../util";
import string from "../../string";
import UrlLinkModal from "../../components/urlLink/UrlLinkModal";

class UrlLinkList extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 0
    },
    linkType: "ALL",
    deleted: false,

    type: "create",
    visible: false,
    selectedRow: {}
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getUrlLinkList({ pageNum: pager.current });
  };

  // 삭제 포함여부 설정
  deletedHandleChange = (e) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      deleted: e.target.checked,
      isLoaded: true
    });
  };

  // 종류 선택
  linkTypeHandleChange = (value) => {
    this.setState({
      linkType: value,
      isLoaded: true
    });
  };

  // 사용처 삭제 및 복구
  onDelete = (row) => {
    const { deleted, pagination } = this.state;

    let pageNum =
      pagination.current > 1 && !deleted && row.deleted === 0
        ? this.state.list.length === 1
          ? pagination.current - 1
          : pagination.current
        : pagination.current;
    if (
      global.confirm(
        `${row.name.find((item) => item.language === "ko").content}을(를) ${
          row.deleted ? "복구" : "삭제"
        }하시겠습니까?`
      )
    ) {
      httpPut(httpUrl.urlLinkDelete, [row.idx], {})
        .then((result) => {
          if (row.deleted) showAlert("restore");
          else showAlert("delete");
          this.getUrlLinkList({ pageNum });
        })
        .catch((error) => {});
    }
  };

  getUrlLinkList = (params = {}) => {
    const { pagination, linkType, deleted } = this.state;

    let pageNum = params.pageNum || 1;
    if (params.refresh) pageNum = pagination.current;

    httpGet(httpUrl.getUrlLinkList, [10, pageNum, linkType, deleted], {})
      .then((res) => {
        // console.log(`data= ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.linkList === null ? [] : res.data.linkList,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getUrlLinkList();
  }

  render() {
    const { type, visible, selectedRow, linkType, list, pagination } =
      this.state;
    const columns = [
      {
        width: 200,
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        width: 200,
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data, row, index) => (
          <div key={`createDate${index}`}>{formatDate(data)}</div>
        )
      },
      {
        width: 300,
        title: "종류",
        dataIndex: "categoryIdx",
        className: "text-center",
        render: (data, row, index) => (
          <div key={`categoryIdx${index}`}>{string.linkString[data]}</div>
        )
      },
      {
        title: "이름",
        dataIndex: "name",
        className: "text-center",
        render: (data, row, index) => (
          <div
            style={{ cursor: "pointer", color: "blue" }}
            onClick={() => {
              this.setState({
                type: "edit",
                visible: true,
                selectedRow: row
              });
            }}>
            {data.find((item) => item.language === "ko").content}
          </div>
        )
      },
      {
        width: 300,
        title: "상태",
        dataIndex: "deleted",
        className: "text-center",
        render:
          // data => <div>{data === 0 ? '삭제' : '-'}</div>
          (data, row) => (
            <SelectBox
              value={string.deleteString[data]}
              code={string.toggleCode}
              codeString={string.deleteString}
              onChange={(value) => {
                if (value !== row.deleted) {
                  this.onDelete(row);
                }
              }}
            />
          )
      }
    ];

    return (
      <div>
        <div className="title">사용처 관리</div>
        {visible && (
          <UrlLinkModal
            type={type}
            visible={visible}
            onOk={this.getUrlLinkList}
            onCancel={() => {
              this.setState({
                visible: false
              });
            }}
            selectedRow={selectedRow}
          />
        )}
        <div className="flex-row flex-center m-b-10 flex-sb">
          <Button
            onClick={() => {
              this.setState({
                type: "create",
                visible: true,
                selectedRow: {}
              });
            }}>
            생성
          </Button>
          <div className="flex-row flex-center">
            <label htmlFor="" style={{ marginRight: "10px" }}>
              종류 선택&nbsp;&nbsp;&nbsp;
              <SelectBox
                value={string.urlLinkString[linkType]}
                code={string.urlLinkCode}
                codeString={string.urlLinkString}
                onChange={(value) => {
                  if (value !== linkType) this.linkTypeHandleChange(value);
                }}
                style={{ width: "160px", marginRight: "10px" }}
              />
            </label>
            <label htmlFor="" style={{ marginRight: "10px" }}>
              검색조건&nbsp;&nbsp;&nbsp;
              <Checkbox onChange={this.deletedHandleChange}>삭제 포함</Checkbox>
            </label>
            <Button
              onClick={() => {
                this.getUrlLinkList();
              }}>
              검색
            </Button>
          </div>
        </div>
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default UrlLinkList;
