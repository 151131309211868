import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import fileDownload from "js-file-download";

import { Table, Input, Button, DatePicker, Select } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";

import { httpDownload, httpGet, httpUrl } from "../../api/httpClient";
import {
  numberFormat,
  formatDate,
  amountFormat,
  startDateFormat,
  endDateFormat,
  excelFormat,
  checkCoinName,
  checkData
} from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";

const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;
const Option = Select.Option;
class Fee extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 0
    },
    searchText: "",
    coinType: 0,
    tradeType: "ALL",
    startDate: "",
    endDate: "",

    fullCoinList: [],
    isLoaded: false
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getFeeList({ pageNum: pager.current });
  };

  // 거래 종류 선택
  handleChangeTradeType = (value) => {
    this.setState({
      tradeType: value,
      isLoaded: true
    });
  };

  // 코인 종류 선택
  handleChangeCoinType = (value) => {
    this.setState({
      coinType: value,
      isLoaded: true
    });
  };

  // 검색기간 설정
  handleChangeDate = (date) => {
    this.setState({
      startDate: date.length !== 0 ? startDateFormat(date[0]) : "",
      endDate: date.length !== 0 ? endDateFormat(date[1]) : "",
      isLoaded: true
    });
  };

  // 검색어 입력
  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        isLoaded: false
      },
      () => {
        this.getFeeList();
      }
    );
  };

  // 엑셀 다운로드
  downloadExcel = () => {
    const {
      isLoaded,
      pagination,
      searchText,
      tradeType,
      coinType,
      startDate,
      endDate
    } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    if (isLoaded) {
      alert("조회버튼을 먼저 클릭해주세요.");
    } else {
      let total = pagination.total <= 9 ? 10 : pagination.total;
      let title = coinList.find((coin) => coin.coinType === coinType)
        ? `${
            coinList.find((coin) => coin.coinType === coinType).name
          } 수수료내역.xlsx`
        : "전체 수수료내역.xlsx";
      if (startDate && endDate) {
        title =
          `${startDate && excelFormat(startDate)} ~ ${
            endDate && excelFormat(endDate)
          }` +
          " " +
          title;
      }

      if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
        httpDownload(
          httpUrl.downLoadFeeLogList,
          [total, 1, searchText, tradeType, coinType, startDate, endDate],
          {}
        )
          .then((res) => {
            fileDownload(res, title);
          })
          .catch((e) => {
            alert("다운로드 실패");
          });
      }
    }
  };

  // 수수료 내역 조회
  getFeeList = (params = {}) => {
    const { searchText, tradeType, coinType, startDate, endDate } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.feeLogList,
      [10, pageNum, searchText, tradeType, coinType, startDate, endDate],
      {}
    )
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.feeLogList === null ? [] : res.data.feeLogList,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    const coinList = reactLocalStorage.getObject("coinList");
    this.setState({
      fullCoinList: [
        {
          coinType: 0,
          name: "전체"
        },
        ...coinList
      ]
    });
    this.getFeeList();
  }

  render() {
    const { coinType, fullCoinList, tradeType } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    const feeColums = [
      {
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        // width: 80,
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "아이디(이메일)",
        dataIndex: "id",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      },
      {
        title: "시리얼번호",
        dataIndex: "serial",
        className: "text-center"
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "수수료",
        dataIndex: "fee",
        className: "text-center",
        render: (data, row) => (
          <div>{`${amountFormat(data, row.coinType)} ${
            coinList.find((coin) => coin.coinType === row.coinType).symbol
          }`}</div>
        )
      },
      {
        title: "거래종류",
        dataIndex: "type",
        className: "text-center",
        render: (data) => <div>{string.feeLogTypeString[data]}</div>
      }
    ];

    return (
      <div>
        <div className="title">수수료 내역</div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <div className="flex-row flex-center">
            <label>코인종류&nbsp;</label>
            <Select
              placeholder="코인종류"
              style={{ width: "160px", marginRight: "10px" }}
              value={coinType}
              onChange={this.handleChangeCoinType}>
              {fullCoinList.map((coin) => (
                <Option key={coin.coinType} value={coin.coinType}>
                  {coin.name}
                </Option>
              ))}
            </Select>
            <label>거래종류&nbsp;</label>
            <SelectBox
              value={string.feeLogTypeString[tradeType]}
              codeString={string.feeLogTypeString}
              onChange={this.handleChangeTradeType}
              style={{ width: "240px" }}
            />
          </div>
          <div className="flex-row flex-center">
            <label htmlFor="">검색기준&nbsp;</label>
            <Search
              placeholder="아이디를 입력하세요"
              enterButton="검색"
              allowClear
              onSearch={this.onSearch}
              style={{ width: "300px" }}
            />
          </div>
        </div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <div>
            <label>검색기간&nbsp;</label>
            <RangePicker
              locale={locale}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
            />
          </div>
          <Button
            onClick={this.downloadExcel}
            disabled={this.state.list.length === 0 ? true : false}>
            엑셀 다운로드
          </Button>
        </div>
        <Table
          id="foundation-table"
          rowKey={(record) => record.idx}
          columns={feeColums}
          dataSource={this.state.list}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default Fee;
