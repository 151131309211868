import React, { Component } from "react";

import { Table, Button, Input, Checkbox } from "antd";
import { httpGet, httpPut, httpUrl } from "../../api/httpClient";

import string from "../../string";
import { numberFormat, formatDate } from "../../util";
import SelectBox from "../../components/common/SelectBox";
import DetailModal from "../../components/community/DetailModal";

const Search = Input.Search;
class Faq extends Component {
  state = {
    list: [],
    deleted: false,
    searchText: "",
    pagination: {
      total: 0,
      current: 0
    },

    createVisible: false,
    visible: false,
    detailData: {}
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getFaqList({ pageNum: pager.current });
  };

  // 삭제 내역 포함 여부 설정
  deletedHandleChange = (e) => {
    this.setState({
      deleted: e.target.checked
    });
  };

  // 검색
  onSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.getFaqList();
      }
    );
  };

  // 삭제
  onDelete = (value, row) => {
    const { list, deleted, pagination } = this.state;

    let pageNum =
      row.deleted === 0 && deleted === false
        ? list.length === 1
          ? pagination.current === 1
            ? pagination.current
            : pagination.current - 1
          : pagination.current
        : pagination.current;

    if (
      global.confirm(
        `${row.title.find((item) => item.language === "ko").content}을(를) ${
          row.deleted ? "복구" : "삭제"
        }하시겠습니까?`
      )
    ) {
      httpPut(httpUrl.faqDelete, [row.idx], {})
        .then((result) => {
          if (row.deleted) alert("restore");
          else alert("delete");

          this.getFaqList({ pageNum });
        })
        .catch((error) => {});
    }
  };

  getFaqList = (params = {}) => {
    const { deleted, searchText } = this.state;

    let pageNum = params.pageNum || 1;
    if (params.refresh) pageNum = this.state.pagination.current;

    httpGet(httpUrl.faqList, [10, pageNum, deleted, searchText], {})
      .then((res) => {
        // console.log(`data= ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.faqList,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getFaqList();
  }

  render() {
    const { visible, edit, detailData } = this.state;
    const faqColumns = [
      {
        width: 120,
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        width: 300,
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "제목",
        dataIndex: "title",
        className: "text-center",
        render: (data, row) => {
          return (
            <div
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() => {
                this.setState({
                  visible: true,
                  detailData: row,
                  edit: true
                });
              }}>
              {data.find((item) => item.language === "ko").content}
            </div>
          );
        }
      },
      {
        width: 300,
        title: "상태",
        dataIndex: "deleted",
        className: "text-center",
        render:
          // data => <div>{data === 0 ? '삭제' : '-'}</div>
          (data, row) => (
            <div>
              <SelectBox
                value={string.deleteString[data]}
                code={string.toggleCode}
                codeString={string.deleteString}
                onChange={(value) => {
                  if (value !== row.deleted) {
                    this.onDelete(value, row);
                  }
                }}
              />
            </div>
          )
      }
    ];

    return (
      <div>
        {/* <div>{JSON.stringify(this.props)}</div> */}
        <div className="title">FAQ 관리</div>
        <div className="wrapper mt-35 width-1240" />
        {visible && (
          <DetailModal
            edit={edit}
            type="faq"
            visible={visible}
            modalData={detailData}
            onCancel={() => {
              this.setState({ visible: false });
            }}
            onOk={this.getFaqList}
          />
        )}
        <div style={{ float: "left" }}>
          <Button
            onClick={() => {
              this.setState({ visible: true, edit: false });
            }}
            style={{ marginBottom: "10px" }}>
            생성
          </Button>
        </div>
        <div
          className="flex-row flex-center"
          style={{ marginBottom: "10px", float: "right" }}>
          <label htmlFor="">
            검색조건&nbsp;&nbsp;&nbsp;
            <Checkbox
              style={{ marginRight: "10px" }}
              onChange={this.deletedHandleChange}>
              삭제 포함
            </Checkbox>
          </label>
          <Search
            placeholder="제목, 내용을 입력하세요"
            enterButton="검색"
            allowClear
            onSearch={this.onSearch}
            style={{ width: "300px" }}
          />
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="faq-table"
          rowKey={(record) => record.idx}
          columns={faqColumns}
          dataSource={this.state.list}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default Faq;
