import React, { Component } from "react";
import { Modal, Table, Input } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";
import { numberFormat, formatDate } from "../../util";

const Search = Input.Search;
class UserListModal extends Component {
  state = {
    list: [],
    pagination: {
      current: 0,
      total: 0
    },
    searchText: ""
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getUserList({ pageNum: pager.current });
  };

  // 검색
  onSearch = (value) => {
    this.setState({
      searchText: value
    });

    this.getUserList({ searchText: value });
  };

  // 회원 목록 조회
  getUserList = (params = {}) => {
    let pageNum = params.pageNum || 1;
    let searchText = params.searchText || this.state.searchText;

    httpGet(
      httpUrl.userList,
      [10, pageNum, false, searchText, "ID", "", ""],
      {}
    )
      .then((res) => {
        // console.log(`res = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.userList,
          pagination
        });
      })
      .catch((e) => {});
  };

  // 지갑 유무 확인
  getAddressCheck = (value) => {
    httpGet(httpUrl.getAddress, [this.props.foundationData.coinType, value], {})
      .then((res) => {
        // console.log(`res = ${JSON.stringify(res.data, null, 4)}`);
        if (res.data) {
          this.props.onOk(res.data.address);
          this.props.onCancel();
        } else alert("해당 코인의 지갑주소가 생성되지 않았습니다.");
      })
      .catch((e) => {});
  };

  componentDidMount() {}

  render() {
    const { visible, onCancel } = this.props;

    const memberColumns = [
      {
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data, row, index) => (
          <div key={`createDate${index}`}>{formatDate(data)}</div>
        )
      },
      {
        title: "아이디(이메일)",
        dataIndex: "id",
        className: "text-center",
        render: (data, row, index) => {
          return (
            <div
              key={`serial${index}`}
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() => this.getAddressCheck(row.serial)}>
              {data}
            </div>
          );
        }
      },
      {
        title: "시리얼번호",
        dataIndex: "serial",
        className: "text-center",
        render: (data, row, index) => {
          return (
            <div key={`serial${index}`} className="flex-row flex-column">
              {data}
            </div>
          );
        }
      }
    ];

    return (
      <Modal
        visible={visible}
        title="회원검색"
        cancelText="취소"
        onCancel={onCancel}
        style={{ minWidth: "800px" }}
        footer={null}>
        <div className="flex-row flex-center flex-sb m-b-10">
          <Search
            placeholder={"아이디를 입력해주세요."}
            enterButton="검색"
            allowClear
            onSearch={this.onSearch}
            style={{ width: 400 }}
          />
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="member-table"
          className="flex-1"
          rowKey={(record) => record.idx}
          columns={memberColumns}
          dataSource={this.state.list}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </Modal>
    );
  }
}

export default UserListModal;
