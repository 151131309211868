import React, { Component } from "react";
import { Modal } from "antd";
import BasicImage from "../common/BasicImage";
import { serverUrl, httpUrl } from "../../api/httpClient";

class ImageList extends Component {
  state = {
    Images: []
  };

  componentDidMount() {}

  render() {
    const { visible, onCancel, images } = this.props;
    return (
      <Modal
        visible={visible}
        title="증빙자료"
        onCancel={onCancel}
        footer={false}>
        {images.map((image, index) => (
          <BasicImage
            image={image}
            src={serverUrl + httpUrl.getImage + image}
            style={{
              width: "100%",
              height: "auto",
              marginBottom:
                index - 1 === 0 || index === images.length ? 0 : "10px"
            }}
          />
        ))}
      </Modal>
    );
  }
}

export default ImageList;
