import React, { Component } from "react";

import { Table, DatePicker, Button } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";
import locale from "antd/es/date-picker/locale/ko_KR";

import {
  statFormat,
  dayFormat,
  amountFormat,
  numberFormat,
  checkCoinName
} from "../../util";

const { RangePicker } = DatePicker;
export default class FeeDailyStatistics extends Component {
  state = {
    feeDateList: [],
    pagination: {
      total: 0,
      current: 1
    },
    startDate: "",
    endDate: ""
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getFeeDateStatistic({
      pageNum: pager.current
    });
  };

  // 검색 기간 설정
  onChange = (date, dateString) => {
    this.setState({
      startDate: date.length !== 0 ? statFormat(date[0]) : "",
      endDate: date.length !== 0 ? statFormat(date[1]) : ""
    });
  };

  // 일별 수수료 통계 조회
  getFeeDateStatistic = (params = {}) => {
    let pageNum = params.pageNum || 1;
    httpGet(
      httpUrl.getFeeDateStatistic,
      [10, pageNum, this.state.startDate, this.state.endDate],
      {}
    )
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          feeDateList: res.data.list,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getFeeDateStatistic();
  }

  render() {
    const columns = [
      {
        width: 120,
        title: "일자",
        dataIndex: "statDate",
        className: "text-center",
        render: (data) => <div>{dayFormat(data)}</div>
      },
      {
        key: "coinType",
        title: "코인 종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "횟수",
        dataIndex: "count",
        className: "text-center",
        render: (data, row) => <div>{numberFormat(data)}</div>
      },
      {
        title: "수수료",
        dataIndex: "fee",
        className: "text-center",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      }
    ];

    return (
      <div>
        <div className="title">일별 수수료 통계</div>
        <div className="flex-row flex-center flex-end m-b-10">
          <div className="flex-row flex-center">
            <label>검색 기간&nbsp;</label>
            <RangePicker locale={locale} onChange={this.onChange} />
            <Button
              type="primary"
              onClick={() => {
                this.getFeeDateStatistic();
              }}>
              검색
            </Button>
          </div>
        </div>
        <Table
          rowKey={(record) => `${record.statDate}${record.coinType}`}
          columns={columns}
          dataSource={this.state.feeDateList}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}
