import React, { Component } from "react";
import { Form, Modal, Input, InputNumber } from "antd";

const FormItem = Form.Item;
const ConfirmInputModal = Form.create()(
  class extends Component {
    onOk = () => {
      const { selectedRow, rootPage, modifyData, form, onOk } = this.props;
      form.validateFields((err, values) => {
        if (!err) {
          let data = form.getFieldValue("data");
          if (rootPage === "trade") {
            onOk({ data });
          } else if (rootPage === "lock") {
            onOk({
              idx: selectedRow.idx,
              memoDeleted: data,
              deleted: selectedRow.deleted
            });
          } else {
            if (modifyData === "price") {
              onOk({
                idx: selectedRow.idx,
                memo: selectedRow.memo,
                receiptPrice: data,
                adjustmentStatus: "COMPLETE"
              });
            } else {
              onOk({
                idx: selectedRow.idx,
                memo: data,
                adjustmentStatus: "MEMO"
              });
            }
          }
        }
      });
    };

    componentDidMount() {
      if (this.props.rootPage === "adjustment") {
        if (this.props.modifyData === "price") {
          this.props.form.setFieldsValue({
            data: this.props.selectedRow.price
          });
        } else {
          this.props.form.setFieldsValue({
            data: this.props.selectedRow.memo
          });
        }
      }
    }

    render() {
      const { visible, form, onCancel, rootPage, modifyData } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 5 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };

      const title =
        rootPage === "lock"
          ? "LOCK을 삭제하시겠습니까?"
          : rootPage === "trade"
          ? "거절이유"
          : "수정";

      return (
        <Modal
          title={<span>{title}</span>}
          visible={visible}
          okText="확인"
          cancelText="취소"
          onOk={this.onOk}
          onCancel={onCancel}>
          <Form {...formItemLayout}>
            <FormItem
              label={<span>{modifyData === "price" ? "금액" : "메모"}</span>}>
              {/* message: "메모를 입력해주세요!" */}
              {getFieldDecorator("data", {
                rules: [
                  {
                    required: false
                  }
                ]
              })(
                modifyData === "price" ? (
                  <InputNumber
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    placeholder="금액을 입력해주세요"
                    style={{ width: "100%" }}
                  />
                ) : (
                  <Input.TextArea
                    rows={4}
                    // onChange={e =>
                    //   this.handleChangeFormData(e.target.value, "memo")
                    // }
                    placeholder="메모를 입력해주세요."
                    maxLength={100}
                  />
                )
              )}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

export default ConfirmInputModal;
