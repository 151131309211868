import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { Modal, Table, Select, Button, Checkbox } from "antd";
import moment from "moment";

import {
  numberFormat,
  formatDate,
  endDateFormat,
  amountFormat,
  coinMultiFormat,
  formatDateSecond,
  checkCoinName,
  checkData
} from "../../util";
import string from "../../string";
import { httpPut, httpUrl, httpGet } from "../../api/httpClient";
import LockCreateModal from "../common/LockCreateModal";
import SelectBox from "../common/SelectBox";
import ConfirmInputModal from "../common/ConfirmInputModal";
import { ShowErrorAlert } from "../common/ErrorAlertModal";
import { showAlert } from "../common/AlertModal";

const Option = Select.Option;

export default class CoinLockListModal extends Component {
  state = {
    list: [],
    pagination: {
      current: 0,
      total: 0
    },
    coinType: 0,
    searchTypes: [],
    coinLockCoinList: [],

    selectedRowKeys: [],
    selectedRows: [],
    deleteFlag: 0,

    createFlag: false,
    createVisible: false,
    coinList: {
      coinType: [],
      serialNum: ""
    },
    selectData: {},
    modalType: "create",

    deleteModalVisible: false
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getCoinLockList({ pageNum: pagination.current });
  };

  // 코인 종류 선택
  handleCoinType = (value) => {
    this.setState({
      coinType: value
    });
  };

  // 검색 시 삭제 포함 or 만료 포함 여부 선택
  handleSearchType = (checkedValues) => {
    this.setState({
      searchTypes: checkedValues
    });
  };

  handleChangeData = (value, type, coinType) => {
    let tempData = this.state.selectData;
    if (type === "amount") {
      tempData[type] = coinMultiFormat(value, tempData.coinType);
    } else {
      tempData[type] = value;
    }
    this.setState({
      selectData: tempData
    });
  };

  // 마감일 제한
  disabledDate = (current) => {
    // Can not select days before today and today
    return current < moment().endOf("day" - 1);
  };

  // 삭제 시 삭제 메모처리
  handleDeleteState = (value, row) => {
    let deleted = row.deleted;
    if (value !== deleted) {
      if (deleted) {
        this.onDelete({
          idx: row.idx,
          memoDeleted: row.memoDeleted,
          deleted: row.deleted
        });
      } else {
        this.setState({
          deleteModalVisible: true,
          selectedRow: row
        });
      }
    }
  };

  // 삭제
  onDelete = (params = {}) => {
    const { searchTypes, list, pagination } = this.state;
    if (
      global.confirm(
        `해당 LOCK을 ${params.deleted === 1 ? "복구" : "삭제"}하시겠습니까?`
      )
    ) {
      httpPut(httpUrl.coinLockDelete, [], {
        idx: params.idx,
        memoDeleted: params.memoDeleted
      }).then((res) => {
        if (res.data.result === "SUCCESS") {
          let pageNum =
            !searchTypes.includes("deleted") &&
            pagination.current > 1 &&
            params.deleted === 0
              ? list.length === 1
                ? pagination.current - 1
                : pagination.current
              : pagination.current;

          if (params.deleted === 1) showAlert("restore");
          else showAlert("delete");
          this.getCoinLockList({ pageNum });
          this.setState({
            deleteModalVisible: false
          });
        } else ShowErrorAlert(res.data.result, "restore");
      });
    }
  };

  // 다중선택
  onRowSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRowKeys,
      selectedRows: selectedRows.map((row) => {
        return {
          ...row,
          memoDeleted: ""
        };
      })
    });
  };

  // 다중선택 삭제
  onMuptiDelete = (params = {}) => {
    const { selectedRows, searchTypes, list, pagination } = this.state;

    if (global.confirm(`선택하신 Lock들을 삭제 하시겠습니까?`)) {
      let idxs = [];
      selectedRows.forEach((row) => {
        idxs.push(row.idx);
      });
      // console.log(`idxs= ${idxs}`);
      let deleteDate = {
        idxs,
        memoDeleted: ""
      };
      // console.log(`deleteDate= ${JSON.stringify(deleteDate, null, 4)}`);

      httpPut(httpUrl.lockMuptiDelete, [], deleteDate)
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            let pageNum =
              !searchTypes.includes("deleted") &&
              pagination.current > 1 &&
              params.deleted === 0
                ? list.length === 1
                  ? pagination.current - 1
                  : pagination.current
                : pagination.current;

            showAlert("delete");
            this.setState({
              selectedRows: [],
              selectedRowKeys: []
            });
            this.getCoinLockList({ pageNum });
          } else ShowErrorAlert(res.data.result);
        })
        .catch((e) => {});
    }
  };

  // 생성
  handleCoinCreateModal = (type, row) => {
    if (this.state.createFlag) {
      this.setState({
        createVisible: true,
        modalType: type,
        selectData: type === "edit" && {
          ...row,
          expireDate: row.expireDate
        }
      });
    } else alert("지갑이 존재하지 않습니다.");
  };

  // 회원의 코인 락 정보 리스트
  getCoinLockList = (params = {}) => {
    const { selectedSerial } = this.props;
    const { searchTypes, coinLockCoinList, coinType } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    let pageNum = params.pageNum || 1;
    let deleted = false;
    let expire = false;

    searchTypes.forEach((type) => {
      if (type === "deleted") deleted = true;
      else if (type === "expire") expire = true;
    });

    // 회원이 가지고 있는 코인 종류 가져오는 리스트
    if (coinLockCoinList.length === 0) {
      httpGet(httpUrl.walletList, [100, 1, selectedSerial.serial], {})
        .then((res) => {
          let arr = [];
          arr = res.data.walletList.map((value) => {
            if (coinList.find((coin) => coin.coinType === value.coinType)) {
              return coinList.find((coin) => coin.coinType === value.coinType);
            } else return null;
          });
          this.setState({
            coinLockCoinList: [...arr]
          });

          if (res.data.walletList !== null) {
            this.setState({
              createFlag: true,
              coinList: {
                coinType: res.data.walletList.map((value) => value.coinType),
                serialNum: selectedSerial.serial
              }
            });
          }
        })
        .catch((error) => {});
    }

    // 회원의 코인락 정보 리스트
    httpGet(
      httpUrl.coinLockList,
      [10, pageNum, selectedSerial.serial, coinType, deleted, expire],
      {}
    )
      .then((result) => {
        // console.log(`result = ${JSON.stringify(result, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.total = result.data.totalCount;
        pagination.current = result.data.currentPage;
        this.setState({
          list: result.data.coinLockList,
          pagination
        });
      })
      .catch((e) => {});
  };

  onCancel = () => {
    this.props.onCancel();
    this.setState({
      coinLockCoinList: []
    });
  };

  componentDidMount() {
    // console.log(`props = ${JSON.stringify(this.props, null, 4)}`);
    this.getCoinLockList();
  }

  // 코인 종류 선택 시 전체 추가
  renderOption() {
    const { coinLockCoinList } = this.state;

    let result = [{ coinType: 0, name: "전체" }, ...coinLockCoinList];
    result = result.map((item, index) => {
      return (
        <Option value={item.coinType} key={`${item}${index}`}>
          {item.name}
        </Option>
      );
    });

    return result;
  }

  render() {
    const { visible } = this.props;
    const { deleteFlag, selectedRowKeys, createVisible, deleteModalVisible } =
      this.state;

    const coinLockColumns = [
      {
        width: 120,
        title: "번호",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "마감일",
        dataIndex: "expireDate",
        className: "text-center",
        render: (data, row, index) => <div>{formatDate(data)}</div>
      },
      {
        title: "수량",
        dataIndex: "amount",
        className: "text-center",
        render: (data, row, index) => (
          <span>
            {data === -1 ? "전체잠금" : amountFormat(data, row.coinType)}
          </span>
        )
      },
      {
        title: "메모",
        dataIndex: "memo",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      },
      {
        title: "삭제 메모",
        dataIndex: "memoDeleted",
        className: "text-center",
        render: (data, row, index) => (
          <div>{row.deleted !== null && <span>{checkData(data)}</span>}</div>
        )
      },
      {
        title: "수정",
        className: "text-center",
        render: (data, row, index) => (
          <div>
            {row.deleted === 0
              ? row.expireDate !== null && (
                  <Button
                    onClick={() => {
                      this.handleCoinCreateModal("edit", row);
                    }}
                    style={{
                      marginRight: "5px"
                    }}>
                    수정
                  </Button>
                )
              : row.deleted === null
              ? "-"
              : "삭제"}
          </div>
        )
      },
      {
        title: "상태",
        dataIndex: "deleted",
        className: "text-center",
        render: (data, row, index) => (
          <div>
            {endDateFormat(row.expireDate) >
            formatDateSecond(
              moment()
                .endOf("day" - 1)
                .toString()
            ) ? (
              <SelectBox
                value={string.deleteString[data]}
                code={string.toggleCode}
                codeString={string.deleteString}
                onChange={(value) => {
                  if (parseInt(value) !== data) {
                    this.handleDeleteState(value, row);
                  }
                }}
              />
            ) : (
              <span>{`만료`}</span>
            )}
          </div>
        )
      }
    ];

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onRowSelectChange,
      getCheckboxProps: (record) => ({
        disabled:
          endDateFormat(record.expireDate) <=
            formatDateSecond(
              moment()
                .endOf("day" - 1)
                .toString()
            ) || record.deleted === 1,
        key: record.key
      })
    };

    const options = [
      { label: "삭제 포함", value: "deleted" },
      { label: "만료 포함", value: "expire" }
    ];

    return (
      <Modal
        visible={visible}
        title="코인별 LOCK"
        onCancel={this.onCancel}
        style={{ minWidth: "1400px" }}
        footer={null}>
        {createVisible && (
          <LockCreateModal
            visible={createVisible}
            modalType={this.state.modalType}
            editData={this.state.selectData}
            coinList={this.state.coinList}
            onCancel={() => {
              this.setState({
                createVisible: false,
                modalType: "create",
                selectData: {}
              });
            }}
            refresh={this.getCoinLockList}
          />
        )}
        {deleteModalVisible && (
          <ConfirmInputModal
            rootPage="lock"
            visible={deleteModalVisible}
            onOk={this.onDelete}
            onCancel={() => {
              this.setState({
                deleteModalVisible: false
              });
            }}
            selectedRow={this.state.selectedRow}
          />
        )}
        <div className="flex-row flex-center flex-sb m-b-10">
          <div className="flex-row flex-center">
            <Button
              onClick={() => {
                this.handleCoinCreateModal("create");
              }}
              style={{ marginRight: "10px" }}>
              생성
            </Button>
            <Button
              onClick={this.onMuptiDelete}
              disabled={deleteFlag ? true : false}>
              선택한 LOCK 삭제
            </Button>
          </div>
          <div className="flex-row flex-center">
            <div className="flex-row flex-center m-r-2">
              <label>검색조건&nbsp;&nbsp;</label>
              <Checkbox.Group
                options={options}
                value={this.state.searchTypes}
                onChange={this.handleSearchType}
              />
            </div>
            <div className="flex-row flex-center">
              <label>코인종류&nbsp;</label>
              <Select
                type="coinType"
                placeholder="코인 종류를 선택해주세요."
                value={this.state.coinType}
                onChange={this.handleCoinType}
                style={{ width: "240px", marginRight: "5px" }}>
                {this.renderOption()}
              </Select>
              <Button
                type="primary"
                onClick={() => {
                  if (this.state.coinType === undefined)
                    alert("코인 종류를 선택해주세요.");
                  else this.getCoinLockList();
                }}>
                검색
              </Button>
            </div>
          </div>
        </div>
        <div style={{ color: "blue" }} className="m-b-10">
          * 전체잠금이 설정된 기간동안은 개별 락의 상태와 관계없이 해당 지갑의
          해당 코인에 대해 모든 출금이 금지됩니다.
        </div>
        <div style={{ clear: "both" }} />
        <Table
          className="member-coinLock-table"
          rowKey={(record) => (record.idx ? record.idx : record.coinType)}
          rowClassName={(record) => {
            // console.log(moment().endOf("day" - 1));
            return endDateFormat(record.expireDate) <=
              formatDateSecond(
                moment()
                  .endOf("day" - 1)
                  .toString()
              ) || record.deleted === 1
              ? "gray"
              : "";
          }}
          columns={coinLockColumns}
          dataSource={this.state.list}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          rowSelection={rowSelection}
        />
      </Modal>
    );
  }
}
