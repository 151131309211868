import React, { Component } from "react";

import { Table, Button, Input } from "antd";
import { httpGet, httpPut, httpUrl } from "../../api/httpClient";
import { checkCoinName, formatDate } from "../../util";
import { showAlert } from "../../components/common/AlertModal";
import P2PCautionModal from "../../components/wallet/P2PCautionModal";

const Search = Input.Search;
class P2PCaution extends Component {
  state = {
    list: [],
    pagination: {
      current: 0,
      total: 0
    },
    searchText: "",

    visible: false,
    selectedData: {},
    type: "create"
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getP2PCautionList({ pageNum: pager.current });
  };

  // 검색
  onSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.getP2PCautionList();
      }
    );
  };

  // 삭제
  onDelete = (row) => {
    const { pagination } = this.state;

    if (
      global.confirm(
        `${checkCoinName(row.coinType)} ${row.address} 주소를 삭제하시겠습니까?`
      )
    ) {
      // console.log(JSON.stringify(row, null, 4));
      httpPut(httpUrl.p2pCautionDelete, [], row)
        .then((result) => {
          showAlert("delete");
          this.getP2PCautionList({ pageNum: pagination.current });
        })
        .catch((error) => {});
    }
  };

  // 리스트 불러오기
  getP2PCautionList = (params = {}) => {
    let pageNum = params.pageNum || 1;

    if (params.refresh) pageNum = 1;

    httpGet(httpUrl.getP2PCautionList, [10, pageNum, this.state.searchText], {})
      .then((res) => {
        // console.log(`data= ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({ list: res.data.list, pagination });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getP2PCautionList();
  }

  render() {
    const { visible, type, selectedData, list, pagination } = this.state;

    const columns = [
      {
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data, row, index) => (
          <div key={`createDate${index}`}>{formatDate(data)}</div>
        )
      },
      {
        title: "마감일",
        dataIndex: "expireDate",
        className: "text-center",
        render: (data, row, index) => (
          <div key={`expireDate${index}`}>{formatDate(data)}</div>
        )
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "지갑주소",
        dataIndex: "address",
        className: "text-center"
      },
      {
        dataIndex: "status",
        className: "text-center",
        render: (data, row) => (
          <div className="flex-row flex-center">
            <Button
              className="m-r-10"
              onClick={() => {
                this.setState({
                  visible: true,
                  type: "update",
                  selectedData: row
                });
              }}>
              수정하기
            </Button>
            <Button onClick={(value) => this.onDelete(row)}>삭제하기</Button>
          </div>
        )
      }
    ];

    return (
      <div>
        <div className="title">P2P 제한지갑</div>
        {visible && (
          <P2PCautionModal
            visible={visible}
            type={type}
            selectData={selectedData}
            onCancel={() => {
              this.setState({ visible: false });
            }}
            refresh={this.getP2PCautionList}
          />
        )}
        <div className="flex-row flex-center flex-sb m-b-10">
          <Button
            onClick={() => {
              this.setState({
                visible: true,
                selectedData: {},
                type: "create"
              });
            }}>
            생성
          </Button>
          <div className="flex-row flex-center">
            <label>검색기준&nbsp;&nbsp;</label>
            <Search
              placeholder="지갑주소을 입력하세요"
              enterButton="검색"
              allowClear
              onSearch={this.onSearch}
              style={{ width: "300px" }}
            />
          </div>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="caution-table"
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default P2PCaution;
