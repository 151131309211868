import React, { Component } from "react";

import { Form, Modal, Input, DatePicker, Upload, Button, Icon } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from "moment";

import { httpPost, httpPut, httpUrl, makeUrl } from "../../api/httpClient";
import { checkCoinName, endDateFormat } from "../../util";
import { showAlert } from "../common/AlertModal";
import CoinSelectBox from "../common/CoinSelectBox";
import { ShowErrorAlert } from "../common/ErrorAlertModal";

const FormItem = Form.Item;
const P2PCautionModal = Form.create()(
  class extends Component {
    // 날짜 선택 시 오늘 이전 선택 불가 처리
    disabledDate = (current) => {
      return current < moment().startOf("day");
    };

    /* 생성 - create
      error INVALID_ADDRESS / DUPLICATE / PASSED_DATE */
    onOk = (e) => {
      const { form } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
        // console.log(`value = ${JSON.stringify(values, null, 4)}`);
        if (!err) {
          let data = {
            ...values,
            expireDate: endDateFormat(values.expireDate)
          };
          // console.log(`data = ${JSON.stringify(data, null, 4)}`);
          if (global.confirm("P2P 제한지갑을 생성하시겠습니까?"))
            httpPost(httpUrl.p2pCautionCreate, [], data)
              .then((res) => {
                if (res.data === "SUCCESS") {
                  showAlert("create");
                  this.props.refresh({ refresh: true });
                  this.props.onCancel();
                } else ShowErrorAlert(res.data);
              })
              .catch((e) => {});
        }
      });
    };

    /* 수정 - update
      error PASSED_DATE */
    onUpdate = (e) => {
      const { form, selectData } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
        let data = {
          ...values,
          coinType: selectData.coinType,
          expireDate: endDateFormat(values.expireDate)
        };
        // console.log(`data = ${JSON.stringify(data, null, 4)}`);
        if (global.confirm("P2P 제한지갑을 수정하시겠습니까?"))
          httpPut(httpUrl.p2pCautionUpdate, [], data)
            .then((res) => {
              if (res.data === "SUCCESS") {
                showAlert("update");
                this.props.refresh();
                this.props.onCancel();
              } else ShowErrorAlert(res.data);
            })
            .catch((e) => {});
      });
    };

    componentDidMount() {
      const { type, form, selectData } = this.props;
      if (type === "update")
        form.setFieldsValue({
          ...selectData,
          expireDate: moment(selectData.expireDate)
        });
    }

    render() {
      const { visible, type, onCancel, form, selectData } = this.props;
      const { getFieldDecorator } = form;

      const props = {
        action: `${makeUrl(httpUrl.p2pCautionCreateExcel, null)}`,
        withCredentials: true,
        multiple: false,
        beforeUpload: (file) => {
          if (global.confirm("배포 하시겠습니까?")) {
            return true;
          } else {
            return false;
          }
        },
        onSuccess: (file) => {
          // console.log(`file = ${JSON.stringify(file, null, 4)}`);
          var success = file.data.success;
          var fail = file.data.fail;
          var total = success + fail;

          var s =
            "총 : " + total + ", 성공 : " + success + ", 실패 : " + fail + "\n";
          Modal.info({
            title: "배포결과",
            content: (
              <div>
                <p>
                  {file.data.failAddresses.length > 0 ? (
                    <div>
                      <div>{`${s}`}</div>
                      <br />
                      {`- 실패한 배포\n`}
                      {file.data.failAddresses.length > 0 &&
                        file.data.failAddresses.map((value) => (
                          <div>{value}</div>
                        ))}
                    </div>
                  ) : (
                    <div>{s}</div>
                  )}
                </p>
              </div>
            ),
            onOk() {
              // onCancel();
            }
          });
        },
        onError(err) {
          Modal.error({
            title: "배포결과",
            content: "파일 업로드 실패"
          });
        }
      };

      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12 }
        }
      };

      return (
        <Modal
          visible={visible}
          title={`P2P 제한지갑 ${type === "create" ? "생성" : "수정"}`}
          okText={type === "create" ? "생성" : "수정"}
          onOk={type === "create" ? this.onOk : this.onUpdate}
          cancelText="취소"
          onCancel={onCancel}
          style={{ minWidth: "800px" }}>
          <div style={{ clear: "both" }} />
          <Form
            {...formItemLayout}
            onSubmit={type === "create" ? this.onOk : this.onUpdate}>
            {type === "create" && (
              <FormItem label={<span></span>} colon={false}>
                <div className="flex-row flex-center">
                  <Upload
                    {...props}
                    accept=".xlsx, .xls"
                    showUploadList={false}>
                    <Button style={{ marginRight: "20px" }}>
                      <Icon type="upload" style={{ fontSize: "20px" }} /> 파일
                      업로드
                    </Button>
                  </Upload>
                  <a href={"/p2pCaution.xlsx"} download>
                    <Button>
                      <Icon type="download" style={{ fontSize: "20px" }} />
                      양식 다운로드
                    </Button>
                  </a>
                </div>
                <span style={{ color: "blue", marginLeft: "10px" }}>
                  * 여러 지갑 주소를 제한할 때 사용
                </span>
              </FormItem>
            )}
            <FormItem label={<span>코인 종류&nbsp;</span>}>
              {type === "create"
                ? getFieldDecorator("coinType", {
                    rules: [
                      {
                        required: true,
                        message: "코인 종류를 선택해주세요!"
                      }
                    ]
                  })(
                    <CoinSelectBox
                      placeholder="선택해주세요"
                      style={{ width: "200px" }}
                      // onChange={(value) => {
                      //   this.onSelect(value, "coinType");
                      // }}
                    />
                  )
                : checkCoinName(selectData.coinType)}
            </FormItem>
            <FormItem label={<span>지갑주소&nbsp;</span>}>
              {type === "create"
                ? getFieldDecorator("address", {
                    rules: [
                      {
                        required: true,
                        message: "지갑주소를 입력해주세요!"
                      }
                    ]
                  })(<Input.TextArea allowClear />)
                : selectData.address}
            </FormItem>
            <FormItem label={<span>마감일&nbsp;</span>}>
              {getFieldDecorator("expireDate", {
                rules: [
                  {
                    required: true,
                    message: "마감일을 선택해주세요!"
                  }
                ]
              })(
                <DatePicker
                  locale={locale}
                  // disabledDate={this.disabledDate}
                  // onChange={(date) => {
                  //   this.handleChange(date, "expireDate");
                  // }}
                />
              )}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

export default P2PCautionModal;
