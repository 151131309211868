import React, { Component } from "react";
import { CompactPicker } from "react-color";

import { Modal, Form, Input, InputNumber } from "antd";
import { httpPost, httpUrl, httpPut } from "../../api/httpClient";
import { showAlert } from "../common/AlertModal";

const FormItem = Form.Item;
const CategoryModal = Form.create()(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        color: "#009CE0"
      };
    }

    handleChangeComplete = (color) => {
      this.setState({ color: color.hex });
    };

    onOk = (e) => {
      const { form, type, detail } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
        if (!err) {
          let body = {
            ...values,
            color: this.state.color
          };
          if (!type) {
            if (global.confirm("카테고리를 생성하시겠습니까?"))
              httpPost(httpUrl.categoryCreate, [], body)
                .then((res) => {
                  if (res.data.result === "SUCCESS") {
                    this.props.onOk();
                    this.props.onCancel();
                    showAlert("create");
                  } else {
                    alert("카테고리가 중복되었습니다.");
                  }
                })
                .catch((e) => {});
          } else {
            body = {
              ...body,
              idx: detail.idx
            };
            if (global.confirm("카테고리를 수정하시겠습니까?"))
              httpPut(httpUrl.categoryUpdate, [], body)
                .then((res) => {
                  if (res.data.result === "SUCCESS") {
                    this.props.onOk({ refresh: true });
                    this.props.onCancel();
                    showAlert("update");
                  } else {
                    alert("카테고리가 중복되었습니다.");
                  }
                })
                .catch((e) => {});
          }
          // console.log(`result = ${JSON.stringify(body, null, 4)}`);
        }
      });
    };

    componentDidMount() {
      const { type, detail, form } = this.props;

      if (type) {
        this.setState({ color: detail.color });
        form.setFieldsValue({
          name: detail.name,
          rank: detail.rank
        });
      }
    }

    render() {
      const { visible, type, onCancel } = this.props;
      const { getFieldDecorator } = this.props.form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12 }
        }
      };

      return (
        <Modal
          visible={visible}
          onOk={this.onOk}
          onCancel={onCancel}
          title={type ? "카테고리 수정" : "카테고리 생성"}
          cancelText="취소"
          okText={type ? "변경" : "생성"}>
          <Form {...formItemLayout} onSubmit={this.onOk}>
            <FormItem label={<span>카테고리&nbsp;</span>}>
              {getFieldDecorator("name", {
                rules: [
                  {
                    required: true,
                    message: "카테고리를 입력해주세요!"
                  }
                ]
              })(<Input placeholder="커피" />)}
            </FormItem>
            <FormItem label={<span>색상&nbsp;</span>}>
              {getFieldDecorator("color", {
                rules: [
                  {
                    required: false
                  }
                ]
              })(
                <CompactPicker
                  color={this.state.color}
                  onChangeComplete={this.handleChangeComplete}
                />
              )}
            </FormItem>
            {type && (
              <FormItem label={<span>랭크&nbsp;</span>}>
                {getFieldDecorator("rank", {
                  rules: [
                    {
                      required: true,
                      message: "랭크 값을 입력해주세요"
                    }
                  ]
                })(
                  <InputNumber placeholder="3" min={1} max={this.props.total} />
                )}
              </FormItem>
            )}
          </Form>
        </Modal>
      );
    }
  }
);

export default CategoryModal;
