import React, { Component } from "react";

import { Table, Button } from "antd";
import { httpGet, httpUrl, httpPost } from "../../api/httpClient";
import {
  numberFormat,
  formatDate,
  coinDivideFormat,
  checkCoinName,
  checkData
} from "../../util";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import ConfirmInputModal from "../../components/common/ConfirmInputModal";
import ImageList from "../../components/trace/ImageList";

class TradeList extends Component {
  state = {
    list: [],
    pagination: {
      current: 1,
      total: 0
    },
    coinType: 0,
    status: "ALL",
    searchText: "",
    tradeType: "ALL"
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getTradeList({ pageNum: pager.current });
  };

  onDeleteHandle = (row) => {
    this.setState({
      memoVisible: true,
      selectedRow: row
    });
  };

  // 수정
  onUpdate = (value, row) => {
    let status = value;
    let updateData = {
      ...row,
      status
    };
    let memo = "";

    if (value !== "SUCCESS") {
      status = "REJECT";
      memo = value.data;
      updateData = {
        ...this.state.selectedRow,
        memo,
        status
      };
    }

    if (global.confirm(string.tradeStatus[status] + "로 변경하시겠습니까?")) {
      httpPost(httpUrl.tradeUpdate, [], updateData)
        .then((res) => {
          this.getTradeList({ pageNum: this.state.pagination.current });
          // this.setState({
          //   memoVisible: false
          // });
        })
        .catch((e) => {});
      this.setState({
        memoVisible: false
      });
    }
  };

  // 교환 내역 조회
  getTradeList = (params = {}) => {
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getTradeList, [10, pageNum, this.state.status], {})
      .then((res) => {
        // console.log(`data = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.logList
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getTradeList();
  }

  render() {
    const {
      memoVisible,
      selectedRow,
      imageVisible,
      images,
      status,
      list,
      pagination
    } = this.state;

    const columns = [
      {
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "교환코인",
        dataIndex: "fromCoinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "교환 요청 코인",
        dataIndex: "toCoinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "수량",
        dataIndex: "amount",
        className: "text-center",
        render: (data, row) => (
          <div>{coinDivideFormat(data, row.fromCoinType)}</div>
        )
      },
      {
        title: "비율 (%)",
        dataIndex: "ratio",
        className: "text-center"
      },
      {
        title: "이름",
        dataIndex: "name",
        className: "text-center"
      },
      {
        title: "이메일",
        dataIndex: "email",
        className: "text-center"
      },
      {
        title: "전화번호",
        dataIndex: "phone",
        className: "text-center"
      },
      {
        title: "상태",
        dataIndex: "status",
        className: "text-center",
        render: (data, row) => (
          <div>
            {data === "REQUEST" ? (
              <div>
                <Button
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    this.onUpdate("SUCCESS", row);
                  }}>
                  승인
                </Button>
                <Button
                  onClick={() => {
                    this.onDeleteHandle(row);
                  }}>
                  거절
                </Button>
              </div>
            ) : (
              string.tradeStatus[data]
            )}
          </div>
        )
      }
    ];

    const expandedRowRender = (record) => {
      let dataArr = [record];
      const dropColumns = [
        {
          title: "증빙타입",
          dataIndex: "proofType",
          className: "text-center",
          render: (data) => <div>{string.proofType[data]}</div>
        },
        {
          title: "증빙자료",
          dataIndex: "images",
          className: "text-center",
          render: (data) => (
            <div>
              {data.length !== 0 ? (
                <Button
                  onClick={() => {
                    this.setState({ imageVisible: true, images: data });
                  }}>
                  상세보기
                </Button>
              ) : (
                "-"
              )}
            </div>
          )
        },
        {
          title: "메모",
          dataIndex: "memo",
          className: "text-center",
          render: (data) => <div>{checkData(data)}</div>
        }
      ];

      return (
        <div>
          <div style={{ clear: "both" }} />
          <Table
            rowKey={(record) => `record: ${record.idx}`}
            columns={dropColumns}
            dataSource={dataArr}
            pagination={false}
          />
        </div>
      );
    };

    return (
      <div>
        {memoVisible && (
          <ConfirmInputModal
            rootPage="trade"
            visible={memoVisible}
            selectedRow={selectedRow}
            onOk={this.onUpdate}
            onCancel={() => {
              this.setState({
                memoVisible: false
              });
            }}
          />
        )}
        {imageVisible && (
          <ImageList
            visible={imageVisible}
            images={images}
            onCancel={() => {
              this.setState({ imageVisible: false });
            }}
          />
        )}
        <div className="title">교환 내역</div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <SelectBox
            style={{ width: "160px" }}
            value={string.tradeStatus[status]}
            code={string.tradeCode}
            codeString={string.tradeStatus}
            onChange={(value) => {
              if (value !== status) {
                this.setState({ status: value }, () => {
                  this.getTradeList();
                });
              }
            }}
          />
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
          style={{ marginBottom: "10px" }}
          expandedRowRender={expandedRowRender}
        />
      </div>
    );
  }
}

export default TradeList;
