import React, { Component } from "react";

import {
  Modal,
  Form,
  DatePicker,
  Radio,
  Input,
  InputNumber,
  Row,
  Col,
  Checkbox,
  Button
} from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import { BigNumber } from "bignumber.js";

import { httpUrl, httpGet, httpPut } from "../../api/httpClient";
import {
  coinMultiFormat,
  amountFormat,
  endDateFormat,
  coinDivideFormat,
  removeComma,
  bigNum,
  amountMultiFormat,
  checkCoinName
} from "../../util";
import moment from "moment";
import { showAlert } from "./AlertModal";
import CoinSelectBox from "./CoinSelectBox";
import { ShowErrorAlert } from "./ErrorAlertModal";

const FormItem = Form.Item;

const LockCreateModal = Form.create()(
  class extends Component {
    state = {
      lockStatus: "ALL",
      isRepeat: false,
      maxAmount: 0,
      coinList: [],
      formData: {
        amount: 0,
        coinType: 0,
        expireDate: "",
        serialNum: "",
        count: 1,
        date: 0,
        memo: ""
      },
      validateAddress: false
    };

    // 마감일 제한
    disabledDate = (current) => {
      // Can not select days before today and today
      return current < moment().endOf("day" - 1);
    };

    handleChangeFormData = (value, type) => {
      const { formData, validateAddress } = this.state;
      formData[type] = value;
      let validateAddr = validateAddress;
      if (type === "coinType") {
        if (this.state.lockStatus === "AMOUNT")
          this.props.form.setFieldsValue({
            amount: ""
          });
        if (!this.props.pageType) this.handleCalculateMaxAmount(value);
      }
      if (type === "address") {
        validateAddr = false;
        formData.serialNum = "";
      }
      this.setState({
        formData,
        validateAddress: validateAddr
      });
    };

    // 재단관리에서 실행 시
    foundationAddress = () => {
      const { pageType, coinList } = this.props;
      const tempData = Object.assign({}, this.state.formData);

      let coinParams =
        pageType === "foundationStatus" ? coinList.coinType : tempData.coinType;
      let addrParams =
        pageType === "foundationStatus" ? coinList.address : tempData.address;
      httpGet(httpUrl.getSearchSerial, [coinParams, addrParams], {})
        .then((res) => {
          if (res.data.data === null) {
            if (
              pageType === "lockManagement" &&
              this.props.modalType === "create"
            )
              alert("검증이 완료되었습니다.");
            tempData.coinType = coinParams;
            tempData["serialNum"] = res.data.serialNum;
            this.props.form.setFieldsValue({ address: addrParams });
            this.setState({
              maxAmount: res.data.balance,
              formData: tempData,
              validateAddress: true
            });
          } else {
            ShowErrorAlert(res.data.data);
            tempData.address = "";
            tempData.serialNum = "";
            this.props.form.setFieldsValue({ address: "" });
            this.setState({
              formData: tempData
            });
          }
        })
        .catch((e) => {});
    };

    // 회원관리에서 실행 시
    userAddress = (coinType) => {
      httpGet(
        httpUrl.coinLockBalance,
        [this.props.coinList.serialNum, coinType],
        {}
      )
        .then((res) => {
          this.setState({
            maxAmount: res.data
          });
        })
        .catch((e) => {});
    };

    // 주소검증
    handleCalculateMaxAmount = (coinType) => {
      const { pageType } = this.props;

      if (pageType) this.foundationAddress();
      else this.userAddress(coinType);
    };

    // 반복 설정 시 해제 비율 계산
    handleCalculateAvg = () => {
      const { formData } = this.state;
      if (
        formData.count > 0 &&
        formData.amount / formData.count > 0 &&
        formData.date !== 0
      ) {
        let amount = formData.amount;
        let count = formData.count;
        let avg = Math.floor((amount / count / amount) * 100 * 100) / 100;
        let rest = ((100 - avg * (count - 1)) * 100) / 100;
        return (
          <FormItem
            label={<span></span>}
            colon={false}>{`LOCK 해제비율: ${avg}% 마지막 해제: ${rest.toFixed(
            2
          )}%`}</FormItem>
        );
      } else {
        return null;
      }
    };

    //재단에서 생성
    foundationLock = (e) => {
      const { pageType, form, modalType } = this.props;
      const { formData, lockStatus, isRepeat } = this.state;

      form.validateFields((err, values) => {
        if (!err) {
          let amount = 0;
          if (pageType === "lockManagement")
            amount = amountMultiFormat(formData.amount, formData.coinType);
          else amount = coinMultiFormat(formData.amount, formData.coinType);

          let createData = {
            ...formData,
            expireDate: endDateFormat(formData.expireDate),
            amount: lockStatus === "ALL" ? -1 : amount,
            date: 0,
            count: 1
          };

          let isPassed = false;
          if (isRepeat) {
            if (amount / formData.count > 0) {
              createData.date = formData.date;
              createData.count = formData.count;
            } else {
              alert(`수량이 최소금액보다 적거나 0입니다.`);
              isPassed = true;
            }
          } else {
            if (createData.amount === 0) {
              alert(`수량이 최소금액보다 적거나 0입니다.`);
              isPassed = true;
            }
          }
          if (!isPassed || amount !== 0) {
            if (modalType === "edit") {
              delete createData.deleted;
              delete createData.memoDeleted;
              delete createData.createDate;
            }
            if (pageType === "lockManagement") {
              delete createData["address"];
            }
            // console.log(`createData = ${JSON.stringify(createData, null, 4)}`);
            if (
              global.confirm(
                `${modalType === "create" ? "생성" : "수정"}하시겠습니까?`
              )
            ) {
              httpPut(httpUrl.lockUpdate, [], createData)
                .then((res) => {
                  if (res.data.result === "SUCCESS") {
                    modalType === "create"
                      ? showAlert("create")
                      : showAlert("update");
                    //foundationStatus: 보유 현황 조회
                    let refresh = modalType === "edit" ? true : false;
                    pageType === "foundationStatus"
                      ? this.props.refresh()
                      : this.props.refresh({
                          refresh: refresh
                        });
                    this.props.onCancel();
                  } else ShowErrorAlert(res.data.data);
                })
                .catch((e) => {});
            }
          }
        }
      });
    };

    //회원관리에서 생성 및 수정
    userLock = (e) => {
      const { form, modalType } = this.props;
      const { formData, lockStatus, isRepeat } = this.state;

      form.validateFields((err, values) => {
        // console.log(`user values = ${JSON.stringify(values, null, 4)}`);
        if (!err) {
          let amount = coinMultiFormat(formData.amount, formData.coinType);
          let createData = {
            ...formData,
            expireDate: endDateFormat(formData.expireDate),
            serialNum: this.props.coinList.serialNum,
            amount: lockStatus === "ALL" ? -1 : amount,
            date: 0,
            count: 1
          };
          let isPassed = false;
          if (isRepeat) {
            if (amount / formData.count > 0) {
              createData.date = formData.date;
              createData.count = formData.count;
            } else {
              alert(`수량이 최소금액보다 적거나 0입니다.`);
              isPassed = true;
            }
          } else {
            if (lockStatus === "AMOUNT" && (amount < 0 || amount === 0)) {
              alert(`수량이 최소금액보다 적거나 0입니다.`);
              isPassed = true;
            }
          }
          if (!isPassed || amount !== 0) {
            if (modalType === "edit") {
              delete createData.deleted;
              delete createData.memoDeleted;
              delete createData.createDate;
            }
            if (
              global.confirm(
                `${modalType === "create" ? "생성" : "수정"}하시겠습니까?`
              )
            ) {
              httpPut(httpUrl.coinLockUpdate, [], createData)
                .then((res) => {
                  if (res.data.result === "SUCCESS") {
                    modalType === "create"
                      ? showAlert("create")
                      : showAlert("update");
                    this.props.onCancel();
                    this.props.refresh();
                  } else ShowErrorAlert(res.data.data);
                })
                .catch((e) => {});
            }
          }
        }
      });
    };

    handleCreateCoinLock = () => {
      const { pageType } = this.props;

      if (pageType) this.foundationLock();
      else this.userLock();
    };

    componentDidMount() {
      // console.log(`props = ${JSON.stringify(this.props, null, 4)}`);
      const { formData } = this.state;
      const { modalType, editData, form } = this.props;
      if (modalType === "edit") {
        let amount = bigNum(
          coinDivideFormat(editData.amount, editData.coinType)
        );
        if (editData.amount === -1) amount = 0;
        this.setState(
          {
            formData: Object.assign({}, { ...editData, amount: amount }),
            lockStatus: editData.amount === -1 ? "ALL" : "AMOUNT"
          },
          () => {
            delete formData.deleted;
            delete formData.memoDeleted;
            delete formData.createDate;
            this.handleCalculateMaxAmount(editData.coinType);
          }
        );
        form.setFieldsValue({
          coinType: editData.coinType,
          serialNum: editData.serialNum,
          expireDate: moment(editData.expireDate),
          memo: editData.memo,
          lock: editData.amount === -1 ? "ALL" : "AMOUNT",
          amount: amount,
          address: editData.address
        });
        // if (this.props.pageType !== "lockManagement")
      }
      if (modalType === "create") {
        if (this.props.pageType === "foundationStatus") {
          this.handleCalculateMaxAmount(this.props.coinList.coinType);
        }
      }
    }

    render() {
      const { formData, isRepeat, validateAddress, lockStatus } = this.state;
      const {
        visible,
        onCancel,
        coinList,
        pageType,
        form,
        modalType,
        editData
      } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 8 },
          sm: { span: 8 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };

      return (
        visible && (
          <Modal
            visible={visible}
            title={`코인별 LOCK ${modalType === "create" ? "생성" : "수정"} `}
            okText="확인"
            cancelText="취소"
            onCancel={onCancel}
            onOk={() => {
              if (pageType === "lockManagement") {
                if (validateAddress) this.handleCreateCoinLock();
                else alert("주소검증이 필요합니다.");
              } else {
                this.handleCreateCoinLock();
              }
            }}
            style={{ minWidth: "800px" }}>
            <Form {...formItemLayout}>
              {/* 회원관리, LOCK관리에서는 coinType선택가능 아니면 coinType fix */}

              {modalType === "create" ? (
                <div>
                  {Array.isArray(coinList.coinType) && (
                    <FormItem label={<span>코인종류&nbsp;</span>}>
                      {getFieldDecorator("coinType", {
                        rules: [
                          {
                            required: true,
                            message: "코인종류를 선택해주세요!"
                          }
                        ]
                      })(
                        <CoinSelectBox
                          type="coinType"
                          placeholder="코인종류"
                          coinCode={coinList.coinType}
                          style={{
                            width: "160px"
                          }}
                          onChange={this.handleChangeFormData}
                        />
                      )}
                    </FormItem>
                  )}
                </div>
              ) : (
                <FormItem label={<span>코인종류&nbsp;</span>}>
                  {checkCoinName(editData.coinType)}
                </FormItem>
              )}
              {pageType === "lockManagement" && (
                <FormItem label={<span>address&nbsp;</span>}>
                  {getFieldDecorator("address", {
                    rules: [
                      {
                        required: true,
                        message: "주소를 입력해주세요!"
                      },
                      {
                        validator: (rule, value, cb) => {
                          if (
                            value &&
                            formData.serialNum === "" &&
                            !validateAddress
                          ) {
                            cb("주소검증이 필요합니다.");
                          }
                          cb();
                        }
                      }
                    ]
                  })(
                    <div>
                      {modalType === "create" ? (
                        <Input
                          allowClear
                          onChange={(e) =>
                            this.handleChangeFormData(e.target.value, "address")
                          }
                          style={{ width: "360px" }}
                          placeholder="주소를 입력해주세요"
                        />
                      ) : (
                        <span>{editData.address}</span>
                      )}
                    </div>
                  )}
                </FormItem>
              )}
              {pageType === "lockManagement" && modalType === "create" && (
                <FormItem label={<span></span>} colon={false}>
                  {validateAddress ? (
                    <div>검증완료</div>
                  ) : (
                    <Button
                      onClick={() => {
                        if (formData.coinType !== 0 && formData.address) {
                          this.handleCalculateMaxAmount();
                        } else if (formData.coinType === 0)
                          alert("코인을 선택해주세요.");
                        else alert("주소를 입력해주세요.");
                      }}>
                      주소검증
                    </Button>
                  )}
                </FormItem>
              )}
              <FormItem label={<span>LOCK 해제일 설정&nbsp;</span>}>
                {getFieldDecorator("expireDate", {
                  rules: [
                    {
                      required: true,
                      message: "날짜를 선택해주세요!"
                    }
                  ]
                })(
                  <DatePicker
                    placeholder="날짜를 선택해주세요."
                    disabledDate={this.disabledDate}
                    onChange={(date) => {
                      this.handleChangeFormData(date, "expireDate");
                    }}
                    locale={locale}
                    // onOk={value => {
                    //   this.handleChangeFormData(value, "expireDate");
                    // }}
                  />
                )}
              </FormItem>
              <FormItem label={<span>LOCK 설정&nbsp;</span>}>
                {getFieldDecorator("lock", {
                  rules: [
                    {
                      required: true,
                      message: "LOCK 설정을 선택해주세요!"
                    }
                  ]
                })(
                  <Radio.Group
                    disabled={modalType === "create" ? false : true}
                    onChange={(e) => {
                      formData.count = 1;
                      formData.date = 0;
                      this.setState({
                        lockStatus: e.target.value,
                        isRepeat: false,
                        formData
                      });
                    }}>
                    <Radio value={"ALL"}>전체잠금</Radio>
                    <Radio value={"AMOUNT"}>수량잠금</Radio>
                  </Radio.Group>
                )}
              </FormItem>
              {lockStatus === "AMOUNT" && modalType === "create" && (
                <FormItem label={<span>반복&nbsp;</span>}>
                  <Row>
                    <Col span={2}>
                      <Checkbox
                        checked={isRepeat}
                        onChange={(e) => {
                          formData.count = 1;
                          formData.date = 0;
                          this.setState({
                            isRepeat: e.target.checked,
                            formData
                          });
                        }}
                      />
                    </Col>
                    {isRepeat && (
                      <span>
                        <Col span={7}>
                          <FormItem className="inline-formitem m-b-0">
                            {getFieldDecorator("date", {
                              rules: [
                                {
                                  required: true,
                                  message: "간격을 입력해주세요!"
                                }
                              ]
                            })(
                              <InputNumber
                                min={0}
                                formatter={(value) =>
                                  `${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                }
                                parser={(value) =>
                                  value.replace(/\$\s?|(,*)/g, "")
                                }
                                onChange={(value) =>
                                  this.handleChangeFormData(value, "date")
                                }
                                style={{ width: "150px" }}
                                placeholder="LOCK 해제간격"
                              />
                            )}
                          </FormItem>
                        </Col>
                        <Col span={1} />
                        <Col span={7}>
                          <FormItem className="inline-formitem m-b-0">
                            {getFieldDecorator("count", {
                              rules: [
                                {
                                  required: true,
                                  message: "횟수를 입력해주세요!"
                                }
                              ]
                            })(
                              <InputNumber
                                min={1}
                                formatter={(value) =>
                                  `${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                }
                                parser={(value) =>
                                  value.replace(/\$\s?|(,*)/g, "")
                                }
                                onChange={(value) =>
                                  this.handleChangeFormData(value, "count")
                                }
                                style={{ width: "150px" }}
                                placeholder="LOCK 해제횟수"
                              />
                            )}
                          </FormItem>
                        </Col>
                      </span>
                    )}
                  </Row>
                </FormItem>
              )}
              {lockStatus === "AMOUNT" && (
                <FormItem label={<span>수량&nbsp;</span>}>
                  {getFieldDecorator("amount", {
                    initialValue:
                      modalType === "edit"
                        ? editData.amount === -1
                          ? 0
                          : bigNum(
                              coinDivideFormat(
                                editData.amount,
                                editData.coinType
                              )
                            )
                        : 0,
                    rules: [
                      {
                        required: true,
                        message: "수량을 입력해주세요!"
                      },
                      {
                        validator: (rule, value, cb) => {
                          if (
                            value &&
                            Array.isArray(coinList.coinType) &&
                            formData.coinType === 0
                          ) {
                            cb("코인 종류를 선택해주세요.");
                          }
                          cb();
                        }
                      },
                      modalType === "create" && {
                        validator: (rule, value, cb) => {
                          value = new BigNumber(removeComma(value)).toNumber();
                          if (
                            value &&
                            formData.coinType !== 0 &&
                            value >
                              coinDivideFormat(
                                this.state.maxAmount,
                                formData.coinType
                              )
                          ) {
                            cb("수량이 잔액 보다 큽니다");
                          }
                          cb();
                        }
                      }
                    ]
                  })(
                    <Input
                      allowClear
                      style={{ width: "160px" }}
                      disabled={modalType === "create" ? false : true}
                      onChange={(e) => {
                        this.handleChangeFormData(e.target.value, "amount");
                      }}
                      onBlur={() => {
                        this.props.form.setFieldsValue({
                          amount: bigNum(formData.amount)
                        });
                        this.props.form.validateFields(["amount"], {
                          force: true
                        });
                      }}
                    />
                  )}
                  {modalType === "create" && (
                    <span
                      style={{
                        marginLeft: "10px"
                      }}>
                      {formData.coinType !== 0 &&
                        `잔액: ${
                          formData.coinType !== 0
                            ? amountFormat(
                                this.state.maxAmount,
                                formData.coinType
                              )
                            : 0
                        }`}
                    </span>
                  )}
                </FormItem>
              )}
              {this.handleCalculateAvg()}
              <FormItem label={<span>메모</span>}>
                {getFieldDecorator("memo", {
                  rules: [
                    {
                      required: false,
                      message: "메모를 입력해주세요!"
                    }
                  ]
                })(
                  <Input.TextArea
                    rows={4}
                    onChange={(e) =>
                      this.handleChangeFormData(e.target.value, "memo")
                    }
                    placeholder="메모를 입력해주세요."
                    style={{ width: "360px" }}
                    maxLength={100}
                  />
                )}
              </FormItem>
            </Form>
          </Modal>
        )
      );
    }
  }
);

export default LockCreateModal;
