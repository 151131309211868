import React, { Component } from "react";
import { connect } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";

import { Table, Input, Button } from "antd";
import { login } from "../../actions/loginAction";
import { httpPut, httpUrl, httpGet } from "../../api/httpClient";

import string from "../../string";
import { numberFormat, formatDate, checkData } from "../../util";
import AuthDetail from "./../../components/system/AuthDetail";
import AdminRegistryModal from "./../../components/system/AdminRegistryModal";
import { showAlert } from "../../components/common/AlertModal";
import AdminDetail from "../../components/system/AdminDetail";

const Search = Input.Search;
class Admin extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 0
    },
    searchText: "",

    createVisible: false,
    selectedRow: "",
    selectData: {},
    prevData: {},

    type: "",
    authVisible: false,
    authIdx: 0,
    authList: [],
    authCoinList: [],
    userInfo: reactLocalStorage.getObject("adminUser")
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getAdminList({ pageNum: pager.current });
  };

  // 검색
  onSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => this.getAdminList()
    );
  };

  onDelete = (row) => {
    const { list, pagination, userInfo } = this.state;

    let pageNum =
      list.length === 1
        ? pagination.current === 1
          ? pagination.current
          : pagination.current - 1
        : pagination.current;

    if (row.idx === userInfo.idx) {
      alert("삭제가 불가합니다.");
    } else {
      if (global.confirm(row.userId + "을 삭제하시겠습니까?")) {
        httpPut(httpUrl.adminDelete, [row.idx], {})
          .then((result) => {
            showAlert("delete");
            this.getAdminList({ pageNum });
          })
          .catch((error) => {});
      }
    }
  };

  // 코인 권한 보기
  coinAuthList = (params = {}) => {
    let idx = 0;
    if (params === 0) {
      idx = 0;
    } else idx = params.idx;
    // console.log(idx);
    httpGet(httpUrl.coinAuthList, [idx], {})
      .then((result) => {
        if (params !== 0) {
          this.setState({
            authIdx: idx,
            authList: result.data,
            authVisible: true
          });
        } else this.setState({ authCoinList: result.data });
      })
      .catch((error) => {});
  };

  // 권한 보기
  showAuth = (params = {}) => {
    let idx = 0;
    if (params === 0) {
      idx = 0;
    } else idx = params.idx;
    // console.log(idx);
    httpGet(httpUrl.authList, [idx], {})
      .then((result) => {
        this.setState({ authList: result.data });
        if (params !== 0) {
          this.setState({
            authIdx: idx,
            authVisible: true
          });
        } else this.coinAuthList(0);
      })
      .catch((error) => {});
  };

  // 관리자 목록 조회
  getAdminList = (params = {}) => {
    let pageNum = params.pageNum || 1;
    let searchText = params.searchText || this.state.searchText;

    httpGet(httpUrl.adminList, [10, pageNum, searchText], {})
      .then((res) => {
        // console.log(`data= ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.adminList,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getAdminList();
  }

  render() {
    const {
      createVisible,
      authList,
      authCoinList,
      detailVisible,
      detailData,
      pagination,
      authVisible,
      type,
      authIdx,
      userInfo
    } = this.state;

    const adminColumns = [
      {
        width: 120,
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        width: 120,
        key: "createDate",
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data, row, index) => (
          <div key={`createDate${index}`}>{formatDate(data)}</div>
        )
      },
      {
        key: "name",
        title: "이름",
        dataIndex: "name",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      },
      {
        key: "userId",
        title: "닉네임",
        dataIndex: "userId",
        className: "text-center",
        render: (data, row, index) => (
          <div
            className="pointer"
            onClick={() => {
              if (userInfo.superAdmin === 1 || row.idx === userInfo.idx)
                this.setState({ detailVisible: true, detailData: row });
              else alert("본인 또는 수퍼관리자 외 수정 할 수 없습니다");
            }}>
            {data}
          </div>
        )
      },
      {
        title: "IP",
        dataIndex: "ipAddress",
        className: "text-center"
      },
      {
        title: "핸드폰번호",
        dataIndex: "mobile",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      },
      {
        title: "이메일",
        dataIndex: "email",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      },
      {
        title: "관리자 등급",
        dataIndex: "superAdmin",
        className: "text-center",
        render: (data) => (
          <div>{string.adminString[string.toggleCode[data]]} </div>
        )
      },
      {
        key: "Coin Auth",
        title: "코인 권한",
        dataIndex: "Coin Auth",
        className: "text-center",
        render: (data, row, index) => (
          <Button
            onClick={() => {
              this.setState({ type: "coin" });
              this.coinAuthList({ idx: row.idx });
            }}>
            코인 권한보기
          </Button>
        )
      },
      {
        key: "Auth",
        title: "권한",
        dataIndex: "Auth",
        className: "text-center",
        render: (data, row, index) => (
          <Button
            onClick={() => {
              this.setState({ type: "auth" });
              this.showAuth({ idx: row.idx });
            }}>
            권한보기
          </Button>
        )
      },
      {
        title: "상태",
        dataIndex: "deleted",
        className: "text-center",
        render: (data, row, index) => (
          <Button
            onClick={(value) => {
              if (userInfo.superAdmin === 1 || row.idx === userInfo.idx)
                this.onDelete(row);
              else alert("본인이외는 삭제 할 수 없습니다");
            }}>
            삭제하기
          </Button>
        )
      }
    ];

    return (
      <div>
        <div className="title">사용자 관리</div>
        <div className="wrapper mt-35 width-1240" />
        {/* 어드민 생성 */}
        <AdminRegistryModal
          type="admin"
          visible={createVisible}
          modalData={authList}
          authCoinData={authCoinList}
          onCancel={() => {
            this.setState({
              createVisible: false
            });
          }}
          refresh={this.getAdminList}
        />
        {/* 어드민 상세 */}
        {detailVisible && (
          <AdminDetail
            visible={detailVisible}
            detailData={detailData}
            onCancel={() => {
              this.setState({ detailVisible: false, detailData: {} });
            }}
            onOk={() => {
              this.getAdminList({
                pageNum: pagination.current
              });
            }}
          />
        )}
        {authVisible && (
          <AuthDetail
            type={type}
            modalData={authList}
            visible={authVisible}
            authIdx={authIdx}
            onCancel={() => {
              this.setState({
                authVisible: false,
                authList: []
              });
            }}
          />
        )}
        {userInfo.superAdmin === 1 && (
          <div style={{ float: "left", marginBottom: "10px" }}>
            <Button
              onClick={() => {
                this.setState({ createVisible: true });
                // this.coinAuthList(0);
                this.showAuth(0);
              }}>
              생성
            </Button>
          </div>
        )}
        <div className="flex-row flex-center m-b-10" style={{ float: "right" }}>
          <label>검색기준&nbsp;</label>
          <Search
            placeholder="이름, 닉네임, ip주소를 입력하세요"
            enterButton="검색"
            allowClear
            onSearch={this.onSearch}
            style={{ width: "360px" }}
          />
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="admin-table"
          rowKey={(record) => record.idx}
          columns={adminColumns}
          dataSource={this.state.list}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    result: state.async.fetchData,
    isLogin: state.login.isLogin,
    loginInfo: state.login.loginInfo
  };
};

let mapDisPatchToProps = (dispatch) => {
  return {
    onLogin: (userinfo) => dispatch(login(userinfo))
  };
};

export default connect(mapStateToProps, mapDisPatchToProps)(Admin);
