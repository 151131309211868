import React, { Component } from "react";
import { Form, Modal, Input } from "antd";
import { httpUrl, httpPut } from "../../api/httpClient";

const FormItem = Form.Item;
const FranchiseAdjustmentMemoModal = Form.create({ name: "form_in_modal" })(
  class extends Component {
    componentDidUpdate(prevProps) {
      let modalData = { ...this.props.modalData };
      if (prevProps.visible !== this.props.visible && !prevProps.visible) {
        this.props.form.setFieldsValue({
          logDate: modalData.logDate,
          franchiseId: modalData.franchiseId,
          coinType: modalData.coinType,
          memo: modalData.memo
        });
      }
    }

    onOk = () => {
      let body = this.props.form.getFieldsValue();
      if (global.confirm("메모를 수정 하시겠습니까?")) {
        httpPut(httpUrl.adjustmentUpdate, [], body).then(res => {
          this.props.refresh(body);
          this.onCancel();
        });
      }
    };

    onCancel = () => {
      this.props.form.resetFields();
      this.props.onCancel();
    };

    render() {
      const { visible, form } = this.props;
      const { getFieldDecorator } = form;
      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 18 }
        }
      };
      return (
        <Modal
          visible={visible}
          title="정산관리 메모"
          okText="저장"
          onCancel={this.onCancel}
          onOk={this.onOk}>
          <Form {...formItemLayout}>
            <FormItem label="일자" style={{ display: "none" }}>
              {getFieldDecorator("logDate", {})(<Input type="text" />)}
            </FormItem>
            <FormItem label="가맹점" style={{ display: "none" }}>
              {getFieldDecorator("franchiseId", {})(<Input type="text" />)}
            </FormItem>
            <FormItem label="코인종류" style={{ display: "none" }}>
              {getFieldDecorator("coinType", {})(<Input type="text" />)}
            </FormItem>
            <FormItem label="메모">
              {getFieldDecorator(
                "memo",
                {}
              )(<Input.TextArea placeholder="메모를 입력해주세요." />)}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

export default FranchiseAdjustmentMemoModal;
