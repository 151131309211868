import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import fileDownload from "js-file-download";

import { Table, Input, Button, DatePicker } from "antd";
import { httpUrl, httpGet, httpDownload } from "../../api/httpClient";
import locale from "antd/es/date-picker/locale/ko_KR";

import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import CoinSelectBox from "../../components/common/CoinSelectBox";
import {
  numberFormat,
  amountFormat,
  formatDate,
  startDateFormat,
  endDateFormat,
  excelFormat,
  checkCoinName
} from "../../util";

const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;
class MergeLogList extends Component {
  state = {
    coinList: [],

    logList: [],
    pagination: {
      total: 0,
      current: 1
    },
    logType: "ALL",
    searchText: "",
    startDate: "",
    endDate: "",
    isLoaded: false
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getLogList({ pageNum: pager.current });
  };

  // 코인 종류 선택
  handleChangeCoinType = (value) => {
    this.setState({
      coinType: value,
      isLoaded: true
    });
  };

  // 상태 선택
  handleChangeLogType = (value) => {
    this.setState({
      logType: value,
      isLoaded: true
    });
  };

  // 검색
  onSearch = (value) => {
    // let validate = this.props.match.params.logname === 'mergeList'?!this.state.coinType ;

    if (!this.state.coinType) {
      alert("코인 종류를 선택해주세요.");
    } else {
      this.setState(
        {
          searchText: value
        },
        () => {
          this.getLogList();
        }
      );
    }
  };

  // 검색 기간 설정
  handleChangeDate = (date) => {
    this.setState({
      startDate: date.length !== 0 ? startDateFormat(date[0]) : "",
      endDate: date.length !== 0 ? endDateFormat(date[1]) : "",
      isLoaded: true
    });
  };

  // 엑셀 다운로드
  downloadExcel = () => {
    const {
      pagination,
      coinType,
      logType,
      searchText,
      startDate,
      endDate,
      isLoaded
    } = this.state;

    if (!coinType) {
      alert("코인 종류를 선택해주세요.");
    } else {
      if (isLoaded) {
        alert("조회버튼을 먼저 클릭해주세요.");
      } else {
        let total = pagination.total <= 9 ? 10 : pagination.total;
        let title = `${checkCoinName(coinType)} 수수료 지급내역.xlsx`;
        if (startDate && endDate) {
          title =
            `${startDate && excelFormat(startDate)} ~ ${
              endDate && excelFormat(endDate)
            }` +
            " " +
            title;
        }

        if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
          httpDownload(
            httpUrl.downLoadEthLogList,
            [total, 1, logType, coinType, searchText, startDate, endDate],
            {}
          )
            .then((res) => {
              fileDownload(res, title);
            })
            .catch((e) => {
              alert(`다운로드 실패`);
            });
        }
      }
    }
  };

  getLogList = (params = {}) => {
    const { coinType, logType, searchText, startDate, endDate } = this.state;
    const { match } = this.props;

    let pageNum = params.pageNum || 1;
    let url =
      match.params.logname === "mergeList"
        ? httpUrl.getMergeLogList
        : httpUrl.getEthLogList;
    let arg = [10, pageNum, logType, coinType, searchText];

    if (match.params.logname === "ethFeeLog") {
      arg.push(startDate);
      arg.push(endDate);
    }

    httpGet(url, arg, {})
      .then((res) => {
        // console.log(`res = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          logList:
            match.params.logname === "mergeList"
              ? res.data.mergeLogList === null
                ? []
                : res.data.mergeLogList
              : res.data.ethFeeLogList === null
              ? []
              : res.data.ethFeeLogList,
          pagination,
          isLoaded: false
        });
      })
      .catch((e) => {});
  };

  componentDidUpdate(prevProps) {
    const { match } = this.props;

    if (match.params.logname !== prevProps.match.params.logname) {
      this.setState({
        logList: [],
        pagination: {
          total: 0,
          current: 1
        },
        logType: "ALL",
        coinType: undefined,
        startDate: "",
        endDate: ""
      });
    }
  }

  componentDidMount() {
    this.setState({ coinList: reactLocalStorage.getObject("coinList") });
  }

  render() {
    const { logList, coinType, logType } = this.state;
    const { match } = this.props;

    const logListColumn = [
      {
        title: "순번",
        dataIndex: "idx",
        key: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "주소",
        dataIndex: "address",
        key: "address",
        className: "text-center"
      },
      {
        title: "코인종류",
        dataIndex: "coinType",
        key: "coinType",
        className: "text-center",
        render: (data) => (
          <div>
            {this.state.coinList.find((list) => list.coinType === data).name}
          </div>
        )
      },
      {
        title: "수수료",
        dataIndex: "fee",
        key: "fee",
        className: "text-center",
        render: (data, row, index) => (
          <span>{amountFormat(data, row.coinType)}</span>
        )
      },
      {
        title: "상태",
        dataIndex: "status",
        key: "status",
        className: "text-center",
        render: (data) => <span>{string.mergeLogString[data]}</span>
      },
      {
        title: "일자",
        dataIndex: "createDate",
        key: "createDate",
        className: "text-center",
        render: (data) => <span>{formatDate(data)}</span>
      }
    ];

    return (
      <div>
        <div className="title">
          {match.params.logname === "mergeList"
            ? "모으기 내역"
            : "ERC-20 수수료지급내역"}
        </div>
        <div className="flex-row flex-center flex-sb">
          <div className="flex-row flex-center m-b-10">
            <label>코인 선택 </label>
            <CoinSelectBox
              placeholder="코인 종류를 선택해주세요."
              value={coinType}
              onChange={this.handleChangeCoinType}
              style={{ width: "240px", marginLeft: "5px", marginRight: "10px" }}
              exceptERC={match.params.logname === "mergeList" ? false : true}
              exceptCoinType={[1, 3]}
            />
            <SelectBox
              value={logType}
              code={string.mergeLogCode}
              codeString={string.mergeLogString}
              onChange={this.handleChangeLogType}
              style={{ width: "180px" }}
            />
          </div>
          <div className="flex-row flex-center m-b-10">
            <label>검색기준&nbsp;</label>
            <Search
              placeholder="주소를 입력해주세요"
              enterButton="검색"
              onSearch={this.onSearch}
              allowClear
              style={{ width: "300px" }}
            />
          </div>
        </div>
        {match.params.logname !== "mergeList" && (
          <div className="flex-row flex-center flex-sb m-b-10">
            <div className="flex-row flex-center">
              <label>검색 기간&nbsp;</label>
              <RangePicker
                locale={locale}
                onChange={(date) => {
                  this.handleChangeDate(date);
                }}
              />
            </div>
            <Button
              onClick={this.downloadExcel}
              disabled={logList.length === 0 ? true : false}>
              엑셀 다운로드
            </Button>
          </div>
        )}
        <Table
          id="foundation-table"
          rowKey={(record) => record.idx}
          columns={logListColumn}
          dataSource={logList}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default withRouter(MergeLogList);
