import React, { Component } from "react";

import { Button, Table } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";

import { checkCoinName } from "../../util";
import DepositWalletUpdate from "../../components/marketing/DepositWalletUpdate";

class DepositWallet extends Component {
  state = {
    list: [],
    selectData: {},
    updateVisible: false
  };

  // 입금 주소 관리 목록 조회
  getDepositWalletList = () => {
    httpGet(httpUrl.getDepositWalletList, [], {})
      .then((res) => {
        // console.log(`data = ${JSON.stringify(res, null, 4)}`);
        this.setState({
          list: res.data
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getDepositWalletList();
  }

  render() {
    const { selectData, updateVisible, list } = this.state;

    const columns = [
      {
        key: "coinType",
        title: "코인종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "주소",
        dataIndex: "address",
        className: "text-center"
      },
      {
        title: "Last Block",
        dataIndex: "lastBlock",
        className: "text-center"
      },
      {
        title: "",
        dataIndex: "",
        className: "text-center",
        render: (data, row, index) => (
          <Button
            onClick={() => {
              this.setState({
                selectData: row,
                updateVisible: true
              });
            }}>
            수정
          </Button>
        )
      }
    ];

    return (
      <div>
        <div className="title">입금주소관리</div>
        {updateVisible && (
          <DepositWalletUpdate
            visible={updateVisible}
            modalData={selectData}
            onCancel={() => {
              this.setState({ selectData: {}, updateVisible: false });
            }}
            refresh={this.getDepositWalletList}
          />
        )}
        <div className="wrapper mt-35 width-1240" />
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={false}
        />
      </div>
    );
  }
}

export default DepositWallet;
