import React, { Component } from "react";

import { Button, Table } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";

import { checkCoinName, formatDate } from "../../util";
import WithdrawCurrencyUpdate from "../../components/marketing/WithdrawCurrencyUpdate";

class WithdrawCurrency extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 0
    },

    selectData: {},
    updateVisible: false
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getWithdrawCurrencyList({
      pageNum: pager.current
    });
  };

  // 출금시세 관리 목록 조회
  getWithdrawCurrencyList = (params = {}) => {
    let pageNum = params.pageNum || 1;
    httpGet(httpUrl.getWithdrawCurrencyList, [10, pageNum], {})
      .then((res) => {
        // console.log(`data = ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getWithdrawCurrencyList();
  }

  render() {
    const { updateVisible, selectData, list, pagination } = this.state;

    const columns = [
      {
        title: "날짜",
        dataIndex: "logDate",
        className: "text-center"
        // render: (data) => <div>{statFormat(data)}</div>
      },
      {
        key: "coinType",
        title: "코인 종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "금액",
        dataIndex: "price",
        className: "text-center"
        // render: (data, row) => <div>{priceMultiFormat(data, row.coinType)}</div>
      },
      {
        title: "갱신일",
        dataIndex: "updateDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "",
        dataIndex: "",
        className: "text-center",
        render: (data, row, index) => (
          <Button
            onClick={() => {
              this.setState({
                selectData: row,
                updateVisible: true
              });
            }}>
            수정
          </Button>
        )
      }
    ];

    return (
      <div>
        <div className="title">출금시세관리</div>
        {updateVisible && (
          <WithdrawCurrencyUpdate
            visible={updateVisible}
            modalData={selectData}
            onCancel={() => {
              this.setState({ selectData: {}, updateVisible: false });
            }}
            refresh={this.getWithdrawCurrencyList}
          />
        )}
        <div className="wrapper mt-35 width-1240" />
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.logDate}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default WithdrawCurrency;
