import React, { Component } from "react";

import { Table, Button } from "antd";
import { httpGet, httpUrl, httpPut } from "../../api/httpClient";

import TraceRatioModal from "../../components/trace/TraceRatioModal";
import { showAlert } from "../../components/common/AlertModal";
import { checkCoinName } from "../../util";

class TradeRatio extends Component {
  state = {
    list: [],
    pagination: {
      current: 0,
      total: 0
    },

    visible: false,
    type: false
  };

  onDelete = (row) => {
    if (global.confirm("삭제하시겠습니까?"))
      httpPut(httpUrl.tradeRatioDelete, [], row)
        .then((res) => {
          showAlert("delete");
          this.getTradeRatio();
        })
        .catch((e) => {});
  };

  getTradeRatio = () => {
    httpGet(httpUrl.getTradeRatio, [], {})
      .then((res) => {
        // console.log(`data = ${JSON.stringify(res, null, 4)}`);
        this.setState({ list: res.data });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getTradeRatio();
  }

  render() {
    const { visible, type, data, list } = this.state;

    const columns = [
      {
        title: "교환코인",
        dataIndex: "fromCoinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "교환요청 코인",
        dataIndex: "toCoinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "비율 (%)",
        dataIndex: "ratio",
        className: "text-center",
        render: (data) => <div>{data}</div>
      },
      {
        title: "상태",
        dataIndex: "status",
        className: "text-center",
        render: (data, row, index) => (
          <Button
            onClick={() => {
              this.setState({
                visible: true,
                type: true,
                data: row
              });
            }}>
            수정하기
          </Button>
        )
      },
      {
        title: "삭제 여부",
        dataIndex: "deleted",
        className: "text-center",
        render: (data, row) => (
          <Button
            onClick={() => {
              this.onDelete(row);
            }}>
            삭제하기
          </Button>
        )
      }
    ];

    return (
      <div>
        {visible && (
          <TraceRatioModal
            type={type}
            detail={data}
            visible={visible}
            onCancel={() => {
              this.setState({ visible: false });
            }}
            refresh={this.getTradeRatio}
          />
        )}
        <div className="title">교환 비율</div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={false}
        />
      </div>
    );
  }
}

export default TradeRatio;
