import React, { Component } from "react";

import { Table, Input, Button, InputNumber } from "antd";
import { httpGet, httpPut, httpUrl } from "../../api/httpClient";
import {
  amountFormat,
  dateFormat,
  coinDivideFormat,
  coinMultiFormat,
  checkCoinName
} from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";

class Setting extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 0
    },
    activeType: "ALL",

    selectedRow: "",
    selectData: {},
    prevData: {}
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getSettingList({ pageNum: pager.current });

    // 수정 작업 중 화면 전환하는 경우 수정작업 취소
    if (this.state.selectedRow !== "") this.setState({ selectedRow: "" });
  };

  // 값 변경
  onChangeModal = (value, type, row) => {
    var data = Object.assign({}, this.state.selectData);
    if (type === "coinType") {
      data.coinType = value;
      data.amount = coinDivideFormat(row.amount, value);
      data.delay = row.delay;
      data.active = row.active;
    } else data[type] = value;

    this.setState({
      selectData: data
    });
    // console.log(`data: ${JSON.stringify(data, null, 4)}`);
  };

  onUpdate = () => {
    const { selectData, pagination } = this.state;

    let updateData = {
      ...selectData,
      delay: selectData.delay === "" ? 0 : selectData.delay,
      amount: coinMultiFormat(selectData.amount, selectData.coinType)
    };
    // console.log(JSON.stringify(updateData, null, 4));
    if (selectData.active === 0 && selectData.delay === null) {
      alert("대기시간을 입력해주세요");
    } else {
      if (global.confirm(`변경하시겠습니까?`)) {
        httpPut(httpUrl.setUpdate, [], updateData)
          .then((result) => {
            this.setState({
              selectedRow: "",
              selectData: {}
            });

            this.getSettingList({ pageNum: pagination.current });
          })
          .catch((error) => {});
      }
    }
  };

  // 승인거래 설정 목록 조회
  getSettingList = (params = {}) => {
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.setList, [10, pageNum], {})
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.approvalSettingList,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getSettingList();
  }

  render() {
    const { selectedRow, selectData } = this.state;

    const approvalColumns = [
      {
        key: "coinType",
        title: "코인 종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "금액",
        dataIndex: "amount",
        className: "text-center",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index ? (
              amountFormat(data, row.coinType)
            ) : (
              <InputNumber
                min={0}
                max={coinDivideFormat(9223372036854775807, row.coinType)}
                style={{ width: "180px" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                value={selectData.amount}
                onChange={(value) => {
                  this.onChangeModal(value, "amount");
                }}
              />
            )}
          </div>
        )
      },
      {
        title: "대기시간",
        dataIndex: "delay",
        className: "text-center",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index ? (
              data ? (
                dateFormat(data)
              ) : (
                "-"
              )
            ) : (
              <div>
                <Input
                  allowClear
                  style={{ width: "160px", marginRight: "8px" }}
                  value={selectData.delay}
                  placeholder={"ex)1시간 -> 60분"}
                  onChange={(e) => {
                    this.onChangeModal(e.target.value, "delay");
                  }}
                />
                분
              </div>
            )}
          </div>
        )
      },
      {
        title: "활성화",
        dataIndex: "active",
        className: "text-center",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index ? (
              string.activeHandlesCodes[string.toggleCode[data]]
            ) : (
              <SelectBox
                type="active"
                value={string.activeHandlesCodes[this.state.selectData.active]}
                code={string.toggleCode}
                codeString={string.activeHandlesCodes}
                onChange={this.onChangeModal}
              />
            )}
          </div>
        )
      },
      {
        title: "설정",
        dataIndex: "set",
        className: "text-center",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index && (
              <Button
                onClick={() => {
                  this.setState({
                    selectedRow: index,
                    prevData: row
                  });
                  this.onChangeModal(row.coinType, "coinType", row);
                }}>
                수정하기
              </Button>
            )}
            {selectedRow === index && (
              <div>
                <Button
                  style={{ marginRight: "8px" }}
                  type="primary"
                  shape="circle"
                  icon="check"
                  onClick={this.onUpdate}
                />
                <Button
                  type="danger"
                  shape="circle"
                  icon="close"
                  onClick={() => {
                    this.setState({
                      selectedRow: "",
                      selectData: this.state.prevData
                    });
                  }}
                />
              </div>
            )}
          </div>
        )
      }
    ];

    return (
      <div>
        <div className="title">승인거래설정</div>
        <div className="wrapper mt-35 width-1240" />
        <div style={{ clear: "both" }} />
        <Table
          id="setting-table"
          rowKey={(record) => record.coinType}
          columns={approvalColumns}
          dataSource={this.state.list}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default Setting;
