import React, { Component } from "react";

import { Button, DatePicker, Form, Input, Modal, Upload } from "antd";
import moment from "moment";

import {
  httpPost,
  httpPut,
  httpUrl,
  imageType,
  serverUrl
} from "../../api/httpClient";
import BasicImage from "../common/BasicImage";
import { endDateFormat, startDateFormat } from "../../util";
import { showAlert } from "../common/AlertModal";
import { ShowErrorAlert } from "../common/ErrorAlertModal";

const FormItem = Form.Item;
const PopUpModal = Form.create()(
  class extends Component {
    state = {
      image: ""
    };

    handleDeleteFile = (image) => {
      if (global.confirm("이미지를 삭제하시겠습니까?")) {
        this.setState({
          image: ""
        });
      }
    };

    disabledDate = (current) => {
      // Can not select days before today and today
      return current < moment().startOf("day");
    };

    onCancel = () => {
      this.props.onCancel();
      this.props.form.resetFields();
    };

    onSumbitCreate = (e) => {
      const { form } = this.props;
      const { image } = this.state;

      e.preventDefault();
      form.validateFields((err, values) => {
        if (!err)
          if (global.confirm("생성하시겠습니까?")) {
            let body = {
              ...values,
              image: image,
              startDate: startDateFormat(values.startDate),
              expireDate: endDateFormat(values.expireDate)
            };
            // console.log(`create body =${JSON.stringify(body, null, 4)}`);

            httpPost(httpUrl.popUpCreate, [], body)
              .then((res) => {
                if (res.data.result === "SUCCESS") {
                  showAlert("create");
                  this.props.refresh();
                  this.props.onCancel();
                } else ShowErrorAlert(res.data.data);
              })
              .catch((e) => {});
          }
      });
    };

    onSumbitUpdate = (e) => {
      const { form, data } = this.props;
      const { image } = this.state;

      e.preventDefault();
      form.validateFields((err, values) => {
        if (!err)
          if (global.confirm("수정하시겠습니까?")) {
            let body = {
              ...data,
              ...values,
              image: image,
              startDate: startDateFormat(values.startDate),
              expireDate: endDateFormat(values.expireDate)
            };
            // console.log(`update body =${JSON.stringify(body, null, 4)}`);

            httpPut(httpUrl.popUpUpdate, [], body)
              .then((res) => {
                if (res.data.result === "SUCCESS") {
                  showAlert("update");
                  this.props.refresh({ refresh: true });
                  this.props.onCancel();
                } else ShowErrorAlert(res.data.data);
              })
              .catch((e) => {});
          }
      });
    };

    onSumbit = (e) => {
      const { type } = this.props;

      if (type === "create") this.onSumbitCreate(e);
      if (type === "edit") this.onSumbitUpdate(e);
    };

    componentDidMount() {
      // console.log(`data = ${JSON.stringify(this.props.data, null, 4)}`);
      const { type, data, form } = this.props;

      if (type === "edit") {
        this.setState({ image: data.image });
        form.setFieldsValue({
          ...data,
          startDate: moment(data.startDate),
          expireDate: moment(data.expireDate)
        });
      }
    }

    render() {
      const { visible, type, form } = this.props;
      const { getFieldDecorator } = form;
      const { image } = this.state;

      const formItemLayout = {
        labelCol: {
          xs: { span: 5 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };

      const uploadProps = {
        action: serverUrl + httpUrl.imageUpload,
        multiple: false,
        withCredentials: true,
        beforeUpload: (file, fileList) => {
          if (!imageType.find((type) => type === file.type)) {
            Modal.error({
              title: "업로드 결과",
              content: (
                <span>
                  파일 업로드 실패
                  <br />
                  파일 형식을 확인해주세요.
                </span>
              )
            });
            return false;
          }
        },
        onSuccess: (file) => {
          // console.log(`file = ${JSON.stringify(file, null, 4)}`);
          if (file.data.result) {
            Modal.info({
              title: "업로드 결과",
              content: "파일 업로드 성공"
            });
            this.setState({
              image: file.data.fileId
            });
          }
        },
        onError(err) {
          Modal.error({
            title: "업로드 결과",
            content: "파일 업로드 실패"
          });
        }
      };

      return (
        <Modal
          visible={visible}
          title={`팝업 ${type === "create" ? "생성" : "수정"}하기`}
          okText={`${type === "create" ? "생성" : "수정"}`}
          onOk={this.onSumbit}
          cancelText="취소"
          onCancel={this.onCancel}
          style={{ minWidth: "800px" }}>
          <Form {...formItemLayout}>
            <FormItem label={<span>이미지&nbsp;</span>}>
              {getFieldDecorator("image", {
                rules: [
                  {
                    required: true,
                    message: "이미지를 선택해주세요!"
                  }
                ]
              })(
                <div>
                  {image !== "" && image && (
                    <div className="flex-row flex-sb flex-center m-b-10">
                      <BasicImage
                        image={image}
                        src={serverUrl + httpUrl.getImage + image}
                        style={{ width: "300px", height: "150px" }}
                      />
                      {/* <Icon
                        className="pointer"
                        type="delete"
                        onClick={() => {
                          this.handleDeleteFile(image);
                        }}
                      /> */}
                    </div>
                  )}
                  <Upload {...uploadProps} showUploadList={false}>
                    <Button>{image ? "변경" : "선택"}</Button>
                  </Upload>
                </div>
              )}
            </FormItem>
            <FormItem label={<span>URL</span>}>
              {getFieldDecorator("url", {
                rules: [
                  {
                    required: true,
                    message: "url을 입력해주세요!"
                  }
                ]
              })(
                <Input.TextArea
                  rows={2}
                  placeholder="url를 입력해주세요."
                  maxLength={100}
                />
              )}
            </FormItem>
            <FormItem label={<span>시작일&nbsp;</span>}>
              {getFieldDecorator("startDate", {
                rules: [
                  {
                    required: true,
                    message: "시작일을 선택해주세요!"
                  }
                ]
              })(<DatePicker disabledDate={this.disabledDate} />)}
            </FormItem>
            <FormItem label={<span>마감일&nbsp;</span>}>
              {getFieldDecorator("expireDate", {
                rules: [
                  {
                    required: true,
                    message: "마감일을 선택해주세요!"
                  },
                  {
                    validator: (rule, value, cb) => {
                      if (value <= form.getFieldValue("startDate"))
                        cb("시작일보다 이후로 선택해주세요");
                      cb();
                    }
                  }
                ]
              })(<DatePicker disabledDate={this.disabledDate} />)}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);
export default PopUpModal;
