import React, { Component } from "react";
// import { connect } from 'react-redux';
import { reactLocalStorage } from "reactjs-localstorage";

import { Modal, Table, Input, Button, Select } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";

import string from "./../../string";
import SelectBox from "./../common/SelectBox";
import {
  numberFormat,
  formatDate,
  amountFormat,
  checkData,
  checkCoinName
} from "../../util";
import P2PLogModal from "./P2PLogModal";

const Option = Select.Option;
class WalletLogModal extends Component {
  state = {
    fullCoinList: [],

    pagination: {
      current: 0,
      total: 0
    },
    walletLogVisible: false,
    walletLogList: [],
    searchTypes: "ALL",
    searchText: "",
    transferType: "ALL",
    coinType: 0,

    p2pVisible: false,
    logIdx: 0
  };

  // 패이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.showWalletLog({ pageNum: pagination.current });
  };

  // 전송 종류 선택
  handleChangeTranferType = (value) => {
    this.setState({
      transferType: value
    });
  };

  // 코인 종류 선택
  handleChangeCoinType = (value) => {
    this.setState({
      coinType: value
    });
  };

  // 검색 조건 선택
  handleChangeSearchType = (value) => {
    if (value === "SERVER") {
      this.setState({
        searchText: "",
        searchTypes: value
      });
    } else {
      this.setState({
        searchTypes: value
      });
    }
  };

  // 검색어 입력
  handleChangeSearchText = (value) => {
    this.setState({
      searchText: value
    });
  };

  // 검색
  onSearch = () => {
    if (
      this.state.searchText === "" &&
      this.state.searchTypes !== "ALL" &&
      this.state.searchTypes !== "SERVER"
    ) {
      alert("검색어를 입력해주세요.");
    } else {
      this.showWalletLog();
    }
  };

  // 거래 내역 조회
  showWalletLog = (params = {}) => {
    const { searchTypes, searchText, transferType, coinType } = this.state;
    const { selectedSerial } = this.props;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.walletLogList,
      [
        10,
        pageNum,
        selectedSerial.serial,
        searchTypes,
        searchText,
        transferType,
        coinType
      ],
      {}
    )
      .then((result) => {
        const pagination = { ...this.state.pagination };
        pagination.total = result.data.totalCount;
        pagination.current = result.data.currentPage;
        this.setState({
          walletLogList: result.data.walletLogList,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    const coinList = reactLocalStorage.getObject("coinList");
    this.setState({
      fullCoinList: [
        {
          coinType: 0,
          name: "전체"
        },
        ...coinList
      ]
    });
    this.showWalletLog();
  }

  render() {
    const { page, visible, onOk } = this.props;
    const {
      walletLogList,
      pagination,
      searchTypes,
      transferType,
      coinType,
      p2pVisible,
      logIdx
    } = this.state;

    const walletColums = [
      {
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "전송 방법",
        dataIndex: "type",
        className: "text-center",
        render: (data) => <div>{string.sendString[data]}</div>
      },
      {
        title: "보낸 지갑",
        dataIndex: "from",
        className: "text-center"
        // render: (data) => (
        //   <div style={{ wordWrap: "break-word", width: "200px" }}>{data}</div>
        // )
        // ...this.getSearch('FROM'),
      },
      {
        title: "받은 지갑",
        dataIndex: "to",
        className: "text-center"
        // render: (data) => (
        //   <div style={{ wordWrap: "break-word", width: "200px" }}>{data}</div>
        // )
        // ...this.getSearch('TO'),
      },
      {
        title: "금액",
        dataIndex: "amount",
        className: "text-center",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      },
      {
        title: "거래 상태",
        dataIndex: "status",
        className: "text-center",
        render: (data, row) => <div>{string.requestLogString[data]}</div>
      },
      !page
        ? {
            width: 200,
            title: "메모",
            dataIndex: "memo",
            className: "text-center",
            render: (data) => (
              <div style={{ wordWrap: "break-word", width: "200px" }}>
                {checkData(data)}
              </div>
            )
          }
        : {
            colSpan: 0,
            width: 0,
            render: () => {
              return {
                props: {
                  colSpan: 0
                }
              };
            }
          },
      !page
        ? {
            title: "LOCK 내역",
            dataIndex: "p2p",
            className: "text-center",
            render: (data, row) => (
              <Button
                onClick={() => {
                  this.setState({ p2pVisible: true, logIdx: row.idx });
                }}>
                내역보기
              </Button>
            )
          }
        : {
            colSpan: 0,
            width: 0,
            render: () => {
              return {
                props: {
                  colSpan: 0
                }
              };
            }
          }
    ];

    return (
      <Modal
        visible={visible}
        title="거래내역"
        cancelText="취소"
        onCancel={onOk}
        style={{ minWidth: "1800px" }}
        footer={null}>
        {p2pVisible && (
          <P2PLogModal
            type="user"
            visible={p2pVisible}
            logIdx={logIdx}
            onCancel={() => {
              this.setState({ p2pVisible: false });
            }}
          />
        )}
        <div className="wallet-log-wrapper">
          <div className="flex-row flex-center flex-sb m-b-10">
            <div className="flex-row flex-center">
              <label>코인 종류&nbsp;</label>
              <Select
                placeholder="코인종류"
                style={{ width: "160px", marginRight: "10px" }}
                value={coinType}
                onChange={this.handleChangeCoinType}>
                {this.state.fullCoinList.map((coin) => (
                  <Option key={coin.coinType} value={coin.coinType}>
                    {coin.name}
                  </Option>
                ))}
              </Select>
              <label>전송 방법&nbsp;</label>
              <SelectBox
                style={{ width: "160px" }}
                value={string.transferString[transferType]}
                codeString={string.transferString}
                onChange={this.handleChangeTranferType}
              />
            </div>
            <div className="flex-row flex-center">
              <SelectBox
                value={string.walletLogString[searchTypes]}
                code={string.walletLogCode}
                codeString={string.walletLogString}
                onChange={this.handleChangeSearchType}
                style={{ width: "200px" }}
              />
              <Input
                placeholder="보낸 지갑주소, 받은 지갑주소을 입력하세요"
                disabled={this.state.searchTypes === "SERVER"}
                onChange={(e) => {
                  this.handleChangeSearchText(e.target.value);
                }}
                allowClear
                style={{ width: "360px" }}
              />
              <Button
                type="primary"
                onClick={() => {
                  this.onSearch();
                }}>
                검색
              </Button>
            </div>
          </div>
          <div style={{ clear: "both" }} />
          <Table
            id="member-log-table"
            rowKey={(record) => record.idx}
            columns={walletColums}
            dataSource={walletLogList}
            style={{ marginBottom: "10px" }}
            pagination={pagination}
            onChange={this.handleTableChange}
          />
        </div>
      </Modal>
    );
  }
}

export default WalletLogModal;
