import React, { Component } from "react";

import { Table, Button, Checkbox } from "antd";

import { httpGet, httpPut, httpUrl } from "../../api/httpClient";
import { showAlert } from "../../components/common/AlertModal";
import SelectBox from "../../components/common/SelectBox";
import {
  endDateFormat,
  formatDate,
  formatDateSecond,
  numberFormat,
  startDateFormat
} from "../../util";
import string from "../../string";
import moment from "moment";
import EventModal from "../../components/event/EventModal";

class EventList extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 0
    },
    deleted: false,
    expireDate: false,
    orderByType: "DESC",

    type: "create",
    visible: false,
    selectedRow: {}
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getEventList({ pageNum: pager.current });
  };

  // 삭제 포함여부 설정
  deletedHandleChange = (e) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      deleted: e.target.checked,
      isLoaded: true
    });
  };

  // 종료된 이벤트 포함여부 설정
  expireDateHandleChange = (e) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      expireDate: e.target.checked,
      isLoaded: true
    });
  };

  // 정렬 순서 선택
  orderByTypeHandleChange = (value) => {
    this.setState({
      orderByType: value,
      isLoaded: true
    });
  };

  // 이벤트 삭제 및 복구
  onDelete = (row) => {
    const { deleted, pagination } = this.state;

    let pageNum =
      pagination.current > 1 && !deleted && row.deleted === 0
        ? this.state.list.length === 1
          ? pagination.current - 1
          : pagination.current
        : pagination.current;
    if (
      global.confirm(
        `${row.title.find((item) => item.language === "ko").content}을(를) ${
          row.deleted ? "복구" : "삭제"
        }하시겠습니까?`
      )
    ) {
      httpPut(httpUrl.eventDelete, [row.idx], {})
        .then((result) => {
          if (row.deleted) showAlert("restore");
          else showAlert("delete");
          this.getEventList({ pageNum });
        })
        .catch((error) => {});
    }
  };

  getEventList = (params = {}) => {
    const { pagination, deleted, expireDate, orderByType } = this.state;

    let pageNum = params.pageNum || 1;
    if (params.refresh) pageNum = pagination.current;

    httpGet(
      httpUrl.getEventList,
      [10, pageNum, deleted, expireDate, orderByType],
      {}
    )
      .then((res) => {
        // console.log(`data= ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getEventList();
  }

  render() {
    const { type, visible, selectedRow, orderByType, list, pagination } =
      this.state;
    const columns = [
      {
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data, row, index) => (
          <div key={`createDate${index}`}>{formatDate(data)}</div>
        )
      },
      {
        title: "제목",
        dataIndex: "title",
        className: "text-center",
        render: (data, row, index) => (
          <div
            style={{ cursor: "pointer", color: "blue" }}
            onClick={() => {
              this.setState({
                type: "edit",
                visible: true,
                selectedRow: row
              });
            }}>
            {data.find((item) => item.language === "ko").content}
          </div>
        )
      },
      {
        title: "기간",
        dataIndex: "startDate",
        className: "text-center",
        render: (data, row, index) => (
          <div>{`${startDateFormat(data)} - ${endDateFormat(
            row.expireDate
          )}`}</div>
        )
      },
      {
        width: 300,
        title: "기간상태",
        dataIndex: "status",
        className: "text-center",
        render:
          // data => <div>{data === 0 ? '삭제' : '-'}</div>
          (data, row) => (
            <div>
              {startDateFormat(row.startDate) >
              formatDateSecond(
                moment()
                  .endOf("day" - 1)
                  .toString()
              ) ? <span>예정</span>:endDateFormat(row.expireDate) >
              formatDateSecond(
                moment()
                  .endOf("day" - 1)
                  .toString()
              ) ? (
                <span>진행중</span>
              ) : (
                <span>만료</span>
              )}
            </div>
          )
      },
      {
        width: 300,
        title: "상태",
        dataIndex: "deleted",
        className: "text-center",
        render:
          // data => <div>{data === 0 ? '삭제' : '-'}</div>
          (data, row) => (
            <SelectBox
              value={string.deleteString[data]}
              code={string.toggleCode}
              codeString={string.deleteString}
              onChange={(value) => {
                if (value !== row.deleted) {
                  this.onDelete(row);
                }
              }}
            />
          )
      }
    ];

    return (
      <div>
        <div className="title">이벤트 관리</div>
        {visible && (
          <EventModal
            type={type}
            visible={visible}
            onOk={this.getEventList}
            onCancel={() => {
              this.setState({
                visible: false
              });
            }}
            selectedRow={selectedRow}
          />
        )}
        <div className="flex-row flex-center m-b-10 flex-sb">
          <Button
            onClick={() => {
              this.setState({
                type: "create",
                visible: true,
                selectedRow: {}
              });
            }}>
            생성
          </Button>
          <div className="flex-row flex-center">
            <label htmlFor="" style={{ marginRight: "10px" }}>
              순서&nbsp;&nbsp;&nbsp;
              <SelectBox
                value={string.orderByString[orderByType]}
                code={string.orderByCode}
                codeString={string.orderByString}
                onChange={(value) => {
                  if (value !== orderByType)
                    this.orderByTypeHandleChange(value);
                }}
                style={{ width: "160px", marginRight: "10px" }}
              />
            </label>
            <label htmlFor="" style={{ marginRight: "10px" }}>
              검색조건&nbsp;&nbsp;&nbsp;
              <Checkbox onChange={this.deletedHandleChange}>삭제 포함</Checkbox>
              <Checkbox onChange={this.expireDateHandleChange}>
                종료된 이벤트 포함
              </Checkbox>
            </label>
            <Button
              onClick={() => {
                this.getEventList();
              }}>
              검색
            </Button>
          </div>
        </div>
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default EventList;
